import axios from "axios";

//User Login
export async function loginUser(user_details) {
  console.log(`${process.env.REACT_APP_SERVER}/api/auth/login`, user_details);
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER}/api/auth/login`,
      user_details
    );
    return response;
  } catch (err) {
    console.error(err);
    return err;
  }
}

//User Sign Up
export async function signUpUser(user_details) {
  const response = await axios.post(
    `${process.env.REACT_APP_SERVER}/api/auth/register`,
    user_details
  );
  return response;
}

//Get User
export async function getUser(token, user_name) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER}/api/users/${user_name}`,
      config
    );
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      // logOut();
    }
  }
}

//Fetch all device cards
export async function getAllDeviceCards(token, logOut) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER}/api/devices`,
      config
    );
    return response?.data;
  } catch (err) {
    if (err.response.status === 401) {
      logOut();
    }
  }
}

//Fetch specific device card
export async function getDeviceCard(deviceName, user, token, logOut) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER}/api/devices/${deviceName}`,
      config
    );
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      logOut();
    }
  }
}

//Create card Instance
export async function createCardInstance(data) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };
  const response = await axios.post(
    `http://theopenfactory.cloud:8081/createInstance`,
    data,
    config
  );
  return response;
}

// Add/Delete device card
export async function mutateDevices(method, device, token, logOut) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    if (method === "POST") {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/devices`,
        device,
        config
      );
      return response;
    } else {
      const response = await axios.delete(
        `${process.env.REACT_APP_SERVER}/api/devices/${device}`,
        config
      );
      return response;
    }
  } catch (error) {
    if (error.response.status === 401) {
      logOut();
    }
  }
}

// Fetch suites
export async function getSuites(token, logOut) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER}/api/suites`,
      config
    );
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      logOut();
    }
  }
}

//Fetch specific suite
export async function getSuite(suiteUid, token, logOut) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER}/api/suites/${suiteUid}`,
      config
    );
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      logOut();
    }
    return error;
  }
}

//CONNECT SUITE
export async function connectSuite(suite, deviceName, token, logOut) {
  // console.log(suite,deviceName,token)
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const data = {};
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_SERVER}/api/devices/${deviceName}/addSuite?suiteUid=${suite}`,
      data,
      config
    );
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      logOut();
    }
    return error;
  }
}

//ADD SUITE
export async function addSuite(suite, token, logOut) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER}/api/suites`,
      suite,
      config
    );
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      logOut();
    }
    return error;
  }
}

//UPLOAD ARTEFACTS
export async function uploadArtefacts(artefacts, token, logOut) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_SERVER}/api/suites`,
      artefacts,
      config
    );
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      logOut();
    }
    return error;
  }
}

//UPDATE SUITE from dockerCompose
export async function updateSuite(payload, suiteUid, token, logOut) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_SERVER}/api/suites/${suiteUid}`,
      payload,
      config
    );
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      logOut();
    }
    return error;
  }
}

//UPDATE SUITE from Apps
export async function updateSuiteApps(data, suiteUid, token, logOut) {
  const options = {
    method: "PATCH",
    url: `${process.env.REACT_APP_SERVER}/api/suites/${suiteUid}`,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_SERVER}/api/suites/${suiteUid}`,
      data,
      config
    );
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      logOut();
    }
    return error;
  }
}

//CREATE_SUITE_FINAL
export async function createSuiteFinal(suiteUid, token, logOut) {
  const options = {
    method: "PATCH",
    url: `${process.env.REACT_APP_SERVER}/api/suites/createSuite`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      suiteUid: suiteUid,
    },
  };

  try {
    const response = await axios.request(options);
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      logOut();
    }
    return error;
  }
}

// DELETE SUITE
export async function deleteSuite(suite, token, logOut) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_SERVER}/api/suites/${suite}`,
      config
    );
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      logOut();
    }
    return error;
  }
}

//GET APPS
export async function getApps(token, logOut) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER}/api/apps`,
      config
    );
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      logOut();
    }
    return error;
  }
}

//GET APP
export async function getApp(token, appUid, logOut) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER}/api/apps/${appUid}`,
      config
    );
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      logOut();
    }
    return error;
  }
}

//CREATE APP
export async function createApp(data, token) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  console.log(data, token);
  const response = await axios.post(
    `${process.env.REACT_APP_SERVER}/api/apps`,
    data,
    config
  );
  return response;
}

//Delete App
export async function deleteApp(appUid, token) {
  const options = {
    method: "DELETE",
    url: `${process.env.REACT_APP_SERVER}/api/apps/${appUid}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.request(options);
  return response;
}
//Mutate APP (create(POST) and delete(DELETE) methods)
export async function mutateApps(appData, token, method) {
  let options;
  if (method === "DELETE") {
    options = {
      method: "DELETE",
      url: `${process.env.REACT_APP_SERVER}/api/apps/${appData}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  } else {
    options = {
      method: "POST",
      url: `${process.env.REACT_APP_SERVER}/api/apps`,
      data: appData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }
  const response = await axios.request(options);
  return response;
}

//Update App
export async function updateApp(appUid, data, token) {
  console.log(appUid, data, token);
  const options = {
    method: "PATCH",
    url: `${process.env.REACT_APP_SERVER}/api/apps/${appUid}`,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.request(options);
  return response;
}

//CreateFinal App
export async function createFinalApp(appUid, token) {
  console.log(appUid, token);
  const options = {
    method: "PATCH",
    url: `${process.env.REACT_APP_SERVER}/api/apps/createApp`,
    params: {
      appUid: appUid,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.request(options);
  return response;
}

// GET LOCATIONS suggest
export async function suggestLocations(query, session_token) {
  //  const {longitude,latitude} = proximity
  const params = {
    // proximity:`${longitude},${latitude}`,
    access_token: process.env.REACT_APP_MAPBOX_TOKEN,
    session_token: session_token,
    q: query,
    language: "en",
  };

  try {
    //  const {longitude,latitude} = proximity
    //  console.log(longitude,latitude)
    const response = await axios.get(
      `${process.env.REACT_APP_MAPBOX_URL}/suggest`,
      { params }
    );
    return response.data;
  } catch (err) {
    return err;
  }
}

// GET LOCATIONS retrieve
export async function getLocation(mapbox_id, session_token) {
  const params = {
    access_token: process.env.REACT_APP_MAPBOX_TOKEN,
    session_token: session_token,
    language: "en",
  };

  const response = await axios.get(
    `${process.env.REACT_APP_MAPBOX_URL}/retrieve/${mapbox_id}`,
    { params }
  );
  return response.data;
}

// Connect/Disconnect VPN
export async function VpnConnect(url) {
  const options = {
    method: "POST",
    url: url,
  };
  try {
    const response = await axios.request(options);
    if (response.status === 200) {
      // alert(JSON.stringify(response.status))
      // console.log(response,254)
      return response;
    }
  } catch (err) {
    // alert(JSON.stringify(err))
    // console.error(err)
    // return err
  }
}
