import { useAuth } from "../../context/AuthContext"
import { SignIn } from "../../Pages/authentication";

const RequireAuth = ({children}) => {
  const{token} = useAuth()
  
   return(
    
      // token ? 
      // children
      // : 
      // <Redirect token={token}/>
    
      token ? 
      children
      : 
      <SignIn />
   ) 

}

export default RequireAuth