import { useState, useEffect, useContext, useImperativeHandle, forwardRef } from 'react';
import { Card, ListGroup, Button, Container, Row, Col, Badge, ProgressBar } from 'react-bootstrap';
import { BiCalendarAlt, BiTime, BiMap } from "react-icons/bi";
import { ReactSVG } from 'react-svg';
import { verified, cpu, temp, memory } from '../Images/Images';
import { LoadingDots } from '../loaders/LoadingDots';
import { getLocation } from '../../services/Api';
import { useAuth } from '../../context/AuthContext';
import { useDevice } from '../../context/DeviceContext';
import { useQuery } from 'react-query'

function DeviceDetailsCard(props, ref) {


  const [cardPayload, setCardPayload] = useState()
  const [alive, setAlive] = useState(false)
  const { token, wsPayload } = useAuth()
  // const {Payload} = useDevice()
  const [loading, setLoading] = useState(true)

  //   const {data,isLoading,isError} = useQuery(
  //   {
  //     queryKey:['locationId',props?.card?.locationId],
  //     queryFn:() => getLocation(props?.card?.locationId,token),
  //     enabled:!!props?.card?.locationId,
  //     refetchOnMount:false,
  //     refetchOnReconnect:false,
  //     refetchOnWindowFocus:false,
  //     refetchIntervalInBackground:false
  //   }
  //  )


  //  const country = data?.features?.[0]?.properties?.context?.country?.name
  //  const city = data?.features?.[0]?.properties?.context?.place?.name
  //  const district = data?.features?.[0]?.properties?.context?.district?.name || data?.features?.[0]?.properties?.name


  useImperativeHandle(ref, () => {
    return {
      alive
    }
  })


  useEffect(() => {

    let interval = setInterval(() => {
      let d = new Date();
      let lastactive = cardPayload?.lastactive;
      // console.log("datetime test", d.getTime()-lastactive)
      if ((d.getTime() - lastactive < 30000)) {

        setAlive(true)
        setLoading(false)
        // console.log('alive',cardPayload?.datetime)
      }
      else {
        setAlive(false)
        setLoading(false)
        // console.log('dead',cardPayload?.datetime)
      }
    }, 1000)

    return () => clearInterval(interval)
  })

  useEffect(() => {
    if (wsPayload?.length !== 0 && wsPayload["heartbeat"]?.device_name === props?.card?.deviceName) {
      // console.log(Payload)
      setCardPayload(wsPayload["heartbeat"])
    }
  }, [wsPayload, props?.card?.deviceName])

  return (
    <Card className='p-5 shadow-sm border-light'>
      <Row>
        <Col md={5} lg={3}>
          <div className='device-img '>
            <img className='img-device' src={props?.card?.image} alt="" />
          </div>
        </Col>
        <Col md={7} lg={9}>
          <div className='product-info'>
            <div className='title d-flex'><h3 className='me-3'>{props?.card?.deviceName}</h3>
              <div className='virifyied-icon'>
                <ReactSVG src={verified} />
              </div>

              {
                loading ?
                  <LoadingDots />
                  :
                  <Badge className={cardPayload ? alive ? 'online' : 'offline' : 'offline'} pill>
                    {cardPayload ? alive ? 'online' : 'offline' : 'offline'}
                  </Badge>

              }

            </div>
            {['sm'].map((breakpoint) => (
              <ListGroup key={breakpoint} horizontal={breakpoint} className="my-0">
                <ListGroup.Item className='py-0 ps-0 border-0 border-end small '><BiCalendarAlt /> {alive && Date(cardPayload?.datetime)?.slice(0, 15)}</ListGroup.Item>
                <ListGroup.Item className='py-0 border-0 border-end small'><BiTime /> {alive && (cardPayload?.datetime)?.slice(11, 16)}</ListGroup.Item>
                <ListGroup.Item className='py-0 border-0 small'><BiMap />
                  {
                    props?.card?.locationId ?
                      `${props?.card?.locationId}`

                      :
                      'Location N/A'
                  }</ListGroup.Item>
              </ListGroup>
            ))}
            <div className='description mt-2'>
              <p>{props?.card?.description}</p>
            </div>
            <Container className='mt-3' fluid>
              <Row>
                <Col className='ps-0 information' md={6}>
                  <ListGroup className='me-3 border-end pe-5' variant="flush">
                    <h5 className='mb-3'>Information</h5>
                    <ListGroup.Item className='p-0 d-flex align-items-center justify-content-start border-0'>
                      <strong>Product ID</strong>
                      <span>{props?.card?.productId}</span>
                    </ListGroup.Item>
                    <ListGroup.Item className='p-0 my-0 d-flex align-items-center justify-content-start border-0'>
                      <strong>Type</strong>
                      <span data-toggle="tooltip" title={props?.card?.osName}>{props?.card?.osName ? props?.card?.osName?.length < 20 ? props?.card?.osName : props?.card?.osName?.slice(0, 20) + '...' : "-"} / {props?.card?.systemType ? props?.card?.systemType : "-"}</span>
                    </ListGroup.Item>
                    <ListGroup.Item className='p-0 d-flex align-items-center justify-content-start border-0'>
                      <strong>Last Online</strong>
                      <span>--</span>
                    </ListGroup.Item>
                    <ListGroup.Item className='p-0 d-flex align-items-center justify-content-start border-0'>
                      <strong>MAC ID</strong>
                      <span>{props?.card?.macId}</span>
                    </ListGroup.Item>
                    <ListGroup.Item className='p-0 d-flex align-items-center justify-content-start border-0'>
                      <strong>Timezone</strong>
                      <span>{props?.card?.timeZone}</span>
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
                <Col md={6}>
                  <div className='progressbar'>
                    <h5 className='mb-4'>Device</h5>
                    <div className='device-item d-flex align-items-center justify-content-between'>
                      <span className='title bg-dark text-white d-flex'><ReactSVG src={cpu} /> CPU</span>
                      <ProgressBar variant={alive && (cardPayload?.cpu > 90 ? 'danger' : cardPayload?.cpu > 50 ? 'warning' : 'success')} className='float-start mx-2' now={alive && cardPayload ? cardPayload?.cpu : 0}></ProgressBar>
                      <span className='percent me-4'>{`${alive && cardPayload ? cardPayload?.cpu : 0}%`}</span>
                    </div>
                    <div className='device-item d-flex align-items-center justify-content-between'>
                      <span className='title bg-dark text-white d-flex'><ReactSVG src={temp} /> Temp</span>
                      <ProgressBar variant={alive && (cardPayload?.temperature > 90 ? 'danger' : cardPayload?.temperature > 50 ? 'warning' : 'success')} className='float-start mx-2' now={alive && cardPayload ? cardPayload?.temperature : 0}></ProgressBar>
                      <span className='percent me-4'>{`${alive && cardPayload ? cardPayload?.temperature : 0}%`}</span>
                    </div>
                    <div className='device-item d-flex align-items-center justify-content-between'>
                      <span className='title bg-dark text-white d-flex'><ReactSVG src={memory} /> Memory</span>
                      <ProgressBar variant={alive && (cardPayload?.memory > 90 ? 'danger' : cardPayload?.memory > 50 ? 'warning' : 'success')} className='float-start mx-2' now={alive && cardPayload ? cardPayload?.memory : 0}></ProgressBar>
                      <span className='percent me-4'>{`${alive && cardPayload ? cardPayload?.memory : 0}%`}</span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
      </Row>
    </Card>
  )
}

export default forwardRef(DeviceDetailsCard)