

import SignInForm from '../../Component/Forms/SignInForm';

const SignIn = () => {

  return (  
    <> 
    <SignInForm />
    {/* <div className="LandingPage position-absolute top-0 start-0 vh-100 vw-100 bg-light z-3">
      <div className="wrapper account">
      <div className='sign_in'>
        <Link className='logo mb-4 d-block text-center' to='/'><ReactSVG src={logo_light} alt="" /></Link>
        <div className='sign_in_form bg-white mx-auto shadow p-5'>
          <span className='fs-2'>
          Welcome Back
          </span>
          <p>Sign in to your The Open Factory account.</p>
          
          <Form className='mt-4'>
          
            <Form.Group className="mb-3" controlId="emailId">              
              <Form.Control required type="text" className='border-0 border-bottom' placeholder="email id" />
            </Form.Group> 
            <Form.Group className="mb-3" controlId="password">              
              <Form.Control required type="password" className='border-0 border-bottom' placeholder="password" />
            </Form.Group>

             <div className="d-grid gap-2">
            
              {
                !logging?
                <>
                <Button type='submit' variant="primary theme-btn" >
                  Sign in
                </Button>
                </>
                :
                    <>
                      <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                        <span role="status">Logging In ...</span>
                      </button>
                    </>
              }
               
            </div>                        
            
            
            <div className='mt-4'>
            <span>Don't have an account?</span> <Button variant="link p-0">Click here</Button>
          </div>
                        
          </Form> 
        </div>
      </div>
      </div>

    </div> */}
    </>
    
  
  )
}

export default SignIn