import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { ReactSVG } from 'react-svg';
import { empty } from '../Images/Images';

function EmptyBox({ setShow, setNewUser, page, heading, info }) {
  return (
    <div className='d-block'>
      <br /><br /><br />
      <div className='cover-container empty_device d-flex w-100 h-100 mx-auto flex-column text-center'>
        <main className="px-3">
          <ReactSVG src={empty} className='empty-box' alt="" />
          <h3>{heading}!</h3>
          <p className="lead">{info}</p>
          {/* {
            page === 'device' &&
            <Button
              variant="primary theme-btn"
              onClick={() => {
                setShow(true)
                setNewUser(true)
              }}>
              Add device
            </Button>
          } */}

        </main>
      </div>
    </div>
  )
}

export default EmptyBox