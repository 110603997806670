
import Spinner from 'react-bootstrap/Spinner';
import {loader} from '../Images/Images';

const PageLoaderAnim = ({ text }) => {
  return (
    <div className='d-flex  justify-content-center align-items-center'
      style={{ height: '35rem' }}
    >
      <div
        className='d-flex flex-column align-items-center '
      >
        <img
          className="d-block w-100 "
          src={loader}
          alt="First slide"
        />
        {/* <Spinner
          animation="border"
          variant="info"
        /> */}
        {/* <p >Loading {text}...</p> */}
      </div>

    </div>
  )
}

export default PageLoaderAnim