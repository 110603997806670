import {useState,useEffect,useContext} from 'react';
import { useMutation, useQuery } from 'react-query';
import { useAuth } from '../../context/AuthContext';
import {useParams} from 'react-router-dom'
import Breadcrumbs from '../../Component/miscellaneous/Breadcrumb';
import { Card, ListGroup, Button, Container, Row, Col, Tab, Tabs, Table, Badge, Stack} from 'react-bootstrap';
import { ReactSVG } from 'react-svg';
import { pencil, product_image, user_profile, donut_chart } from '../../Component/Images/Images';
import { BiCalendarAlt, BiUser, BiCloudDownload, BiUserPlus, BiRocket, BiRevision, BiBrush} from "react-icons/bi";
import {getSuite} from '../../services/Api'
import {MultipleItems} from '../../Component/sliders';
import {updateSuite} from '../../services/Api';
import TableApps from '../../Component/tables/TableApps';
import {SuiteDevicesTab} from '../../Component/tabs';
import { notify } from '../../utility/Helper';


function SuiteDetails() {  

  const {user,token,logOut} = useAuth()

  const {suiteUid} = useParams();
  //current page suite
  const [suite,setSuite] = useState();
  //set current selected suite app
  const [currSuiteApp,setCurrSuiteApp]= useState();

  const {mutate:get_suite} = useMutation({
    mutationFn:async()=>{
      return getSuite(suiteUid,token,logOut)
    },
    onSuccess:(res)=>{
       setSuite(res?.data)
    },
    onError:(err)=>{
      notify(`error : ${err?.response?.status}`)
    }
  })

  const {mutate:updateSuiteParams} = useMutation({
    mutationFn:async(values)=>{
       
      const payload ={
        "suiteName": suite?.suiteName,
        "appList": [
          {
            "appParameters": values ,
            "app":{
              "appName": currSuiteApp?.app?.appName,
                    "version": currSuiteApp?.app?.version,
                    "image": {
                        "imageName": currSuiteApp?.app?.image?.imageName
                    }
            }
          }
        ]
      }
      return updateSuite(payload,suiteUid,token, logOut)
    },
    onSuccess:(data)=>{
      notify(`success : parameters updated .`)
      get_suite()
    },
    onError:(err)=>{
      notify(`error : ${err?.response?.status}`)
    }
  })
  

  // const updateSuiteParams = async(values) =>{

  //   const payload ={
  //   "suiteName": suite?.suiteName,
  //   "appList": [
  //     {
  //       "appParameters": await values ,
  //       "app":{
  //         "appName": currSuiteApp?.app?.appName,
  //               "version": currSuiteApp?.app?.version,
  //               "image": {
  //                   "imageName": currSuiteApp?.app?.image?.imageName
  //               }
  //       }
  //     }
  //   ]
  //   }

  //   payload && user && token && updateSuite(payload,suiteUid,token)
  //   .then(res => {
  //     console.log(res,36)
  //     setUpdatedSuite(c=>c+1)
  //   })
  //   .catch(err => console.error(err))


  //   console.log(payload,currSuiteApp,40)
  // }

  useEffect(()=>{
    get_suite()

  },[])


  return (
    <>
     <div className='container-fluid navbar-light px-5 position-relative pb-4 suite_details'>
        <div className="row breadcrumbs mt-3 d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">        
            <div className="col-md-12">              
              <nav aria-label="breadcrumb"  className='d-flex'>
                <h3 className="title me-3">Suite</h3>
                <Breadcrumbs page={['Suite',`${suite?.suiteName}`]}/>
              </nav>
            </div>              
        </div>
        <Container className='mt-3' fluid='true'>
          <div className='p-4 shadow-sm border-light card'>
            <Row xs={1} md={2} className="g-4 m-2">
                <Col md={5} lg={2} style={{margin: '0', padding: '12px', alignContent: 'center'}}>
                  <img 
                    style={{objectFit:'fit', width:'100%', borderRadius: '8px'}}
                    // className='object-fit-cover border rounded w-100 h-100' 
                    src={suite && suite.image}
                    alt=" "
                   />
                </Col>
                <Col md={9} xs={12} style={{margin: '0', padding: '16px', paddingLeft: '36px'}}>
                  <div className='product-info'>
                    <div className='title d-flex'>
                      <h3 className='me-3'>{suite?.suiteName} : {suite?.version}</h3>
                      {/* <div className='virifyied-icon'> */}
                        {/* <ReactSVG src={pencil} />  */}
                      {/* </div>                                           */}
                    </div>
                    {['sm'].map((breakpoint) => (
                      <ListGroup key={breakpoint} horizontal={breakpoint} className="my-0">
                        {/* <BiUser /> */}
                        <ListGroup.Item className='py-0 ps-0 border-0'>Created by : {suite?.user?.username}</ListGroup.Item>
                      </ListGroup>
                    ))}
                    {['sm'].map((breakpoint) => (
                      <ListGroup key={breakpoint} horizontal={breakpoint} className="mt-3">
                        <ListGroup.Item className='py-0 px-5 ps-0 border-0 border-end'>
                          <span>OS/ARCH</span>
                          <p className='mb-0'>{suite && suite.architecture}</p>                          
                        </ListGroup.Item>                                             
                        <ListGroup.Item className='py-0 px-5 border-0 border-end'>                         
                          <span>LAST UPDATE</span>
                          {/* <p className='mb-0'><BiCalendarAlt/> 20 May, 2023</p> */}
                          <p className='mb-0'>20 May, 2023</p>
                        </ListGroup.Item>
                        <ListGroup.Item className='py-0 px-5 border-0 border-end'>
                          <span>DOWNLOADS</span>
                          <p className='mb-0'><BiCloudDownload /> {suite?.downloads}</p>
                        </ListGroup.Item>
                        <ListGroup.Item className='py-0 px-5 border-0'>                          
                          <span>VULNERABILTIES</span>
                          <Stack className='mt-1' direction="horizontal" gap={2}>                            
                            <Badge bg="danger">1C</Badge>
                            <Badge bg="warning" text="dark">
                              4H
                            </Badge>
                            <Badge bg="info">15M</Badge>                            
                            <Badge bg="secondary">6L</Badge>
                          </Stack>
                        </ListGroup.Item>                        
                      </ListGroup>
                    ))}
                    <div className='description mt-3'><br/>
                      <h4>Description :</h4>
                      <p>{suite?.description}</p>
                    </div>
                  </div>  
                </Col>
               {/*  <Col md={3} xs={12}>
                  <img src={donut_chart} className='img-fluid float-end' alt=''/>
                    <PieChart/> 
                </Col>
                */}
            </Row>

            {/* <MultipleItems/>            */}
            <div className='app_table mt-5 px-3 '>
              <h4>Apps :</h4>
              <TableApps
                app={suite} 
                appParameters={currSuiteApp?.appParameters}
                updateSuiteParams={updateSuiteParams}
              />
            </div>
            
            <Container fluid='true'>
            <div className='mt-5 device connected_tab' fluid='true'>
            <h4>Device :</h4>
            <Row>
              <Col>
                <Card className='p-5 shadow-sm border-light'>
                
                <SuiteDevicesTab
                  suite={suite}
                />
                </Card>              
              </Col>
            </Row>
            </div> 
            </Container>
            
              
          </div>
          
        </Container>
        
     </div>
    </>
   
  )
}

export default SuiteDetails