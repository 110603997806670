import { Button, Form } from "react-bootstrap";
import { useEffect, useState } from 'react';
import { BsSearch } from "react-icons/bs";
import {  filter, x } from '../Component/Images/Images';
import { scopes, statuses ,deviceStatuses } from "../utility/Constants";
import { useFormik } from 'formik';

export function useSearch({ list, setSearchActive, objectType }) {

  const [show, setShow] = useState(false)
  const [filteredList, setFilteredList] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const initialValues = {
    search: '',
    scope: [],
    status: []
  }


  function findCommonElements(arrays) {

    if (arrays?.length === 0) {
      return []
    }

    // Find common elements
    const commonElements = arrays.reduce((result, currentArray) => {
      const resultant = result.filter(value => currentArray.includes(value));
      return resultant
    });

    return commonElements

  }

  function filterNonEmptyArrays(array1, array2, array3) {
    // Filter out empty arrays
    const nonEmptyArrays = [array1, array2, array3].filter((arr) => arr.length > 0);

    return nonEmptyArrays;
  }

  const onSubmit = val => {

    let searchResults
    let scopeResults
    let statusResults

    // setClick(!searchClick)
    if (objectType === 'suite') {
      // search with name
      searchResults = list?.filter(suite => suite?.suiteName?.includes(formik?.values?.search) && formik?.values?.search !== '')
      // search with scope
      scopeResults = list?.filter(suite => formik?.values?.scope?.includes(suite?.scope))
      // search with status
      statusResults = list?.filter(suite => formik?.values?.status?.includes(suite?.status))
    }
    if (objectType === 'app') {
      // search with name
      searchResults = list?.filter(app => app?.appName?.includes(formik?.values?.search) && formik?.values?.search !== '')
      // search with scope
      scopeResults = list?.filter(app => formik?.values?.scope?.includes(app?.scope))
      // search with status
      statusResults = list?.filter(app => formik?.values?.status?.includes(app?.status))
    }

    if (objectType === 'device') {
      // search with name
      searchResults = list?.filter(device => device?.deviceName?.includes(formik?.values?.search) && formik?.values?.search !== '')
      // search with scope
      scopeResults = list?.filter(device  => formik?.values?.scope?.includes(device?.scope))
      // search with status
      statusResults = list?.filter(device => formik?.values?.status?.includes(device?.status))
    }


    const nonEmptyResults = filterNonEmptyArrays(searchResults, scopeResults, statusResults)

    const commonElements = findCommonElements(nonEmptyResults);

    setFilteredList(commonElements)
    setSearchTerm(formik?.values?.search)

  };

  const formik = useFormik({
    initialValues,
    onSubmit,
  });

  useEffect(() => {

    if (formik?.values?.search === '' && formik?.values?.scope?.length === 0 && formik?.values?.status?.length === 0) {
      setSearchActive(false)
      setFilteredList([])
    }
    else {
      setSearchActive(true)
      
      let searchResults
      let scopeResults
      let statusResults

      // setClick(!searchClick)
      if (objectType === 'suite') {
        // search with name
        searchResults = list?.filter(suite => suite?.suiteName?.includes(formik?.values?.search) && formik?.values?.search !== '')
        // search with scope
        scopeResults = list?.filter(suite => formik?.values?.scope?.includes(suite?.scope))
        // search with status
        statusResults = list?.filter(suite => formik?.values?.status?.includes(suite?.status))
      }
      if (objectType === 'app') {
        // search with name
        searchResults = list?.filter(app => app?.appName?.includes(formik?.values?.search) && formik?.values?.search !== '')
        // search with scope
        scopeResults = list?.filter(app => formik?.values?.scope?.includes(app?.scope))
        // search with status
        statusResults = list?.filter(app => formik?.values?.status?.includes(app?.status))
      }

      if (objectType === 'device') {
        // search with name
        searchResults = list?.filter(device => device?.deviceName?.includes(formik?.values?.search) && formik?.values?.search !== '')
        // search with scope
        scopeResults = list?.filter(device  => formik?.values?.scope?.includes(device?.scope))
        // search with status
        statusResults = list?.filter(device => formik?.values?.status?.includes(device?.status))
      }
  

      const nonEmptyResults = filterNonEmptyArrays(searchResults, scopeResults, statusResults)

      const commonElements = findCommonElements(nonEmptyResults);

      setFilteredList(commonElements)
      setSearchTerm(formik?.values?.search)
    }
    // console.log(formik.values)
  }, [formik?.values, list, objectType, setSearchActive])




  const formSearch = (
    <Form noValidate className=" bg-white border rounded-pill pe-2  " onSubmit={formik.handleSubmit}>
      <div className='d-flex  '>
        <Form.Control
          type="search"
          placeholder="Search"
          className=" rounded-pill border-0 "
          aria-label="Search"
          required
          name='search'
          onChange={formik.handleChange}
        />
        <Button type='submit' variant="white rounded-pill  border-0">
          <BsSearch />
        </Button>

        <Button
          variant="white rounded-pill border-0 "
          onClick={() => setShow(!show)}
        >
          <img src={show ? x : filter} alt='' />
        </Button>
      </div>

      <div
        style={{ position: 'absolute', right: '3rem', zIndex: '1', width:'20%' }}
        className={`bg-white p-2 border pe-3 rounded-2 ${show ? 'visible' : 'invisible'}`} >
        <Form.Label>Filter by : </Form.Label>

        {
          objectType !== 'device' &&
          <Form.Group className="mb-3 border p-2" >
          <Form.Label>scope</Form.Label>
          {
            scopes?.map(scope => {
              return (
                <Form.Check // prettier-ignore
                  key={scope}
                  name='scope'
                  type='checkbox'
                  value={scope}
                  onChange={formik.handleChange}

                  label={scope?.toLowerCase()}
                />
              )
            })
          }
        </Form.Group>
        }
        

        {objectType === 'suite' &&
          <Form.Group className="mb-3 border p-2" >
            <Form.Label>status</Form.Label>
            {
              statuses?.map(status => {
                return (
                  <Form.Check // prettier-ignore
                    key={status}
                    name='status'
                    type='checkbox'
                    value={status}
                    onChange={formik.handleChange}

                    label={status?.toLowerCase()}
                  />
                )
              })
            }
          </Form.Group>}

          {objectType === 'device' &&
          <Form.Group className="mb-3 border p-2" >
            <Form.Label>status</Form.Label>
            {
              deviceStatuses?.map(status => {
                return (
                  <Form.Check // prettier-ignore
                    key={status}
                    name='status'
                    type='checkbox'
                    value={status}
                    onChange={formik.handleChange}

                    label={status?.toLowerCase()}
                  />
                )
              })
            }
          </Form.Group>}

        <Button
          type='reset'
          variant="secondary"
          size="sm"
          className='px-1 py-0 '
          onClick={() => {
            formik.resetForm()
          }}
        >Reset</Button>
      </div>
    </Form>
  )



  return ({
    formSearch,
    filteredList,
    searchTerm
  });
}


