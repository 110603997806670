import  {useState,useEffect} from 'react';
import Breadcrumbs from '../../Component/miscellaneous/Breadcrumb';
import { Card, Button, Container, Row, Col, Image} from 'react-bootstrap';
import DeviceDetailsCard from '../../Component/cards/DeviceDetailsCard';
import { BiUserPlus, BiRocket, BiRevision, BiBrush } from "react-icons/bi";
import { ReactSVG } from 'react-svg';
import Spinner from 'react-bootstrap/Spinner';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import renderTooltip from '../../Component/miscellaneous/Overlay';
import { useParams} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Mqtt from '../../hooks/Mqtt'
import {getDeviceCard} from '../../services/Api'
import  DeviceDetailsTab  from '../../Component/tabs/DeviceDetailsTab';
import  SuitesDeviceDetails  from '../../Component/box/SuitesDeviceDetails';
import VpnConnection from '../../Component/stepper/VpnConnection';
import DemoDeviceDetailsCard from '../../Component/cards/DemoDeviceDetailsCard';


const reducer = (appAction,dispatchObj) =>{
    if(dispatchObj._action === 'start' || dispatchObj._action === 'restart'){
      return (
        {
          app : dispatchObj.app,
          _action : 'running'
        }
      )
    }
    if(dispatchObj._action === 'stop'){
      return (
        {
          app : dispatchObj.app,
          _action : 'exited'
        }
      )
    }
}

const reducerLoad = (loading,dispatchObj) => {
  if(dispatchObj.load === 'deploy'){
    return({load:'deploy', state:dispatchObj.state })
  }
  if(dispatchObj.load === 'reboot'){
    return({load:'reboot', state:dispatchObj.state })
  }
  if(dispatchObj.load === 'purge'){
    return({load:'purge', state:dispatchObj.state })
  }
  if(dispatchObj.load === 'exited'){
    return({load:'exited', state:dispatchObj.state })
  }
  if(dispatchObj.load === 'running'){
    return({load:'running', state:dispatchObj.state })
  }
  if(dispatchObj.load === 'restart'){
    return({load:'restart', state:dispatchObj.state })
  }
  else{
    return({load:dispatchObj.load, state:dispatchObj.state })
  }
}

function DemoDeviceDetails() {



 
 



  const [cpu, setCpu] = useState(Math.trunc(23 + Math.floor(Math.random()*3)))
  const [temp, setTemp] = useState(Math.trunc(57 + Math.floor(Math.random()*3)))
  const [mem, setMem] = useState(Math.trunc(36 + Math.floor(Math.random()*3)))


  function setOff(c){
    if(c >= 95){
      return c-65
    }
    if (c <= 20) {
      return c+3
    }
    else{
      let rand = Math.random()
      return c + (rand > 0.5 ? Math.floor(Math.random()*2) : -1*Math.floor(Math.random()*2))
    }
  }

  useEffect(()=>{
   
    let interval = setInterval(()=>{
       setCpu(setOff(cpu))
       setMem(setOff(mem))
       setTemp(setOff(temp))
    },30000)
    return ()=> clearInterval(interval)

  },[cpu,temp,mem])

  return (
    <>
  
    
    <div className='device-details'> 
       {/* {console.log(location,392)} */}
        <div className='container-fluid px-5 position-relative pb-5'>
          <div className="row breadcrumbs mt-3 d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">        
              <div className="col-md-6 col-xs-12">              
                <nav aria-label="breadcrumb"  className='d-flex'>
                  <h3 className="title me-3">Device Details</h3>

                  <Breadcrumbs page={[`Device`,`_demoDevice`]} />

                </nav>
              </div>
              <div className="col-md-6 col-xs-12">
                <div className='action-btn me-3 d-flex float-end'>

                  <Button className='me-2' variant="outline-primary" ><BiUserPlus/> Identify</Button>

                  <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(`No suite connected`) } trigger={ ['hover','focus']} >
                  <Button 
                  className='me-2' 
                  variant="outline-primary" 
                  disabled={true}>
                  
                    <BiRocket/>Deploy
                  
                   </Button>
                   </OverlayTrigger>
              
                  <Button 
                  className='me-2' 
                  variant="outline-primary" 

                  disabled={true}>
                  <BiRevision/>Reboot 
                  </Button>

                   <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={ renderTooltip('No app running') } trigger={ ['hover','focus']}>
                   <Button variant="outline-danger" 
                   disabled={true}>
                   <BiBrush/>Purge
                   </Button>
                   </OverlayTrigger>

                </div>
              </div>
          </div>

          <Container className='mt-3' fluid>
            <DemoDeviceDetailsCard
            memory={mem}
            cpu={cpu}
            temperature={temp}
            />
          </Container>          
         
          <Container className='mt-5' fluid>          
            <Row xs={1} md={2} className="g-4">
              <Col md={6} xs={12}>  
                <SuitesDeviceDetails/>
              </Col> 

               <Col md={6} xs={12}>                
                <VpnConnection/>  
               </Col>             
            </Row>                             
          </Container>
          

          <DeviceDetailsTab

           
          />       
      </div>
    </div>


    </>
    
    
  )
}

export default DemoDeviceDetails