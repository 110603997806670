import React, { useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { ReactSVG } from 'react-svg';
import { vertical_icon, verified, edit, share, trash, clone } from '../Images/Images';
import { Dropdown, Card, ListGroup, Button } from 'react-bootstrap';
import { Rating } from 'react-simple-star-rating'
import DeleteModal from '../modals/DeleteModal';
import { BiHeart } from "react-icons/bi";
import { getSuite } from '../../services/Api'
import Heart from "react-animated-heart";


const SuiteCard = ({ user, suite, searchActive, searched_term, _deleteSuite, token }) => {

  let left_parted_name = suite && searched_term && suite.suiteName.slice(0, suite.suiteName.indexOf(searched_term))
  let right_parted_name = suite && searched_term && suite.suiteName.slice(suite.suiteName.indexOf(searched_term) + searched_term.length)
  const navigate = useNavigate();
  const [rating, setRating] = useState(0);
  const [show, setShow] = useState(false)
  const [isClick, setClick] = useState(false);

  return (
    <>
      <div className='suit_item mb-5 '>
        <Card className='shadow border-0'>
          <Card.Body className='p-4'>
            <div className="d-flex justify-content-between mb-3">
              <div className="ribbon">
                <span className={suite && suite.scope === "PUBLIC" ? "tag public" : suite.scope === "PRIVATE" ? "tag private" : "tag org"}>
                  {suite && suite.scope === "ORGANIZATION" ? 'ORG' : suite.scope}
                </span>
                {/* <div className="wrap">
              <span className={suite?.scope === "PUBLIC" ? "ribbon6 public" : suite?.scope === "PRIVATE" ? "ribbon6 private" : "ribbon6 org"}>
                {suite && suite.scope === "ORGANISATION" ? 'ORG' : suite.scope }
              </span>
            </div> */}
              </div>
              <div className="vertical-icon">
                <Dropdown align="end">
                  <Button variant='link fs-3 heart'>
                    {/* <BiHeart/></Button> */}
                    <Heart styles={{ margin: '-27px -30px -40px -40px' }} isClick={isClick} onClick={() => setClick(!isClick)} /></Button>
                  <Dropdown.Toggle variant="default" id="dropdown-basic" className='bg-white border-light border-0 p-0'>
                    <ReactSVG src={vertical_icon} />
                  </Dropdown.Toggle>
                  {user === suite?.user?.username &&
                    <Dropdown.Menu className='py-2 shadow border-light'>
                      {/* <Dropdown.Item className='d-flex' >
                      <ReactSVG className='me-2' src={clone} />
                      <button className='bg-transparent border-0 text-body'>
                        Clone
                      </button>
                    </Dropdown.Item> */}
                      {/* {user === suite?.user?.username &&
                        <Dropdown.Item className='d-flex' >
                          <ReactSVG className='me-2' src={edit} />
                          <button className='bg-transparent border-0 text-body'>
                            Edit Suite
                          </button>
                        </Dropdown.Item>
                      } */}
                      {user === suite?.user?.username &&
                        <Dropdown.Item className='d-flex' ><ReactSVG className='me-2' src={share} />
                          <button disabled className='bg-transparent border-0 text-body text-muted' data-toggle="tooltip" title="Coming Soon ...">Publish Suite
                          </button>
                        </Dropdown.Item>
                      }
                      {user === suite?.user?.username &&
                        <Dropdown.Item className='d-flex'>
                          <ReactSVG className='me-2' src={trash} />
                          <button className='bg-transparent border-0 text-body' onClick={() => setShow(true)}>
                            Delete Suite
                          </button>
                        </Dropdown.Item>
                      }
                    </Dropdown.Menu>
                  }
                </Dropdown>
              </div>
            </div>
            <div
              className="d-flex justify-content-center">
              <div
                className="device-img position-relative"
                onClick={
                  () => {
                    suite?.status === 'CREATED' ? navigate(`/suite/${suite?.suiteUid}`) : navigate(`/suite/create/${suite?.suiteUid}`)
                  }
                }
              >
                <Card.Img className='img-2 rounded-pill shadow' variant="top" src={`${suite?.image}`} />
                {suite?.user?.username === "theopenfactory" && <ReactSVG src={verified} className="position-absolute verified bottom-0 end-0" />}
              </div>

            </div>
            {
              searchActive && searched_term !== '' ?
                <>
                  {/* <Link className='card-title h3 my-2 text-center' > */}
                  <Card.Title className='card-title h2 mb-2 mt-3 text-left'
                    onClick={
                      () => suite?.status === 'CREATED' ? navigate(`/suite/${suite?.suiteUid}`) : navigate(`/suite/create/${suite?.suiteUid}`)
                    }
                  >
                    <span className='text-center '>{left_parted_name}</span>
                    <span className='text-center text-primary'>{searched_term}</span>
                    <span className='text-center '>{right_parted_name}</span>
                  </Card.Title>

                  {/* </Link> */}
                </>
                :
                //  <Link className='card-title h3 my-2 text-center' to={suite && `${suite.suiteName}`}>{suite && suite.suiteName}</Link> 
                <Card.Title className='card-title h2 mb-2 mt-3 text-left'
                  onClick={
                    () => suite?.status === 'CREATED' ? navigate(`/suite/${suite?.suiteUid}`) : navigate(`/suite/create/${suite?.suiteUid}`)
                  }
                >
                  {suite?.suiteName}
                </Card.Title>
            }

            {/* <Link className='card-title h3'>{suite && suite.suiteName}</Link> */}

            {['sm'].map((breakpoint) => (
              <ListGroup key={breakpoint} horizontal={breakpoint} className="my-0 d-flex justify-content-between">
                <ListGroup.Item className='p-0 border-0 border-right me-2'>{suite?.user?.username}</ListGroup.Item>
                <div className="vr"></div>
                <ListGroup.Item className='p-0 border-0 border-right me-2'>{suite?.downloads} </ListGroup.Item>
                <div className="vr"></div>
                <ListGroup.Item className='p-0 border-0 border-right'>{suite?.version}</ListGroup.Item>
              </ListGroup>
            ))}

            <div className="d-flex justify-content-between rating mt-2">
              <Rating
                onClick={(rate) => setRating(rate)}
                // onPointerEnter={() => console.log('Enter')}
                // onPointerLeave={() => console.log('Leave')}
                // onPointerMove={(value, index) => console.log(value, index)}
                fillColor={'#0C90E1'}
                size={'17'}
              /* Available Props */
              />
              <div className="vr"></div>
              <Card.Text className='ms-2 mt-1'>
                {suite && suite.architecture}
              </Card.Text>

            </div>
            <Card.Text className='mt-2 desc-text' data-bs-toggle="tooltip" title={suite?.description}>
              {suite && suite.description.length > 55 ? suite.description.slice(0, 55) + '...' : suite.description}
            </Card.Text>

          </Card.Body>
        </Card>
      </div>

      <DeleteModal _delete={_deleteSuite} Name={suite?.suiteUid} show={show} handleClose={() => setShow(false)} token={token} />

    </>

  )
}

export default SuiteCard