import { useState, useEffect, useContext } from 'react'
import { useAuth } from '../../context/AuthContext';
import { Outlet } from "react-router-dom";
import Navbars from '../../Component/navigation/Navbars'
import Sidebar from '../../Component/navigation/Sidebar'
import Mqtt from '../../hooks/Mqtt';
import { ToastContainer } from 'react-toastify';


function Suite() {

  const { user, token, login_user, logOut, userID } = useAuth()

  return (
    <>
      <ToastContainer />
      <Sidebar user={user} logOut={logOut} userID={userID} />
      <div className="my-container bg-light active-cont">
        <Navbars user={user} logOut={logOut} login_user={login_user} />
        <Outlet />
      </div>

    </>

  )
}

export default Suite