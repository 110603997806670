import { useContext } from 'react'
import { useAuth } from '../../context/AuthContext';
import { Outlet } from "react-router-dom";
import Navbars from '../../Component/navigation/Navbars'
import Sidebar from '../../Component/navigation/Sidebar'

const HomeDash = () => {
    const { user, logOut, userID } = useAuth()

    return (
        <>
            <Sidebar user={user} logOut={logOut} userID={userID} />
            <div className="my-container bg-light active-cont">
                <Navbars user={user} logOut={logOut} />
                <Outlet />
            </div>
        </>
    )
}

export default HomeDash