import {useEffect,useState,useContext,Fragment} from 'react'
import { useAuth } from '../../context/AuthContext';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import InputGroup from 'react-bootstrap/InputGroup';
import {useFormik} from 'formik';
import * as yup from 'yup';
import PreviewImage from '../../utility/Preview';
import {BiCamera } from "react-icons/bi";
// import {suggestLocations,getLocation} from "../../services/Api"
// import mapboxgl from 'mapbox-gl';

function AddDeviceForm({add,submitted,newUser,setNewUser}) {

  const {token} = useAuth()
  // const [locations,setLocations] = useState()
  // const [coordinates,setCoordinates] = useState()
  // const [searchState,setSearchState] = useState()
  
  const initialValues = {
    deviceName:newUser?'DemoDevice':'',
    description: '',
    imageFile: null,
    // place:'',
    locationId:'',
    longitude:'',
    latitude:'',

  }

  const validationSchema = yup.object({
    deviceName: yup.string().required('Device Name cannot be empty !'),
    description: yup.string().required('Please provide a description !'),
    longitude:yup.number(),
    latitude:yup.number(),
    place:yup.string(),
    locationId:yup.string(),
    imageFile: yup.mixed().required('Please add an image !')
    .test("File Type","Only image(.jpeg ,.jpg and .png) supported!", value => value && ['image/png','image/jpeg','image/jpg'].includes(value.type))
    .test("File Size","File size too big!", value =>value && value.size < 1024 * 1024)
  });
  
  const onSubmit = async(val) => {

    console.log(val,formik.values)
    const device = new FormData();

    Object.keys(val).forEach(key => {
      if(!['place'].includes(key) ){
        device.append(key,val[key])
      }
    });

    const args ={
      method : 'POST',
      object: device,
      values:val
    }
    add(args);
    submitted(true);
    newUser && setNewUser(false)
  }

  const formik = useFormik({initialValues,onSubmit,validationSchema});

  // const getLoc = () => {

  //     function showPosition(position){
  //        const longitude = position.coords.longitude
  //        const latitude = position.coords.latitude
         
  //        const loc = {'longitude' : longitude, 'latitude' : latitude}
  //        setCoordinates(loc)
  //       //  console.log(longitude, latitude ,loc)
  //        return loc
  //     }
  //     // const geoloc = navigator.geolocation.getCurrentPosition(showPosition)
  //     if(navigator.geolocation){
  //       navigator.geolocation.getCurrentPosition(showPosition);
  //     }
  //     else{
  //       // console.log('Not found')
  //     }
  //     // const locs = await getLocations(formik.values.place)
  // }

  // const setLoc = async() =>{
  //       const locs = await suggestLocations(formik.values.place,token)
  //       locs && setLocations(locs?.suggestions) 
  // }

  // const modify_form = async(location) =>{

  //   formik.setFieldValue('place',`${location?.name},${location?.place_formatted}`)
  //   formik.setFieldValue('locationId',location?.mapbox_id)
    
  //   const location_id = location?.mapbox_id
  //   const place = await getLocation(location_id,token)

  //   const latitude = await place?.features?.[0]?.geometry?.coordinates?.[1]
  //   const longitude = await place?.features?.[0]?.geometry?.coordinates?.[0]

  //   await formik.setFieldValue('latitude',Number(latitude))
  //   await formik.setFieldValue('longitude',Number(longitude))
  // }




  // useEffect(()=>{

  //   formik?.values?.place?.length !==0 && setLoc() 

  // },[formik?.values?.place])

  return (
       
        <Form onSubmit={formik.handleSubmit}>
          {/* {console.log(newUser,3333)} */}
            <div className='custom_upload position-relative mt-3 mb-4'>
                  <Form.Control
                      type="file"
                      className='position-absolute bottom-0 end-0 translate-middle'
                      name='imageFile'
                      onChange={e => formik.setFieldValue('imageFile', e.target.files[0])}
                      isInvalid={(formik.touched.imageFile && formik.errors.imageFile)}
                      isValid={formik.touched.imageFile}
                  />
                  {formik.values.imageFile && <PreviewImage file={formik.values.imageFile} />}
                  <span><BiCamera /></span>
              </div>
            
              <p className="text-center text-danger">{formik.touched.imageFile && formik.errors.imageFile}</p>

            <Form.Group
              controlId="validationFormik101"
              className="position-relative mb-3"
            >
              <Form.Label>Device Name</Form.Label>
              <Form.Control
                type="text"
                name="deviceName"
                className="form-control "
                value={formik.values.deviceName}
                readOnly={newUser}
                onChange={formik.handleChange}
                isInvalid={ (formik.touched.deviceName && formik.errors.deviceName)}
                isValid={formik.touched.deviceName}
              />
              {/* <p className='text-danger fs-6 '>{formik.errors.deviceName}</p> */}
            <Form.Control.Feedback type='invalid'>
              {formik.errors.deviceName}
            </Form.Control.Feedback>
            </Form.Group>

            <Form.Group
              controlId="validationFormik102"
              className="position-relative mb-3"
            >
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                className="form-control "
                // required
                value={formik.values.description}
                onChange={formik.handleChange}
                isInvalid={  (formik.touched.description && formik.errors.description)}
                isValid={formik.touched.description}
              />
               <Form.Control.Feedback type='invalid' >
              {formik.errors.description}
            </Form.Control.Feedback>
            </Form.Group> 

            {/* <Form.Group
              controlId="validationFormik101"
              className="m-0"
            >
              <Form.Label>Place</Form.Label>
              <Dropdown> 
                  <Dropdown.Toggle variant="default w-100 d-flex" id="dropdown-split-basic" className='bg-white border-light border-0 p-0'>
                  <Form.Control
                    type="text"
                    name="place"
                    placeholder="Place"
                    className="form-control m-0"
                    value={formik.values.place}
                    onChange={formik.handleChange}
                    isInvalid={ (formik.touched.place && formik.errors.place)}
                    isValid={formik.touched.place}
                  />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {
                      locations && 
                      locations.map(location => {
                        // console.log(location)
                        return(
                          <Fragment key={location.id}>
                          <Dropdown.Item 
                            onClick={()=>modify_form(location)}
                          >
                            {`${location?.name},${location?.place_formatted}`}
                          </Dropdown.Item>
                          </Fragment>
                          
                        )
                      })
                    }
                  </Dropdown.Menu>
              </Dropdown>
               <Form.Control.Feedback type='invalid' >
                {formik.errors.place}
               </Form.Control.Feedback>
            </Form.Group>         */}

            
            
            
            {/* <SearchBox accessToken={process.env.REACT_APP_MAPBOX_TOKEN}></SearchBox> */}

            

          <Button className="theme-btn mt-3 float-end btn btn-primary" type="submit">Add Device</Button>
        </Form>
  
  );
}

export default AddDeviceForm;