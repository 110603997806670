
import { useState, useContext, useEffect, useImperativeHandle, forwardRef } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { useQuery, useMutation } from 'react-query';
import SelectSuitesModal from '../modals/SelectSuitesModal';
import { Card, Button, Container, Row, Col, Image } from 'react-bootstrap';
import { BiWifiOff } from "react-icons/bi";
import { ReactSVG } from 'react-svg';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import renderTooltip from '../miscellaneous/Overlay';
import { running, check_circle, pencil } from '../Images/Images';
import { useAuth } from '../../context/AuthContext';
import { getSuites, connectSuite, getSuite } from '../../services/Api';

function SuitesDeviceDetails(props, ref) {

  const { token, user } = useAuth()
  const [show, setShow] = useState(false);
  const [suites, setSuites] = useState(); // Suites via API
  const [createdSuites, setCreatedSuites] = useState();// fully created suites
  const [connectedSuite, setConnectedSuite] = useState(); // already connected suite
  const [selectedSuite, setSelectedSuite] = useState() //select suite
  const [runningSuite, setRunningSuite] = useState()

  const select = (suite) => {
    console.log(suite)
    setSelectedSuite(suite?.suiteUid)
  }
  // console.log(token)
  const suitesResult = useQuery({
    queryKey: ['suites', token],
    queryFn: () => getSuites(token),
    enabled: !!token,
    onSuccess: (data) => {
      const _suites = data?.data
      console.log(_suites)
      setSuites(_suites)

    },
    onError: (error) => console.log(error),

  })

  useImperativeHandle(ref, () => {
    return {
       connectedSuite,
      //  isLoading
      
      isSuiteConnected: () => {
        if (connectedSuite) {
          return true
        }
        return false
      },
      isSuiteRunning: () => {
        if (props?.card?.runningSuite) {
          return true
        }
        return false
      }
    }
  });


  useEffect(() => {

    const filtered_suites = suites?.filter(suite => suite?.status === 'CREATED')
    setCreatedSuites(filtered_suites)

    const ready_connected_suite = suites?.find(suite => suite?.suiteName === props?.card?.pairedSuite?.suiteName)

    const ready_running_suite = suites?.find(suite => suite?.suiteName === props?.card?.runningSuite?.suiteName)

    // console.log(props,ready_connected_suite)
    setConnectedSuite(ready_connected_suite)
    setRunningSuite(ready_running_suite)

  }, [suites,props])

 






  



  return (

    <Card className='p-5 shadow-sm border-light suits'>
      <Card.Title className='mb-4'>Suits</Card.Title>
      <Row xs={1} md={2} className="g-4">
        <Col className='conected'>
          <Card className='shadow-sm border-light mb-3'>
            <Card.Header className='border-light d-flex align-items-center justify-content-between'>
              <div className='d-flex'>
                <ReactSVG className='me-2' src={check_circle} /> Connected
              </div>

              <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip('click to connect a suite')} trigger={['hover', 'focus']} >
                <Button className='float-end fs-5 p-0' variant="link" onClick={() => setShow(true)}>
                  <ReactSVG className='me-2 p-0' src={pencil} />
                </Button>
              </OverlayTrigger>

              <SelectSuitesModal
                show={show}
                onHide={show ? () => setShow(false) : () => setShow(true)}
                onClick={show ? () => setShow(false) : () => setShow(true)}
                suites={createdSuites}
                select={select}
                selectedSuite={selectedSuite}
                mutate={props?.mutate}
              />
            </Card.Header>
            <Card.Body className='text-center py-4'>
              {
                props?.isLoading ?
                  <Spinner animation="border" variant="primary" />
                  :
                  connectedSuite ?
                    <>
                      <Image height={70} width={70} roundedCircle src={`${connectedSuite?.image}`} />
                      <Card.Title className='fs-6 mt-2'>{connectedSuite.suiteName}</Card.Title>
                    </>
                    :
                    <>
                      <BiWifiOff className='text-gray fs-1 disabled' />
                      <Card.Title className='fs-6 mt-2'>No Suite Connected</Card.Title>
                    </>
              }
            </Card.Body>

          </Card>
        </Col>
        <Col className='running'>
          <Card className='shadow-sm border-light mb-3'>
            <Card.Header className='border-light d-flex'>
              <ReactSVG className='me-2' src={running} />
              Running
            </Card.Header>
            <Card.Body className='text-center py-4 '>
              {runningSuite ?
                <>
                  <Image height={70} width={70} roundedCircle
                    src={`${runningSuite?.image}`} />

                  <Card.Title className='fs-6 mt-2'>{runningSuite?.suiteName}</Card.Title>

                </>
                :
                <>
                  <BiWifiOff className='text-gray fs-1 disabled' />
                  <Card.Title className='fs-6 mt-2'>No Suite Running</Card.Title>
                </>
              }
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Card>

  )
}

export default forwardRef(SuitesDeviceDetails)
