import { useState, useEffect, createContext } from "react";
import "./App.scss";
import { Routes, Route, useNavigate, Outlet } from "react-router-dom";
import Cookies from "universal-cookie";
import jwt from "jwt-decode";
import Home from "./Pages/Home";
import { QueryClientProvider, QueryClient } from "react-query";
import { AllDevices, DeviceDetails, Device } from "./Pages/devices";
import { Apps, AppDetails, Appstore } from "./Pages/apps";
import {
  CreateSuite2,
  CreateSuite3,
  AllSuites,
  Suite,
  SuiteDetails,
} from "./Pages/suites";
import { SignIn, SignUp, ForgotPassword } from "./Pages/authentication";
import { DemoDeviceDetails, DemoSuiteDetails } from "./Pages/demoPages";
import { Dashboard, HomeDash } from "./Pages/dashboard";
import PageNotFound from "./Pages/PageNotFound";
import RequireAuth from "./Component/contextWrappers/RequireAuth";
import { useAuth, AuthProvider } from "./context/AuthContext";

function App() {
  if (process.env.NODE_ENV !== "development") {
    console.log = () => {};
    console.debug = () => {};
    console.info = () => {};
    console.warn = () => {};
}

  const queryClient = new QueryClient();

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="device" element={<Device />}>
              <Route
                index
                element={
                  <RequireAuth>
                    <AllDevices />
                  </RequireAuth>
                }
              />
              <Route
                path=":deviceName"
                element={
                  <RequireAuth>
                    <DeviceDetails />
                  </RequireAuth>
                }
              />
              <Route path="_demoDevice" element={<DemoDeviceDetails />} />
            </Route>

            <Route path="suite" element={<Suite />}>
              <Route
                index
                element={
                  <RequireAuth>
                    <AllSuites />
                  </RequireAuth>
                }
              />
              <Route
                path=":suiteUid"
                element={
                  <RequireAuth>
                    <SuiteDetails />
                  </RequireAuth>
                }
              />
              <Route path="demo/:suiteUid" element={<DemoSuiteDetails />} />

              <Route
                path="create"
                element={
                  <RequireAuth>
                    <Outlet />
                  </RequireAuth>
                }
              >
                <Route
                  index
                  element={
                    <RequireAuth>
                      <CreateSuite2 />
                    </RequireAuth>
                  }
                />
                <Route
                  path=":suiteUid"
                  element={
                    <RequireAuth>
                      <CreateSuite3 />
                    </RequireAuth>
                  }
                />
              </Route>
            </Route>

            <Route path="appstore" element={<Apps />}>
              <Route
                index
                element={
                  <RequireAuth>
                    <Appstore />
                  </RequireAuth>
                }
              />
              <Route
                path=":appUid"
                element={
                  <RequireAuth>
                    <AppDetails />
                  </RequireAuth>
                }
              />
            </Route>

            <Route path="dashboard" element={<HomeDash />}>
              <Route
                index
                element={
                  <RequireAuth>
                    <Dashboard />
                  </RequireAuth>
                }
              />
            </Route>

            <Route path="signin" element={<SignIn />}></Route>
            <Route path="signup" element={<SignUp />}></Route>
            <Route path="forgotpassword" element={<ForgotPassword />}></Route>
            <Route path="/*" element={<PageNotFound />}></Route>
          </Routes>
        </AuthProvider>
      </QueryClientProvider>
    </>
  );
}
export default App;
