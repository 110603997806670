
import { Spinner } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

function MutateLoader({show,entity,action}) {

  
  return (
    <>
     {/* <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        centered
        size='sm'
      >
        <div className='text-center  '>
          <Spinner animation="border" variant="info" className='mt-4'/>
          <p>{action} {entity} ...</p> 
        </div>
         
      </Modal> */}
      <div 
      className={show?'visible':'invisible'}
      style={{position:'fixed',left:'0',top:'0',bottom:'0',right:'0',backdropFilter:'blur(15px)',height:'100%',width:'100%',zIndex:2}}>
        <div 
        style={{position:'relative',left:'40%',top:'40%' }} 
        className='d-flex flex-column align-items-center  w-25 '>
          <Spinner animation="border" variant="info" className='mt-4 '  />
          <div className=' '>
            <p className='fs-3 '>{action} {entity} ...</p> 
          </div>
          
        </div>
          
      </div>

    </>
  );
}

export default  MutateLoader;