import { useState, useEffect, useReducer, useContext, useRef } from 'react';
import { useMutation } from 'react-query';
import { useAuth } from '../../context/AuthContext';
import Breadcrumbs from '../../Component/miscellaneous/Breadcrumb';
import { Card, Button, Container, Row, Col, Image } from 'react-bootstrap';
import DeviceDetailsCard from '../../Component/cards/DeviceDetailsCard';
import { BiFingerprint, BiRocket, BiRevision, BiGhost } from "react-icons/bi";
import { ReactSVG } from 'react-svg';
import Spinner from 'react-bootstrap/Spinner';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import renderTooltip from '../../Component/miscellaneous/Overlay';
import { useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { getDeviceCard, connectSuite } from '../../services/Api'
import { DeviceDetailsTab } from '../../Component/tabs';
import SuitesDeviceDetails from '../../Component/box/SuitesDeviceDetails';
import { VpnConnection } from '../../Component/stepper';
import { notify } from '../../utility/Helper';
import { useDevice } from '../../context/DeviceContext';

const reducer = (appAction, dispatchObj) => {
  if (dispatchObj._action === 'start' || dispatchObj._action === 'restart') {
    return (
      {
        app: dispatchObj.app,
        _action: 'running'
      }
    )
  }
  if (dispatchObj._action === 'stop') {
    return (
      {
        app: dispatchObj.app,
        _action: 'exited'
      }
    )
  }
}

const reducerLoad = (loading, dispatchObj) => {
  if (dispatchObj.load === 'deploy') {
    return ({ load: 'deploy', state: dispatchObj.state })
  }
  if (dispatchObj.load === 'reboot') {
    return ({ load: 'reboot', state: dispatchObj.state })
  }
  if (dispatchObj.load === 'purge') {
    return ({ load: 'purge', state: dispatchObj.state })
  }
  if (dispatchObj.load === 'exited') {
    return ({ load: 'exited', state: dispatchObj.state })
  }
  if (dispatchObj.load === 'running') {
    return ({ load: 'running', state: dispatchObj.state })
  }
  if (dispatchObj.load === 'restart') {
    return ({ load: 'restart', state: dispatchObj.state })
  }
  else {
    return ({ load: dispatchObj.load, state: dispatchObj.state })
  }
}


function DeviceDetails() {

  const { deviceName } = useParams();//current device
  const { user, token, logOut, wsPayload, ws } = useAuth()
  // const { Payload } = useDevice()
  const [card, setCard] = useState();// Device via API
  const [cardPayloadWS, setCardPayloadWS] = useState(); // Device payload via mqtt server
  const [apps, setApps] = useState();//Apps via mqtt server

  const [mqttActions, setMqttActions] = useState(false);
  const [mqtt_busy, setMqtt_busy] = useState(false);

  const [appAction, dispatch] = useReducer(reducer, null)
  const [loading, dispatchLoad] = useReducer(reducerLoad, { load: null, state: false })
  const [downloading, setDownloading] = useState(0);

  const suitesBoxRef = useRef()
  const DeviceDetailsTabRef = useRef()
  const DetailsCardRef = useRef()

  const { data: suiteConnected, isLoading, isError, mutate } = useMutation({
    queryKey: ['connectedSuite'],
    mutationFn: (suite) => {
      return connectSuite(suite, card?.deviceName, token, logOut)
    },
    onSuccess: () => {
      console.log(suiteConnected?.data)
      getDevice()
    }
  })

  //Fetching the card 
  const getDevice = async () => {
    token && getDeviceCard(deviceName, user, token, logOut)
      .then(response => {
        // console.log(user,deviceName)
        setCard(response.data)
      })
      .catch(err => console.error(err))
  }

  //Publish the payload
  const publishCombined = (action) => {

    const checkedApps = DeviceDetailsTabRef.current.checkedApps()
    console.log(checkedApps)
    const app_array = checkedApps.map(appName => {
      return (`{"container_name": "${appName.trim().replace(/ /g, "_").toLowerCase()}"}`)
    })

    const payload = `{
    "operation": "container_${action}", 
    "parameters":[
      ${app_array}
     ]
    }`
    // Publish(`${user}/${deviceName}/device_operation`, payload)
    ws.send(JSON.stringify({ topic: `${user}/${deviceName}/device_operation`, payload: payload }))
    setMqttActions(true)
    dispatch({ app: [...checkedApps], _action: action })

    let message;
    if (action === 'start') { message = 'running' }
    if (action === 'restart') { message = 'restart' }
    if (action === 'stop') { message = 'exited' }
    !mqtt_busy && dispatchLoad({ load: message, state: true })
    setMqtt_busy(true)
  }
  // Publish individual device
  const publishIndividual = (appName, action) => {
    console.log(appName, action)
    // return

    const payload = `{
      "operation": "container_${action}", 
      "parameters":[
        {
          "container_name": "${appName}"
        }
      ] 
    }`
    // Publish(`${user}/${deviceName}/device_operation`, payload)
    ws.send(JSON.stringify({ topic: `${user}/${deviceName}/device_operation`, payload: payload }))
    setMqttActions(true)
    dispatch({ app: appName, _action: action })
    let message;
    if (action === 'start') { message = 'running' }
    if (action === 'restart') { message = 'restart' }
    if (action === 'stop') { message = 'exited' }
    !mqtt_busy && dispatchLoad({ load: `${appName} ${message}`, state: true })
    setMqtt_busy(true)
  }
  // reboot
  const reboot = () => {
    // Publish(`${user}/${deviceName}/device_operation`, '{"operation" : "reboot"}')
    ws.send(JSON.stringify({ topic: `${user}/${deviceName}/device_operation`, payload: '{"operation" : "reboot"}' }))

    !mqtt_busy && dispatchLoad({ load: 'reboot', state: true })
    setMqtt_busy(true)
  }
  //refresh
  const refresh = () => {
    // Publish(`${user}/${deviceName}/device_operation`, '{}')
    ws.send(JSON.stringify({ topic: `${user}/${deviceName}/device_operation`, payload: '{}' }))
    // Subscribe(`${user}/${deviceName}/app_details`)
    notify(`Apps refreshed !`)
  }
  //deploy
  const deploy = () => {

    const isConnected = suitesBoxRef?.current?.isSuiteConnected()
    console.log(isConnected)
    if (isConnected) {
      const payload = `{"operation" : "deploy", "key" : "${token}"}`
      // Publish(`${user}/${deviceName}/device_operation`, payload)
      ws.send(JSON.stringify({ topic: `${user}/${deviceName}/device_operation`, payload: payload }))
      !mqtt_busy && dispatchLoad({ load: 'deploy', state: true })
      setMqtt_busy(true)
    }


  }
  //purge
  const purge = () => {
    if (card?.runningSuite) {
      const payload = `{"operation" : "prune", "key" : "${token}"}`
      // Publish(`${user}/${deviceName}/device_operation`, payload)
      ws.send(JSON.stringify({ topic: `${user}/${deviceName}/device_operation`, payload: payload }))
      !mqtt_busy && dispatchLoad({ load: 'purge', state: true })
      setMqtt_busy(true)
    }

  }

  useEffect(() => {

    getDevice()//Fetching the card 
    user && ws?.send(JSON.stringify({ topic: `${user}/${deviceName}/device_operation`, payload: "{}" }))
    //  user && Publish(`${user}/${deviceName}/device_operation`, '{}')
    //  user && Subscribe(`${user}/${deviceName}/app_details`)

  }, [user, deviceName])

  useEffect(() => {
    // console.log(Payload.length!==0 && Payload.apps,Payload)
    if (wsPayload["app-details"] ? wsPayload["app-details"]["apps"]?.length > 0 : false) {
      // console.log(mqttActions);
      if (wsPayload["app-details"]["runtimestatus"] === 'success') {
        if (!mqttActions) {// Deployed successfully
          setApps(wsPayload["app-details"] ? wsPayload["app-details"]["apps"] : null)
          // console.log(Payload)
          notify('Deployed Successfully !')
          dispatchLoad({ load: 'deploy', state: false })
        }
        else {
          if (appAction) {//mqtt publish
            // console.log("=====================55555555555555555555====================", appAction?.app)

            if (Array?.isArray(appAction?.app)) {// combined mqtt publish

              let filtered_apps = [];
              appAction?.app?.forEach(App => {
                let item = wsPayload["app-details"]?.apps?.find(_app => _app?.name === App && _app?.state === appAction?._action)
                // let item = wsPayload["app-details"]?.apps?.find(_app => _app?.name === App && _app?.state === appAction?._action)
                item && filtered_apps?.unshift(item)
              })

              if (appAction?.app?.length === filtered_apps?.length) {
                notify(`Device's apps ${appAction?._action} successfully !`)
                dispatchLoad({ load: appAction?._action, state: false })
              }

            }
            else {//individual mqtt publish
              let item = wsPayload["app-details"]?.apps?.find(app => app?.name === appAction?.app && app?.state === appAction?._action)//individual mqtt publish
              item && notify(`${item?.name} ${item?.state} successfully!`)
              item && dispatchLoad({ load: `${item?.name} ${item?.state}`, state: false })

            }
          }
          setMqttActions(false)
        }
        setMqtt_busy(false)
      }
      if (wsPayload["app-details"]?.runtimestatus === 'busy') {
        notify('server busy!')
        setMqtt_busy(true)
      }

      if (wsPayload["app-details"]?.runtimestatus === 'restarting') {// Restarted Successfully
        notify('Restarting Device !')
        dispatchLoad({ load: 'reboot', state: false })
        setMqtt_busy(false)
      }

      setApps(wsPayload["app-details"]?.apps)
      //  console.log(Payload)
      getDevice();
    }
    else {
      getDevice();
      if (wsPayload["app-details"]?.apps === null && wsPayload["app-details"]?.runtimestatus === 'success') {// Pruned successfully

        notify('Purged Successfully !')
        dispatchLoad({ load: 'purge', state: false })
        setMqtt_busy(false)
      }
      if (wsPayload["app-details"]?.apps === null && wsPayload["app-details"]?.deploystatus === null) {
        //  console.log(307,mqtt_busy)
        mqtt_busy && ws?.send(JSON.stringify({ topic: `${user}/${deviceName}/device_operation`, payload: "{}" }))
        // mqtt_busy && Publish(`${user}/${deviceName}/device_operation`, '{}')
        //  mqtt_busy && Subscribe(`${user}/${deviceName}/app_details`)
        !downloading && setMqtt_busy(false)
      }

      if (wsPayload["app-details"]?.length !== 0 && card && wsPayload["app-details"]?.device_name === card.deviceName) {
        setCardPayloadWS(wsPayload["app-details"])
      }
    }

    if (wsPayload["app-details"]?.length !== 0 && wsPayload["app-details"]?.runtimestatus && wsPayload["app-details"]?.runtimestatus?.includes('error:')) {

      notify(wsPayload["app-details"]?.runtimestatus)
      console.log(wsPayload["app-details"]?.runtimestatus, wsPayload["app-details"]?.runtimestatus?.includes('deploy failure'))
      if (wsPayload["app-details"]?.runtimestatus?.includes('deploy failure')) {
        dispatchLoad({ load: 'deploy', state: false })
      }
      if (wsPayload["app-details"].runtimestatus.includes('purge failure')) {
        dispatchLoad({ load: 'purge', state: false })
      }


    }
  }, [wsPayload])

  useEffect(() => {
    if (wsPayload["heartbeat"]) {
      if (card && wsPayload["heartbeat"]["device_name"] === card.deviceName) {
        setCardPayloadWS(wsPayload["heartbeat"])
        console.log("wsPayload[heartbeat]", cardPayloadWS)
      }
    }
  }, [wsPayload])

  useEffect(() => {

    let percentages = wsPayload["app-details"]?.deploystatus?.map(app => app.percentage)
    let total_percentage = card?.pairedSuite && percentages?.reduce((total, num) => total + num) / card?.pairedSuite?.appList?.length
    total_percentage && setDownloading(`${total_percentage}`.slice(0, 4))
    total_percentage === 100 && setDownloading()
    // console.log(total_percentage)
  }, [wsPayload["app-details"]?.deploystatus, card])


  const isSuiteConnected = suitesBoxRef?.current?.isSuiteConnected

  const isDeviceAlive = DetailsCardRef?.current?.alive
  //  const x = suitesBoxRef?.current?.connectedSuite
  //   useEffect(()=>{
  //     console.log(x)
  //     getDevice()

  //   },[x])


  return (
    <>
      <div className='device-details'>
        <div className='container-fluid px-5 position-relative pb-4'>
          <div className="row breadcrumbs mt-3 d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
            <div className="col-md-6 col-xs-12">
              <nav aria-label="breadcrumb" className='d-flex'>
                <h3 className="title me-3">Device Details</h3>

                <Breadcrumbs page={[`Device`, `${deviceName}`]} />

              </nav>
            </div>
            <div className="col-md-6 col-xs-12">
              <div className='action-btn me-3 d-flex float-end'>

                <Button className='me-3 px-4' variant="outline-primary" ><BiFingerprint /> Identify</Button>

                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={card?.pairedSuite ? renderTooltip(`Deploy ${card?.pairedSuite?.suiteName}`) : renderTooltip(`No suite connected`)} trigger={['hover', 'focus']} >
                  <Button
                    className='me-3 px-4'
                    variant="outline-primary"
                    onClick={() => deploy()}
                    disabled={(loading?.load === 'deploy' && loading?.state) || mqtt_busy || !isSuiteConnected || !isDeviceAlive}>
                    {
                      loading?.load === 'deploy' && loading?.state ?

                        <span> <Spinner animation="border" variant="primary" size="sm" /> Deploying {`${downloading ? downloading : 0} %`}</span>
                        :
                        <>
                          <BiRocket /> Deploy
                        </>
                    }
                  </Button>
                </OverlayTrigger>

                <Button
                  className='me-3 px-4'
                  variant="outline-primary"
                  onClick={() => reboot()}
                  disabled={(loading?.load === 'reboot' && loading?.state) || mqtt_busy || !isSuiteConnected || !isDeviceAlive}>
                  {

                    loading?.load === 'reboot' && loading?.state ? <Spinner animation="border" variant="primary" size="sm" />
                      :
                      <><BiRevision /> Reboot </>
                  }
                </Button>

                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={card?.runningSuite ? renderTooltip(' Purge apps') : renderTooltip('No app running')} trigger={['hover', 'focus']}>
                  <Button
                    className='me-3 px-4'
                    variant="outline-danger"
                    onClick={() => purge()}
                    disabled={(loading?.load === 'purge' && loading?.state) || mqtt_busy || !isSuiteConnected || !isDeviceAlive}>
                    {
                      loading?.load === 'purge' && loading?.state ? <Spinner animation="border" variant="danger" size="sm" /> : <><BiGhost /> Purge</>
                    }
                  </Button>
                </OverlayTrigger>

              </div>
            </div>
          </div>

          <Container className='mt-3' fluid>
            <DeviceDetailsCard ref={DetailsCardRef} card={card} cardPayload={cardPayloadWS} />
          </Container>

          <Container className='mt-4' fluid>
            <Row xs={1} md={2} className="g-4">
              <Col md={6} xs={12}>
                <SuitesDeviceDetails
                  ref={suitesBoxRef}
                  card={card}
                  suiteConnected={suiteConnected?.data?.pairedSuite}
                  notify={notify}
                  mutate={mutate}
                />
              </Col>

              <Col md={6} xs={12}>
                <VpnConnection />
              </Col>
            </Row>
          </Container>


          <DeviceDetailsTab
            suiteref={suitesBoxRef}
            ref={DeviceDetailsTabRef}
            card={card}
            apps={apps}
            loading={loading}
            refresh={refresh}
            publishCombined={publishCombined}
            publishIndividual={publishIndividual}
            mqtt_busy={mqtt_busy}
            isSuiteLoading={isLoading}
            isDeviceAlive={isDeviceAlive}
          />
        </div>
      </div>
    </>
  )
}

export default DeviceDetails