import {useState,useRef,useContext, useEffect} from 'react'
import { useParams } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from "react-router-dom";
import { useMutation ,useQuery} from 'react-query';
import Breadcrumbs from '../../Component/miscellaneous/Breadcrumb';
import {MutateLoader} from '../../Component/loaders/';
import {Form, Card, Row, Col, Button} from 'react-bootstrap'; 
import {getSuite,uploadArtefacts,updateSuite,updateSuiteApps,createSuiteFinal} from '../../services/Api'
import {CreateSuiteStatus} from '../../Component/stepper';
import 'react-toastify/dist/ReactToastify.css';
import {getUpdatePayload} from '../../utility/Helper';
import {SuiteCreateTab}  from '../../Component/tabs';
import {CreateSuiteForm3} from '../../Component/Forms';
import Mqtt from '../../hooks/Mqtt'
import { notify } from '../../utility/Helper';

const CreateSuite3 = () => {

  const { suiteUid } = useParams()

  const [CurrentSuite, setCurrentSuite] = useState();// current suite created
  const [suiteStatus, setSuiteStatus] = useState();// current suite status

  const { token, logOut } = useAuth()
  // mqtt hook functions
  // const {Connect,Disconnect} = Mqtt()

  const CreateSuiteFormref = useRef(); //suiteForm ref
  const suiteTabref = useRef(); //suiteCreateTab ref

  const navigate = useNavigate();

  // modal state
  const [modalShow, setModalShow] = useState(false)
  //Mutate Loader state
  const [newLoading, setNewLoading] = useState(false)
  const [disableCreate, setDisableCreate] = useState(true)

  // uploadArtefacts api result
  const { mutate: UploadArtefacts } = useMutation({
    mutationFn: async () => {
      // console.log(suiteTabref.current.tab)
      const artefacts = await suiteTabref?.current?.composeSubmit()
      console.log(artefacts)
      return (uploadArtefacts(artefacts, token, logOut))
    }
    ,
    onSuccess: (data) => {
      console.log('FILES_UPLOADED')
      setSuiteStatus('FILES_UPLOADED')
      UpdateSuiteDocker()
    },
    onError: (error) => {

    }
  })

  // updateSuiteFromDocker api query
  const { mutate: UpdateSuiteDocker } = useMutation({
    mutationFn: async () => {
      const dockerCompose = await suiteTabref.current.dockerCompose();
      const  uiFlag = await suiteTabref.current.uiFlag;
      const update_payload = getUpdatePayload(dockerCompose, CurrentSuite?.suiteName ,uiFlag)

      return (updateSuite(update_payload, CurrentSuite?.suiteUid, token))
    }
    ,
    onSuccess: (data) => {
      console.log('APPS_ASSOCIATED',data?.data)
      setSuiteStatus('APPS_ASSOCIATED')
    },
    onError: (error) => {

    }
  })

   // updateSuiteFromApps api query
   const {mutate:UpdateSuiteApp} = useMutation({
    mutationFn:async(suiteUid)=>{

          const update_payload = await suiteTabref?.current?.apps()
          console.log(update_payload,suiteUid,token,'ko2.2')
    
          return(updateSuiteApps(update_payload ,CurrentSuite?.suiteUid,token))
    }
    ,
    onSuccess:(data)=>{
      console.log('APPS_ASSOCIATED',data?.data)
      setSuiteStatus('APPS_ASSOCIATED')
      CreateSuiteFinal()
    },
    onError:(error)=>{
      setNewLoading(false)
      notify(`error : code:${error?.response?.status} | Apps Association Failed`)
  }
  })

  // createFinal api query
  const { mutate: CreateSuiteFinal } = useMutation({
    mutationFn: async () => {
      console.log('ko2')
      setNewLoading(true)
      return (createSuiteFinal(suiteUid, token))
    }
    ,
    onSuccess: (data) => {
      console.log(data)
      setNewLoading(false)
      console.log('CREATED')
      setSuiteStatus('CREATED')
      navigate('/suite')
    },
    onError: (error) => {
      setNewLoading(false)
      notify(`error : ${error?.response?.status} Creation Failure`)
    }
  })

  // Query works when a suite exists in intermediate stage(not fully built but DRAFT stage)
  const { isLoading, isError } = useQuery({
    queryKey: ['suite', suiteUid],
    queryFn: () => getSuite(suiteUid, token),
    enabled: !!token && !!suiteUid,
    onSuccess: (data) => {
      setSuiteStatus(data?.data?.status)
      setCurrentSuite(data?.data)
    },
    refetchIntervalInBackground:false,
    refetchOnMount:false,
    refetchOnReconnect:false,
    refetchOnWindowFocus:false
  })

  async function handleCreate(){

    if(suiteTabref?.current?.tab==='upload'){
      CreateSuiteFinal()
    }
    else{
      setNewLoading(true)
      UpdateSuiteApp()
    }

    
  }

  // useEffect(()=>{

  //   Connect()
  //   return () => {
  //     Disconnect()
  //   }

  // },[])

  return (
    <>
  
      <div className='create_suite container-fluid navbar-light px-5 position-relative pb-5'>
        <div className="row breadcrumbs mt-3 d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
          <div className="col-md-12">
            <nav aria-label="breadcrumb" className='d-flex'>
              <h3 className="title me-3">Create Suite</h3>
              <Breadcrumbs page={['suite', 'create']} />
            </nav>
          </div>
        </div>

        <Card className='p-4 shadow-sm border-light suite_details'>
          {/* {suiteStatus && <CreateSuiteStatus suiteStatus={suiteStatus}/>} */}
          <CreateSuiteStatus suiteStatus={suiteStatus} />
          <Row>

            <Col md={6} lg={8} xl={9}>
              <div className='suite_details_form'>

                <CreateSuiteForm3
                  suite={CurrentSuite}
                  ref={CreateSuiteFormref}
                />
              </div>
            </Col>
          </Row>

          <Form.Label className='mt-4'>Build method</Form.Label>

          <SuiteCreateTab
            CurrentSuite={CurrentSuite}
            suiteStatus={suiteStatus}
            ref={suiteTabref}
            show={modalShow}
            setModalShow={setModalShow}
            UploadArtefacts={UploadArtefacts}
            setDisableCreate={setDisableCreate}
            handleSubmit={null}
          />


          <div className='add-variable mt-4 pb-4 '>
            <Button
              variant='primary theme-btn px-5'
              // disabled={!(suiteStatus==='APPS_ASSOCIATED') || }
              onClick={() => {
                handleCreate()
              }}
            >
              Create
            </Button>

          </div>

        </Card>

      </div>
      <MutateLoader
        show={newLoading ? true : false}
        entity={'suite'}
        action={'Creating'}
      />
    </>
  )
}

export default CreateSuite3