import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import AddDeviceForm from '../Forms/AddDeviceForm'


function AddDeviceModal({show,handleClose,add,submitted,newUser,setNewUser}) {
//   const [show, setShow] = useState(false);
//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className='px-4' closeVariant='white' closeButton>
          <Modal.Title>Add Device</Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-4 py-4'>
          {/* {
            newUser ? 
            <AddNewDeviceForm add={add} submitted={submitted} />
            :
            <AddDeviceForm add={add} submitted={submitted} newUser={newUser} setNewUser={setNewUser}/>
          } */}
          <AddDeviceForm add={add} submitted={submitted} newUser={newUser} setNewUser={setNewUser}/>
          </Modal.Body>
      </Modal> 
    </>
  );
}

export default AddDeviceModal;