
import {useState,useContext} from 'react'
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { ReactSVG } from 'react-svg';
import { logo_light } from '../../Component/Images/Images';
import { useQuery } from 'react-query';
import { signUpUser } from '../../services/Api';

const SignUp = () => {
  
  const {user,token,login_user,logOut} = useAuth()
  const [signUp, setSignUp] = useState(false)
  const [signupError,setError] = useState()
  const navigate = useNavigate()

  const { data, isError, isSuccess, isLoading ,error } = useQuery({
    queryKey: ['signup', signUp],
    queryFn: () => signUpUser(formik.values),
    enabled: signUp,
    onError: (error) => {
      console.error(error)
      if(error?.response?.status === 409){
        setError('User has already signed up !')
      }
      console.log('Failed Signing UP')
    },
    onSuccess: () => navigate('/signin')
      // console.log(data)
      // const {userName} = data?.data
      // const login_values = {
      //   'username' : userName ,
      //   'password' : formik?.values?.password
      // }
      // login_user(login_values)
    ,
    refetchIntervalInBackground: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false
  })

  const initialValues = {
    userName: '',
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword:'',
    roles: []
  }

  const validationSchema = yup.object({
    userName: yup.string()
      .required('Provide a valid username!')
    ,
    firstName: yup.string()
      .required('Provide a valid firstName!')
    ,
    lastName: yup.string()
      .required('Provide a valid Lastname!')
    ,
    email: yup.string()
      .required('Email is Required !')
      .email('must be a valid email')
    
    ,
    password: yup.string().required('Password Required!')
    ,
    confirmPassword: yup.string().required('Please confirm your password !')
    ,
    roles: yup.array().of(yup.string())
  });

  const onSubmit = async (values) => {
    if(formik.values.password === formik.values.confirmPassword){
       formik.setFieldValue('roles',['ADMIN'])
       formik.unregisterField('confirmPassword')
      //  console.log(values)
       setSignUp(true)
    }
    else{
      formik.setFieldError('confirmPassword' , 'Password not matching !')
      console.log(values)
    }
   
  }



  const formik = useFormik({ initialValues, onSubmit, validationSchema });

  return (
    <>
    {/* {console.log(formik)} */}
       <div className="LandingPage position-absolute top-0 start-0 vh-100 vw-100 bg-light z-3">
      {/* <div className='logo_for_login'>
      <Link className='logo mb-4' to='/'><ReactSVG src={logo_light} alt="" /></Link>
      </div> */}
      <div className="wrapper account">
        <div className='sign_in'>
          <Link className='logo mb-4 d-block text-center' to='/'><ReactSVG src={logo_light} alt="" /></Link>
          <div className='sign_in_form bg-white mx-auto shadow p-5'>
            <span className='fs-2'>
              Get started
            </span>
            <p>Welcome to The OpenFactory. Apply in less than a minute.</p>
            
            {
              isError &&
              <p className='text-danger'>
                {signupError}
              </p>
            }
            
            <Form
              onSubmit={formik.handleSubmit}
              className='mt-4'
            >
              <Form.Group className="mb-3 d-flex">
                <Form.Control
                  name="userName"
                  required
                  type="text"
                  className='border-0 border-bottom'
                  placeholder="Username"
                  value={formik.values.userName}
                  onChange={formik.handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-3 d-flex">
                <Form.Control
                  name="firstName"
                  required
                  className='me-2 border-0 border-bottom'
                  type="text"
                  placeholder="First Name"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                />
                <Form.Control
                  name="lastName"
                  required
                  type="text"
                  className='border-0 border-bottom'
                  placeholder="Last Name"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="emailId">
                <Form.Control
                  name="email"
                  required
                  type="email"
                  className='border-0 border-bottom'
                  placeholder="Email ID"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="password">
                <Form.Control
                  required
                  name='password'
                  type="password"
                  className='border-0 border-bottom'
                  placeholder="Password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-4" controlId="confirmPassword">
                <Form.Control
                  name='confirmPassword'
                  required
                  type="password"
                  className='border-0 border-bottom'
                  placeholder="Confirm Password" 
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  />

                  {formik.touched.confirmPassword && formik.errors.confirmPassword}
                  {/* <Form.Control.Feedback type='invalid'>
                  {formik.errors.confirmPassword}
                  </Form.Control.Feedback> */}
                  
              </Form.Group>

              <div className="d-grid gap-2">
                {
                  isLoading ?
                    <Button variant="primary theme-btn" disabled>Signing Up ...</Button>
                    :
                    isSuccess?
                      <Button type='submit' variant="primary theme-btn">
                        Succefully Signed Up ...
                      </Button>
                    :
                      <Button type='submit' variant="primary theme-btn">
                        Next
                      </Button>
                }

              </div>
              <p className='mt-2'>By continuing, you agree to The OpenFactory's <Link>Terms of Service</Link> and <Link>Privacy Policy</Link>.</p>
              <div className='mt-4'>
                <span>Have an account? </span><Button variant="link p-0" onClick={()=>navigate('/signin')}>Click here</Button>
              </div>

            </Form>
          </div>
        </div>
      </div>

    </div>

    </>
   
 
  )
}

export default SignUp