import {useState,useEffect} from 'react'

function CreateSuiteStatus({suiteStatus}) {

  const [status , setStatus] = useState([])


  useEffect(()=>{

    if( suiteStatus?.includes('DRAFT')){
      setStatus(['DRAFT'])
    }

    if( suiteStatus?.includes('FILES_UPLOADED')){
      setStatus(['DRAFT',suiteStatus])
    }
    if( suiteStatus?.includes("APPS_ASSOCIATED")){
      setStatus(['DRAFT','FILES_UPLOADED',suiteStatus])
    }

    if( suiteStatus?.includes('CREATED')){
      setStatus(['DRAFT','FILES_UPLOADED',"APPS_ASSOCIATED",suiteStatus])
    }

    // if(suiteStatus && !status.includes(suiteStatus) ){
    //   setStatus([...status,suiteStatus])
    // }

    
  },[suiteStatus])




  return (
    <div className='suite_statusbar'>      
      <div className='container'>

        <ul className="progressbar">
         {
          // console.log(suiteStatus,suiteStatus && status.includes('DRAFT') , status)
          // console.log(status , status.includes('FILES_UPLOADED') && status.includes('APPS_ASSOCIATED') )  
         }
          <li className={status.length>0 && status.includes("DRAFT") ? "active" :"inprogress" }>
            <span className='step'>Step 1</span>
            <span className='step-title'>Draft</span>
            <span className='step-status'>{status.length>0 ? status.includes("DRAFT") ? "Completed" : "Pending": "In Progress"}</span>
          </li>

          <li className={status.length>0 ? (status.includes('FILES_UPLOADED') && status.includes('APPS_ASSOCIATED')) ?  "active" :"inprogress" : " "}>
            <span className='step'>Step 2</span>
            <span className='step-title'>Apps associated</span>
            <span className='step-status'>{status.length>0 ? (status.includes('FILES_UPLOADED') && status.includes('APPS_ASSOCIATED')) ? "Completed" : "Pending" : "In Progress"   }</span>
          </li>

          <li className={status.length>0 ?(status.includes('CREATED')) ?  "active" :"inprogress" : " "}>
            <span className='step'>Step 3</span>
            <span className='step-title'>Suite created</span>
            <span className='step-status'>{status.length>0 ? status.includes("CREATED") ? "Completed" :"Pending" : "In Progress" }</span>
          </li>

        </ul>
      </div>  
    </div>
  )
}

export default CreateSuiteStatus