import {useState,useEffect,useContext} from 'react';
import { useAuth } from '../../context/AuthContext';
import {useParams} from 'react-router-dom';
import { useQuery } from 'react-query';
import Breadcrumbs from '../../Component/miscellaneous/Breadcrumb';
import { Card, ListGroup, Button, Container, Row, Col, Tab, Tabs, Table, Badge, Stack} from 'react-bootstrap';
import { ReactSVG } from 'react-svg';
import { pencil, product_image, user_profile, donut_chart } from '../../Component/Images/Images';
import {  BiCalendarAlt, BiUser, BiCloudDownload, BiUserPlus, BiRocket, BiRevision, BiBrush} from "react-icons/bi";
import {getApp} from '../../services/Api'
import SlickSlider from '../../Component/sliders/SlickSlider';
import TableApps from '../../Component/tables/TableApps';

const AppDetails = () => {

    const {user,token,logOut} = useAuth()

    const {appUid} = useParams();
    //current page app
    const [app,setApp] = useState();
    //set current selected app app
    const [currappApp,setCurrappApp]= useState();
    //checks if any app has been updated
    const [updatedapp,setUpdatedapp] = useState(0);
  
    // edit app modal state and functions
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false)

    const fetchApp = (appUid && token) ? true : false

    console.log(fetchApp)
  
    const {data,isError,isLoading} = useQuery({
        queryKey:['app',appUid],
        queryFn:()=>getApp(token,appUid,logOut),
        enabled: !!appUid && !!token,
        onSuccess:(data)=>{
            console.log(data)
            setApp(data?.data)},
        isError:(error)=>console.log(error)
    })
    
  
  
    return (
      <>
       <div className='container-fluid navbar-light px-5 position-relative pb-4 suite_details'>
          <div className="row breadcrumbs mt-3 d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">        
              <div className="col-md-12">              
                <nav aria-label="breadcrumb"  className='d-flex'>
                  <h3 className="title me-3">Appstore</h3>
                  <Breadcrumbs page={['Appstore',`${app? app?.appName:''}`]}/>
                </nav>
              </div>              
          </div>
          <Container className='mt-3' fluid='true'>
            <div className='p-4 shadow-sm border-light card'>
              <Row xs={1} md={2} className="g-4 m-2">
                  <Col md={3} xs={12}>
                    <img 
                      style={{objectFit:'fit' , height:'100%', width:'100%'}}
                      // className='object-fit-cover border rounded w-100 h-100' 
                      src={app?.imageData}
                      alt=" "
                     />
                  </Col>
                  <Col md={9} xs={12}>
                    <div className='product-info'>
                      <div className='title d-flex'>
                        <h3 className='me-3'>{app?.appName} : {app?.version}</h3>
                        <div className='virifyied-icon'>
                          <ReactSVG src={pencil} /> 
                        </div>                                          
                      </div>
                      {['sm'].map((breakpoint) => (
                        <ListGroup key={breakpoint} horizontal={breakpoint} className="my-0">
                          {/* <BiUser /> */}
                          <ListGroup.Item className='py-0 ps-0 border-0 small'>Created by : {app?.user?.username}</ListGroup.Item>
                        </ListGroup>
                      ))}
                      {['sm'].map((breakpoint) => (
                        <ListGroup key={breakpoint} horizontal={breakpoint} className="mt-2">
                          <ListGroup.Item className='py-0 ps-0 border-0 border-end small '>
                            <span>OS/ARCH</span>
                            <p className='mb-0'>{app && app.architecture}</p>                          
                          </ListGroup.Item>                                             
                          <ListGroup.Item className='py-0 border-0 small border-end'>                         
                            <span>LAST UPDATE</span>
                            <p className='mb-0'><BiCalendarAlt/> 20 May, 2023</p>
                          </ListGroup.Item>
                          <ListGroup.Item className='py-0 border-0 small border-end'>
                            <span>DOWNLOADS</span>
                            <p className='mb-0'><BiCloudDownload /> {app?.downloads}</p>
                          </ListGroup.Item>
                          <ListGroup.Item className='py-0 border-0 small'>                          
                            <span>VULNERABILTIES</span>
                            <Stack className='mt-1' direction="horizontal" gap={2}>                            
                              <Badge bg="danger">1C</Badge>
                              <Badge bg="warning" text="dark">
                                4H
                              </Badge>
                              <Badge bg="info">15M</Badge>                            
                              <Badge bg="dark">6L</Badge>
                            </Stack>
                          </ListGroup.Item>                        
                        </ListGroup>
                      ))}
                      <div className='description mt-3'>
                        <h4>Description :</h4>
                        <p>{app?.description}</p>
                      </div>
                    </div>  
                  </Col>
                 {/*  <Col md={3} xs={12}>
                    <img src={donut_chart} className='img-fluid float-end' alt=''/>
                      <PieChart/> 
                  </Col>
                  */}
              </Row>
              <SlickSlider/>            
              <div className='app_table mt-5 px-3 '>
                  <h3>Apps :</h3>
                  <TableApps app={app}/>
              </div>
              
              
              <Container fluid='true'>
              <div className='mt-5 device connected_tab' fluid='true'>
              <h3>Device :</h3>
              <Row>
                <Col>
                  <Card className='p-5 shadow-sm border-light'>
                  <Tabs                    
                      variant="underline"                    
                      id="justify-tab-example"
                      className="mb-3"                    
                    >
                    <Tab eventKey="connected" title="Connected">
                      {
                         app?.pairedDevices?.length === 0 ?
                          "No Device Connected"
                          :
                          <Table striped>
                        <thead>
                          <tr>          
                            <th>Device Name</th>
                            <th>Location</th>
                            <th>CPU</th>
                            <th>TEMP</th>
                            <th>MEMORY</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            app?.pairedDevices?.map(device =>{
                              return(
                                <tr key={device?.deviceId}>
                                  <td>{device?.deviceName}</td>
                                  <td>v_1.0.0</td>
                                  <td>35%</td>
                                  <td>45%</td>
                                  <td>65%</td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </Table>
                      }
                      
  
                    </Tab>
                    <Tab eventKey="running" title="Running">
                      <div className='action-btn me-3 d-flex float-end mb-3'>
                        <Button size='sm' className='me-2 px-3' variant="outline-primary"><BiUserPlus /> Identify</Button>
                        <Button size='sm' className='me-2 px-3' variant="outline-primary"><BiRocket /> Deploy</Button>
                        <Button size='sm' className='me-2 px-3' variant="outline-primary"><BiRevision /> Reboot</Button>
                        <Button size='sm' className='me-2 px-3' variant="outline-danger"><BiBrush /> Purge</Button>
                      </div>
                            {
                              app?.hostDevices?.length === 0 ?
                                "No Device Runnning"
                                :
                                <Table striped>
                                  <thead>
                                    <tr>
                                      <th>Device Name</th>
                                      <th>Location</th>
                                      <th>CPU</th>
                                      <th>TEMP</th>
                                      <th>MEMORY</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      app?.hostDevices?.map((device,index) => {
                                        return (
                                          <tr key={index}>
                                            <td>{device?.deviceName}</td>
                                            <td>v_1.0.0</td>
                                            <td>35%</td>
                                            <td>45%</td>
                                            <td>65%</td>
                                          </tr>
                                        )
                                      })
                                    }
                                  </tbody>
                                </Table>
                            }     
                    </Tab>                  
                  </Tabs>
                  </Card>              
                </Col>
              </Row>
              </div> 
              </Container>
              
                
            </div>
            
          </Container>
          
       </div>
      </>
  )
}

export default AppDetails