import { useState, useEffect, useRef, useContext } from 'react'
import { useAuth } from '../../context/AuthContext';
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from 'react-query';
import Breadcrumbs from '../../Component/miscellaneous/Breadcrumb';
import MutateLoader from '../../Component/loaders/MutateLoader';
import CreateSuiteUpload2 from '../../Component/Forms/CreateSuiteUpload2';
import { Form, Card, Row, Col, Tabs, Tab, Button, } from 'react-bootstrap';
import { BiCloudUpload, BiStore } from "react-icons/bi";
import { getSuite, getSuites, addSuite, uploadArtefacts, updateSuite, createSuiteFinal, getApps } from '../../services/Api'
import CreateSuiteForm2 from '../../Component/Forms/CreateSuiteForm2';
import yaml from 'js-yaml';
import UploadSuiteModal from '../../Component/modals/UploadSuiteModal'
import CreateSuiteStatus from '../../Component/stepper/CreateSuiteStatus';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Mqtt from '../../hooks/Mqtt';
import { getUpdatePayload } from '../../utility/Helper';
import * as formik from 'formik';
import * as yup from 'yup';
import AppSelectCard from '../../Component/cards/AppSelectCard';
// import { SuiteCreateTab } from '../../Component/tabs/SuiteCreateTab';

// const host = process.env.REACT_APP_MQTT_SERVER;
// const options = {
//   clean: true,// Clean session
//   keepalive: 10,
//   qos: 1,
//   clientId: 'mqttjs_5c32dc7e' + parseInt(Math.random() * 100),
// }

function CreateSuite() {

  const { user, token, logOut, wsPayload, ws } = useAuth()
  const navigate = useNavigate()
  const { suiteUid } = useParams();// current suite name exteraction from url
  const [suite, setSuite] = useState(); // current suite with all parameters 
  const [currentSuiteUid, setCurrentSuiteUid] = useState(suiteUid) // current suite id

  const [selected, setSelected] = useState([])
  const [suiteExists, setSuiteExists] = useState(false);
  const [newLoading, setNewLoading] = useState(false)
  const [Apps, setApps] = useState([])
  const [suiteStatus, setSuiteStatus] = useState();
  const [modalShow, setModalShow] = useState(false)
  const show = () => setModalShow(true)
  const [dockerComposes, setDockerComposes] = useState({});

  const { data: apps, isError, isLoading } = useQuery({
    queryKey: ['apps'],
    queryFn: () => getApps(token, logOut),
    enabled: !!token,
    onSuccess: (data) => {
      const createdApps = data?.data?.filter(app => app.status === 'CREATED')
      setApps(createdApps)
    }
  })

  //search
  const { Formik } = formik;
  const [searched_term, set_searched_term] = useState('')
  const [searched_items, set_searched_items] = useState([])
  const [searchActive, setSearchActive] = useState(false)

  const schema = yup.object({
    search: yup.string(),
    scope: yup.string()
  });

  const submit = val => {
    console.log(val.search)
    setSearchActive(true)
    let results = Apps?.filter(app => app?.appName.includes(val.search))
    set_searched_items(results)
  };

  useEffect(() => {

    if (searched_term === '') { setSearchActive(false) }
    else {
      setSearchActive(true)
      let results = Apps?.filter(app => app?.appName?.includes(searched_term))
      set_searched_items(results)
    }

  }, [searched_term, Apps])


  // const { Connect, Disconnect, Publish, Subscribe, Payload } = Mqtt();

  const notify = (text) => {
    if (text.includes('error')) {
      return (toast.error(text, { position: "top-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", }))
    }
    else {
      return (toast.success(text, { position: "top-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", }))
    }
  }

  const reader = new FileReader();

  const [formData, setFormData] = useState({
    CreateSuiteForm: { values: null, validated: false },
    CreateSuiteUpload: { values: null, validated: false },
  });

  const CreateSuiteFormref = useRef();
  const CreateSuiteUploadref = useRef();

  function handleCreateSuite(data) {
    setFormData({ ...formData, CreateSuiteForm: data });
  }

  function handleCreateSuiteUpload(data) {
    setFormData({ ...formData, CreateSuiteUpload: data });
  }

  // Add Suite
  const add_suite = async () => {

    console.log(currentSuiteUid, 95, 'added')
    const SuiteForm = await CreateSuiteFormref.current.Values();
    const validatedSuiteForm = CreateSuiteFormref.current.Submit() && CreateSuiteFormref.current.validated();
    const dockerCompose = CreateSuiteUploadref.current.dockerCompose();

    if (SuiteForm && validatedSuiteForm) {
      const response = await addSuite(SuiteForm, user, token, logOut)
      console.log(response)

      if (response.status === 200) {
        console.log(response?.data?.suiteUid, 108)
        setCurrentSuiteUid(response?.data?.suiteUid)

        response.data.status === 'DRAFT' && setSuiteStatus('DRAFT')
        // get_suites
        setSuite(response?.data) // set newly created suite

        dockerCompose && reader.readAsText(dockerCompose);

        reader.onload = async () => {
          const text = reader.result.toString()
          const jsObject = yaml.load(text);
          setDockerComposes(jsObject)
        };

        // setModalShow(true)
        show()
        setSuiteExists(false)
      }
      else {
        if (response.response.status === 409) {
          console.log(response)
          notify('error : suite name already exists !')
          setSuiteExists(true)
        }
      }
    }
  }

  //Update Suite
  const update_suite = () => {

    let update_payload = suite && getUpdatePayload(dockerComposes, suite?.suiteName)

    console.log(update_payload, suite)

    update_payload && updateSuite(update_payload, currentSuiteUid, token, logOut)
      .then(res => {
        console.log(res.data)
        console.log(suiteStatus, 'updated')
        res.data.status === 'APPS_ASSOCIATED' && setSuiteStatus('APPS_ASSOCIATED')
      }
      )
      .catch(err => {
        console.error(err)
        setSuiteStatus('DRAFT')
      })

  }

  // Upload Artefacts
  const upload_artefacts = async () => {

    // console.log(suiteStatus,147,'uploaded')

    const update = await CreateSuiteUploadref.current.update(currentSuiteUid)
    const Artefacts = await CreateSuiteUploadref.current.Values();
    const validatedArtefacts = CreateSuiteUploadref.current.Submit() && CreateSuiteUploadref.current.validated();

    // console.log(currentSuiteUid,Artefacts,validatedArtefacts)

    if (suiteStatus === 'DRAFT' && Artefacts && validatedArtefacts && update) {
      try {
        const response = await uploadArtefacts(currentSuiteUid, Artefacts, user, token)
        // console.log(suiteStatus,156)
        // console.log(response.data,157)
        response.data.status === 'FILES_UPLOADED' && setSuiteStatus('FILES_UPLOADED')
        response.data.status === 'FILES_UPLOADED' && update_suite()
      }
      catch (err) {
        console.error(err)
        setSuiteStatus('DRAFT')
      }
    }

  }

  //Create suite final
  const create_suite_final = () => {
    setNewLoading(true)
    suiteStatus === 'APPS_ASSOCIATED' && createSuiteFinal(currentSuiteUid, user, token)
      .then(res => {
        console.log(res.data)
        console.log(suiteStatus)
        setSuiteStatus(res.data.status === 'CREATED' && 'CREATED')
        setNewLoading(false)
        notify('Suite Created')
        setTimeout(() => { navigate("/suite") }, 3000)
      })
      .catch(err => {
        console.error(err)
        notify('Suite cannot be created')
      })

  }

  //get specific suite
  const get_suite = async () => {
    token && getSuite(suiteUid, token)
      .then(res => {
        console.log(res.data)
        setSuite(res.data)
        setSuiteStatus(res.data.status)
        setSuiteExists(true)
        console.log(res.data.status)
      })
      .catch(err => console.log(err))
  }


  const handleStatus = () => {
    console.log(suiteStatus)
    if (suiteStatus && (suiteStatus === 'DRAFT' || suiteStatus === 'FILES_UPLOADED')) {
      const dockerCompose = CreateSuiteUploadref.current.dockerCompose();
      dockerCompose && reader.readAsText(dockerCompose);
      reader.onload = async () => {
        const text = reader.result.toString()
        const jsObject = yaml.load(text);
        setDockerComposes(jsObject)
        setModalShow(true)
      }
    }
    if (suiteStatus && (suiteStatus === 'APPS_ASSOCIATED')) {

    }
    else {
      add_suite()
    }
  }


  //  useEffect(()=>{
  //   // Reconnect()
  //    Disconnect()
  //    let out = setTimeout(() => {
  //       Connect(host,options)
  //    }, 1500);
  //    return () => clearTimeout(out)

  //  },[])


  useEffect(() => {

    console.log(suiteUid)

    //current page suite if doesn't exist
    suiteUid && get_suite()
    // alert(suiteName)
  }, [user, token])


  function handleSelected(App) {

    if (selected.includes(App)) {
      let selectedArray = selected?.filter(app => app !== App)
      setSelected(selectedArray)
    }
    else {
      setSelected([App, ...selected])
    }

  }

  useEffect(() => {

    console.log(selected)
  }, [selected])


  return (
    <>

      <ToastContainer />
      <div className='create_suite container-fluid navbar-light px-5 position-relative pb-5'>
        <div className="row breadcrumbs mt-3 d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
          <div className="col-md-12">
            <nav aria-label="breadcrumb" className='d-flex'>
              <h3 className="title me-3">Create Suite</h3>
              <Breadcrumbs page={['suite', 'create']} />
            </nav>
          </div>
        </div>

        <Card className='p-4 shadow-sm border-light suite_details'>
          {/* {suiteStatus && <CreateSuiteStatus suiteStatus={suiteStatus}/>} */}
          <CreateSuiteStatus suiteStatus={suiteStatus} />
          <Row>

            <Col md={6} lg={8} xl={9}>
              <div className='suite_details_form'>

                <CreateSuiteForm2 onChange={handleCreateSuite} refId={CreateSuiteFormref} suite={suite} suiteExists={suiteExists} />
              </div>
            </Col>
          </Row>

          <Form.Label className='mt-4'>Build method</Form.Label>
          <Tabs
            defaultActiveKey="upload"
            transition={false}
            id="noanim-tab-example"
          >
            <Tab eventKey="upload"
              title={
                <span>
                  <BiCloudUpload /> {"Upload"}
                </span>
              }
              className='custom_tabs pb-4 border border-top-0 p-4 pb-5'>
              <div className='upload'>

                <CreateSuiteUpload2 onChange={handleCreateSuiteUpload} refId={CreateSuiteUploadref} />

                <UploadSuiteModal
                  show={currentSuiteUid && modalShow}
                  onClose={() => setModalShow(false)}
                  data={dockerComposes}
                  user={user}
                  suite={currentSuiteUid}
                  upload_artefacts={upload_artefacts}
                  suiteStatus={suiteStatus}
                  // Publish={Publish}
                  // Subscribe={Subscribe}
                  // Payload={Payload}
                />

                <Button variant='primary theme-btn  px-5 '
                  disabled={suiteStatus && (suiteStatus === 'APPS_ASSOCIATED' || suiteStatus === 'CREATED')}
                  onClick={() => handleStatus()}>
                  Next
                </Button>

              </div>

            </Tab>
            <Tab eventKey="fromapp"
              title={
                <span>
                  <BiStore /> {"From App"}
                </span>
              }
              className='custom_tabs pb-4 border border-top-0 p-4 pb-5'>
              <Row>
                <Col md={9}>
                  <div className='options text-end mt-2'>
                    <Form>
                      {['checkbox'].map((type) => (
                        <div key={`inline-${type}`} >
                          <Form.Check
                            inline
                            label="ORG"
                            name="org"
                            type={type}
                            id={`inline-${type}-org`}
                          />
                          <Form.Check
                            inline
                            label="Private"
                            name="private"
                            type={type}
                            id={`inline-${type}-private`}
                          />
                          <Form.Check
                            inline
                            label="Public"
                            type={type}
                            id={`inline-${type}-public`}
                          />
                        </div>
                      ))}
                    </Form>


                  </div>
                </Col>
                <Col md={3}>
                  <div className='search'>

                    <Formik onSubmit={submit} validationSchema={schema} initialValues={{ search: '' }}>
                      {({ handleSubmit, handleChange, setFieldValue, values }) =>
                        <Form noValidate className="d-flex" onSubmit={handleSubmit} >
                          <Form.Control
                            type="search"
                            placeholder="Search..."
                            className="me-2 rounded-pill"
                            aria-label="Search"
                            required
                            name='search'
                            onChange={e => {
                              setFieldValue('search', e.target.value)
                              set_searched_term(e.target.value)
                            }}
                            value={values.search}
                          />
                          <Button variant="outline-primary" type='submit'>Search</Button>
                        </Form>

                      }

                    </Formik>
                  </div>
                </Col>
              </Row>
              <div className='app_list mt-4 '>

                {
                  searchActive ?
                    Apps && searched_items.length !== 0 ?
                      searched_items.map(App => {
                        return (
                          <Button key={App.appUid}
                            variant='focus-ring focus-ring-info text-decoration-none p-0 border-0 me-3 btn btn-link '
                            onClick={() => handleSelected(App)}

                          >
                            <AppSelectCard app={App} searchActive={searchActive} searched_term={searched_term} />
                          </Button>
                        )
                      })
                      :
                      <div className='row text-center '>
                        <p className="fs-2"> No match found</p>
                      </div>
                    :
                    Apps?.map(App => {
                      return (
                        <Button key={App.appUid}
                          variant={
                            selected?.includes(App) ?
                              'focus-ring focus-ring-info text-decoration-none p-0 border-2 me-3 btn btn-link '
                              :
                              'focus-ring focus-ring-info text-decoration-none p-0 border-0 me-3 btn btn-link '
                          }
                          className='bg-primary'
                          onClick={() => handleSelected(App)}
                        >
                          <AppSelectCard app={App} />
                        </Button>
                      )
                    })
                }
              </div>
            </Tab>
          </Tabs>

          {/* <SuiteCreateTab
          handleCreateSuiteUpload={handleCreateSuiteUpload}
          CreateSuiteUploadref={CreateSuiteUploadref}
          currentSuiteUid={currentSuiteUid}
          dockerComposes={dockerComposes}
          upload_artefacts={upload_artefacts}
          suiteStatus={suiteStatus}
          handleStatus={handleStatus}
        /> */}


          <div className='add-variable mt-4 pb-4 '>
            {/* <span className='fs-5'>Environment variables</span>
          <p>These values will be used as substitutions in the stack file</p>
          <Button variant="outline-primary mb-3">+ Add an environment variable</Button>
          <Row md={3}>
            <Col className='mt-2'>
              <InputGroup className="mb-3">
                <InputGroup.Text className='text-white px-4'  id="basic-addon1">Name</InputGroup.Text>
                <Form.Control
                  placeholder="e.g. FOD"
                  aria-label="Name"
                  aria-describedby="basic-addon1"
                />
            </InputGroup>
            
            </Col>
            <Col className='mt-2'>
              <InputGroup className="mb-3">
              <InputGroup.Text className='text-white px-4' id="basic-addon1">Value</InputGroup.Text>
              <Form.Control
                placeholder="e.g. bar"
                aria-label="Value"
                aria-describedby="basic-addon1"
              />
            </InputGroup>            
            </Col>
            <Col className='mt-2'>
              <Button variant="danger"><BiTrash className='fs-5'/></Button>              
            </Col>
          </Row>
          <Row md={3}>
            <Col>
              <InputGroup className="mb-3">
                <InputGroup.Text className='bg-primary text-white px-4'  id="basic-addon1">Name</InputGroup.Text>
                <Form.Control
                  placeholder="e.g. FOD"
                  aria-label="Name"
                  aria-describedby="basic-addon1"
                />
            </InputGroup>
            
            </Col>
            <Col>
              <InputGroup className="mb-3">
              <InputGroup.Text className='bg-primary text-white px-4' id="basic-addon1">Value</InputGroup.Text>
              <Form.Control
                placeholder="e.g. bar"
                aria-label="Value"
                aria-describedby="basic-addon1"
              />
            </InputGroup>            
            </Col>
            <Col>
              <Button variant="danger"><BiTrash className='fs-5'/></Button>              
            </Col>            
          </Row>   */}
            <Button variant={`primary theme-btn px-5`}
              onClick={() => create_suite_final()}
              disabled={suiteStatus !== 'APPS_ASSOCIATED'}
            >
              Create
            </Button>

          </div>

        </Card>

      </div>
      <MutateLoader
        show={newLoading ? true : false}
        entity={'suite'}
        action={'Creating'}
      />
    </>

  )
}

export default CreateSuite