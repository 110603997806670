import React, { Component, useState } from 'react';
import {Carousel} from 'react-bootstrap';
import {bg1} from '../Images/Images';

function ControlledCarousel() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect} className='z-0'>
      <Carousel.Item>            
        <img
          className="d-block w-100 "
          src={bg1}
          alt="First slide"
        />
        <Carousel.Caption>
          <h3>"Assembly Line" is offline for more than 24 hours</h3>
          <p>The device was last online on the 10th of August 2024 at 8pm</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={bg1}
          alt="Second slide"
        />

        <Carousel.Caption>
          <h3>"raspberryPi" high memory utilization warning!</h3>
          <p>&nbsp;</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={bg1}
          alt="Third slide"
        />

        <Carousel.Caption>
          <h3>"raspberryPi" high temperature warning</h3>
          <p>
            &nbsp;
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default ControlledCarousel;