import { getAllDeviceCards } from "../../services/Api";
import { useQuery } from "react-query";
import { useAuth } from "../../context/AuthContext";
import Breadcrumbs from "../../Component/miscellaneous/Breadcrumb";
import OlaMap from "../../Component/map/OlaMap";
import { coming_soon_new } from "../../Component/Images/Images";

function Dashboard() {
  const { token } = useAuth();

  const { data: devices } = useQuery({
    queryKey: ["markers", token],
    queryFn: () => getAllDeviceCards(token),
    enabled: !!token,
  });

  return (
    <>
      <div className="suites apps container-fluid navbar-light px-5 position-relative pb-5">
        <div className="row breadcrumbs mt-3 d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
          <div className="col-md-12">
            <nav aria-label="breadcrumb" className="d-flex">
              <h3 className="title me-3">Cockpit</h3>
              <Breadcrumbs page={["Cockpit"]} />
            </nav>
          </div>
        </div>
        {/* <Map markersdata={devices} /> */}
        {/* <OlaMap markersdata={devices} /> */}
        <img src={coming_soon_new} alt="" />
      </div>
    </>
  );
}

export default Dashboard;
