
import React, { Component } from 'react'
import {Link,useNavigate,redirect,NavLink } from 'react-router-dom'
import {Breadcrumb, BreadcrumbItem} from 'react-bootstrap';


function CustomBreadcrumb({page}) {
  const navigate = useNavigate()


  return (
    <Breadcrumb>
      <Breadcrumb.Item  className='text-primary'  href={`/`}>Home</Breadcrumb.Item> 

      {/* {page.map( link => 
        <Breadcrumb.Item>
          <NavLink to={`/${link}`}>
             {link}
          </NavLink>
        </Breadcrumb.Item>
      )} */}


      {
        page.map(link => 
        <Breadcrumb.Item 
          key={link} 
          className={link === page[page.length-1]?'text-decoration-underline':'text-primary'} 
          href={`/${link}`}
        >
          
           {link}
           
          
         
        </Breadcrumb.Item>)
      }     


      {/* <Breadcrumb.Item active>{page}</Breadcrumb.Item> */}
    </Breadcrumb>
  );
}

export default CustomBreadcrumb;