import  {useState,useEffect,Suspense,useContext} from 'react';
import {Card, ListGroup, Button, Container, Row, Col, Badge, ProgressBar} from 'react-bootstrap';
import {  BiCalendarAlt, BiTime, BiMap} from "react-icons/bi";
import { ReactSVG } from 'react-svg';
import { verified, cpu, temp, memory} from '../Images/Images';
import { getLocation } from '../../services/Api';

import {useQuery} from 'react-query'

const DemoDeviceDetailsCard = ({memory,temperature,cpu}) => {

    const [alive,setAlive] = useState(true)
 
    const [loading, setLoading] = useState(true)
  
    setTimeout(()=>setLoading(false),1000)
  
    const [_cpu, setCpu] = useState(0)
    const [temp, setTemp] = useState(0)
    const [mem, setMem] = useState(0)
  
  
    const d = new Date();


    const[time,setTime] = useState(d.toLocaleTimeString('en-US', {hour12: false,}));
  
    useEffect(()=>{
  
      let interval = setInterval(()=>{
  
       const d = new Date();
       setTime(d.toLocaleTimeString('en-US', {hour12: false,}))
        
      },1000)
  
      return ()=> clearInterval(interval)
  
    })
  
    useEffect(()=>{
  
      
        
  
        let out = setTimeout(()=>{
        _cpu >= cpu ? setCpu(cpu) : setCpu(m=>m+1)
        temp >= temperature ? setTemp(temperature) : setTemp(m=>m+1)
        mem  >= memory ? setMem(memory) : setMem(m=>m+1)
        },30)
  
        return ()=> clearTimeout(out)
      
  
    },[_cpu,cpu,mem,memory,temp,temperature])
  
  

  const demoImage = `https://tofbucket.s3.ap-south-1.amazonaws.com/theopenfactory/helper/eSIM-768x432-c.jpeg`

  return (
    <Card className='p-5 shadow-sm border-light'>
    <Row>
      <Col md={5} lg={3}>
        <div className='device-img '>
          <img className='img-device' src={demoImage} alt=""/>                 
        </div>
      </Col>
      <Col md={7} lg={9}>
        <div className='product-info'>
          <div className='title d-flex'><h3 className='me-3'>Demo Device</h3>
          <div className='virifyied-icon'>
            <ReactSVG src={verified} /> 
          </div> 
          <Suspense fallback={'Loading'}>
            <Badge className='online' pill>
              online
            </Badge>
          </Suspense>
            
          </div>                   
          {['sm'].map((breakpoint) => (
            <ListGroup key={breakpoint} horizontal={breakpoint} className="my-0">
              <ListGroup.Item className='py-0 ps-0 border-0 border-end small '><BiCalendarAlt/>{d.toUTCString()?.slice(0,16)}</ListGroup.Item>
              <ListGroup.Item className='py-0 border-0 border-end small'><BiTime/> {time}</ListGroup.Item>                      
              <ListGroup.Item className='py-0 border-0 small'><BiMap/>
              Kolkata, India
              </ListGroup.Item>
            </ListGroup>
          ))}
          <div className='description mt-2'>
            <p>Demo device to get hands on with the platform without registering</p>
          </div>
          <Container className='mt-3' fluid>
            <Row>
              <Col className='ps-0 information' md={6}>
              <ListGroup className='me-3 border-end pe-5' variant="flush">
                <h5 className='mb-3'>Information</h5>
                  <ListGroup.Item className='p-0 d-flex align-items-center justify-content-start border-0'>
                    <strong>Product ID</strong>      
                    <span>1016e1ce4f664763bf30134f1fde33c2</span> 
                  </ListGroup.Item>
                  <ListGroup.Item className='p-0 my-0 d-flex align-items-center justify-content-start border-0'>
                    <strong>Type</strong>
                    <span>Ubuntu 22.04.3 LTS</span>
                  </ListGroup.Item>
                  <ListGroup.Item className='p-0 d-flex align-items-center justify-content-start border-0'>
                    <strong>Last Online</strong>
                    <span>--</span>
                  </ListGroup.Item>
                  <ListGroup.Item className='p-0 d-flex align-items-center justify-content-start border-0'>
                    <strong>MAC ID</strong>
                    <span>00:0a:95:9d:67:16</span>
                  </ListGroup.Item>
                  <ListGroup.Item className='p-0 d-flex align-items-center justify-content-start border-0'>
                    <strong>Timezone</strong>
                    <span>UTC</span>
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              <Col md={6}>
              <div className='progressbar'>
                <h5 className='mb-4'>Device</h5>
                <div className='device-item d-flex flex-wrap align-items-center justify-content-between'>
                  <span className='title bg-dark text-white d-flex'><ReactSVG src={cpu} /> CPU</span>
                  <ProgressBar variant={cpu>90?'danger':cpu>50?'warning':'success'} className='float-start' now={cpu}></ProgressBar>
                  <span className='percent me-4'>{cpu}%</span>
                </div>
                <div className='device-item d-flex flex-wrap align-items-center justify-content-between'>
                  <span className='title bg-dark text-white d-flex'><ReactSVG src={temp} /> Temp</span>
                  <ProgressBar variant={temperature>90?'danger':temperature>50?'warning':'success'} className='float-start' now={temperature}></ProgressBar>
                  <span className='percent me-4'>{temperature}%</span>
                </div>
                <div className='device-item d-flex flex-wrap align-items-center justify-content-between'>
                  <span className='title bg-dark text-white d-flex'><ReactSVG src={memory} /> Memory</span>
                  <ProgressBar variant={memory>90?'danger':memory>50?'warning':'success'} className='float-start' now={memory}></ProgressBar>
                  <span className='percent me-4'>{memory}%</span>
                </div>                       
              </div>
              </Col>
            </Row>

          </Container>
          
          
        </div>  
      </Col>
    </Row>
  </Card>  
  )
}

export default DemoDeviceDetailsCard