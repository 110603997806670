import { useState } from 'react'
import { Card, Col, ListGroup, Row, Image } from 'react-bootstrap';
import { device1, verified } from '../Images/Images';
import { ReactSVG } from 'react-svg';

const SuiteSelectCard = ({ suite, searchActive, searched_term }) => {

  let left_parted_name = suite?.suiteName?.slice(0, suite?.suiteName?.indexOf(searched_term))
  let right_parted_name = suite?.suiteName?.slice(suite?.suiteName?.indexOf(searched_term) + searched_term?.length)

  return (
    <>
      {/* <button className=" focus-ring focus-ring-info text-decoration-none p-0 border rounded-2"> */}

      <Card key={suite?.suiteId} className="border-0 focus-ring focus-ring-info shadow-sm border"  >
        <div className="ribbon">
          {/* <div className="wrap"> */}
            <span className={
              suite?.scope === "PUBLIC" ?
                "tag public"
                :
                suite?.scope === "PRIVATE" ?
                  "tag private"
                  :
                  "tag org"
            }>
              {suite?.scope === "ORGANIZATION" ? 'ORG' : suite?.scope}
            </span>
          {/* </div> */}
        </div>
        <Card.Body className='p-0'>
          <div className='img-box text-center position-relative'>
            {/* <Card.Img variant="top"  src={suite && `${process.env.REACT_APP_IMAGE}${suite?.imageUrl}`} /> */}
            <Image width={100} roundedCircle src={suite && `${suite?.image}`} />
            <ReactSVG className='position-absolute bottom-0 end-0 verified' src={verified} />
          </div>
          {
            searchActive ?
              <>
                <Card.Title className='fs-6'>
                  <span className='text-center '>{left_parted_name}</span>
                  <span className='text-center text-primary'>{searched_term}</span>
                  <span className='text-center '>{right_parted_name}</span>
                </Card.Title>
              </>
              :
              <Card.Title className='text-center fs-6'>{suite?.suiteName}</Card.Title>
          }
          {/* <Card.Title className='text-center fs-6'>{suite?.suiteName}</Card.Title> */}

          <Card.Text className='fs-6n info fw-light d-flex justify-content-center justify-content-between px-3 mb-2'>
            <span className='me-2 border-right'>{suite?.user?.username}</span>
            <span className='me-2'>1,234</span>
            <span>{suite?.version}</span>
          </Card.Text>
        </Card.Body>

      </Card>


      {/* </button> */}
    </>
  )
}

export default SuiteSelectCard