import { createContext, useEffect, useContext, useState } from 'react'
import { Routes, Route, useNavigate, Outlet } from 'react-router-dom';
import { loginUser, getUser } from '../services/Api';
import Cookies from 'universal-cookie';
import jwt from 'jwt-decode'

const _cookies = new Cookies(null, { path: '/' });
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [userID, setUserID] = useState(() => _cookies.get('user_uid'));
  const [token, setToken] = useState(() => _cookies.get('jwt_authorization'));
  const [ws, setWs] = useState(null);
  const [wsPayload, setWSPayload] = useState([]);
  var wsStatus = false;
  var wsClient


  const login_user = async (user_details) => {
    const response = await loginUser(user_details)
    console.log(response)

    if (response?.status === 200) {
      const decoded = jwt(response.data.token)
      // console.log(0, user, token)
      login(response.data.token)
      setUser(decoded.sub)
      setToken(response.data.token)
      const user_id = await getUser(response.data.token, decoded.sub)
      setUserID(user_id.data.userUid)
      _cookies.set("user_uid", user_id.data.userUid, {})
      // get_userid(response.data.token, decoded.sub)
      // console.log("############################",user_id.data.userUid)
      navigate('device')
    }
    return response
  }

  const get_userid = async (token, username) => {
    const user_id = await getUser(token, username)
    setUserID(user_id.data.userUid) 
  }

  // User Login
  const login = (jwt_token) => {
    _cookies.set("jwt_authorization", jwt_token, {})
  }
  // user Logout
  const logOut = () => {

    _cookies.remove('jwt_authorization')
    _cookies.remove('user_uid')
    navigate("/")
    setToken()
    wsClient?.close()
    console.log('logged out')
  }

  useEffect(() => {

    // console.log("hello.........================",token,_cookies)
    const _token = _cookies.get('jwt_authorization');
    const _userid = _cookies.get('user_uid');
    // console.log(_token)

    if (_token) {
      setToken(_token);
      setUserID(_userid);
      var _user = ""
      try {
        _user = jwt(_token).sub;
      } catch(e) {
        console.log(e)
      }
      setUser(_user);
      if (!wsStatus) {
        wsClient = new WebSocket(`${process.env.REACT_APP_WS_SERVER}?username=` + _user);
        wsStatus = true
        console.log("connecting ws")
        wsClient.onopen = () => {
          setWs(wsClient);
        };
        wsClient.onclose = () => console.log('ws closed');
      }
    }
    else {
      setToken();
      setUser();
    }

  }, [token])

  useEffect(() => {
    if (ws) {
      ws.onmessage = (evt) => {
        const payload = JSON.parse(evt.data);
        if (!payload["type"]) {
          setWSPayload(payload)
          console.log("payload", payload)
        }
      };
    }
  }, [ws]);


  return <AuthContext.Provider value={{ user, token, login_user, logOut, wsPayload, ws, userID }} >{children}</AuthContext.Provider>
}


export const useAuth = () => {
  return useContext(AuthContext);
}