import { useContext, useState, useEffect } from 'react'
import { useAuth } from '../../context/AuthContext';
import Button from 'react-bootstrap/Button';
import { Form, Row, Col } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { FieldArray, Field, Formik } from 'formik';
import * as yup from 'yup';
import { BiCamera, BiCloudDownload, BiCloudUpload } from "react-icons/bi";
import PreviewImage from '../../utility/Preview';
import { ReactSVG } from 'react-svg';
import { trash } from '../Images/Images';
// import Mqtt from '../../hooks/Mqtt';


export const CreateAppForm = ({ _createApp, setShow }) => {

    const { user, wsPayload, ws } = useAuth()
    const [Payload, setPayload] = useState();
    const [inProgress, setProgress] = useState();
    const [isLoading, setLoading] = useState();
    // const { Publish, Subscribe, Connect, Disconnect } = Mqtt()
    const [percentage, setPercentage] = useState();
    const [isVerified, setVierified] = useState(false)

    const onVerify = (image, appName) => {


        const publish_payload = `{"user":"${user}", "image":"${image}", "suite":"${appName.replace(/ /g, "").toLowerCase()}"}`

        // Publish(`create_suite_cmd`, publish_payload)
        ws.send(JSON.stringify({ topic: `create_suite_cmd`, payload: publish_payload }))
        setLoading(true)

        // Subscribe(`${user}/create_suite_status/S`)
    }
    const initialValues = {
        appName: '',
        version: '',
        description: '',
        imageFile: null,
        scope: 'PRIVATE',
        image: '',
        imageVersion: '',
        architecture: '',
        uiFlag: false,
        ports: [
            // {
            //     intPort: '',
            //     extPort: ''
            // }
        ],
        environments: [
            // {
            //     variable: '',
            //     value: ''
            // }
        ]
    }

    const onSubmit = async (values, errors, isInvalid) => {
        // console.log("999999999999999999999999", values, errors, isInvalid)
        let image = values.image
        values.image = `${user}` + `_` + values.appName.replace(/ /g, "").toLowerCase() + `_` + image.split(":")[0].replace(/\//g, '-')
        values.imageVersion = image.split(":")[1]
        const object = new FormData()
        Object.keys(values).forEach(key => object.append(key, values[key]));
        const method = 'POST'
        _createApp({ object, method, values })
        setShow(false)

    }

    const validationSchema = yup.object({
        appName: yup.string().min(3).max(20).required('Provide a valid Name!'),
        // .test("appName","Enter a different appname", ()=>!Exists?true : false ),
        version: yup.string().required('Enter your app version !')
        ,
        description: yup.string().max(500).required('Please provide a valid description!')
        ,
        architecture: yup.string().required('Please enter a valid architecture').oneOf(['linux/amd64', "linux/arm64/v8"])
        ,
        image: yup.string().required('Provide an image to verify')
        ,
        imageFile: yup.mixed().required('Add an Image!')
            .test("File Type", "Only image(.jpeg ,.jpg and .png) supported!", value => value && ['image/png', 'image/jpeg', 'image/jpg'].includes(value.type))
            .test("File Size", "File size too big! Try to upload a file less than 5 Mb .", value => value && value.size < 1024 * 1024 * 1024 * 5)
        ,
        scope: yup.string().required().oneOf(['PRIVATE', 'PUBLIC', 'ORGANIZATION'])
        ,
        ports: yup.array().of(yup.object())
        ,
        environments: yup.array().of(yup.object())
    })

    // useEffect(() => {
    //     // Disconnect()
    //     Connect()
    //     return () => {
    //         Disconnect()
    //     }
    // }, [])

    useEffect(() => {
        // let item = appStatus?.find(item => item?.app === Payload?.app );

        // let _payload = Payload?.length !== 0 && Payload.app && Payload.action && Payload.percentage ;

        // if(!item && _payload ){
        //     console.log(appStatus,'add')
        //     dispatch({obj:Payload , type :'ADD'})
        //     setLoading(loading.map(item => item !== Payload.app ? item : null))
        //     // setLoading(false)
        // }
        // if(item && _payload ){
        //   console.log(appStatus,'update')
        //   dispatch({obj:Payload , type :'UPDATE'})
        // }

        // console.log(wsPayload)
        let Payload = wsPayload?.["create-suite-status"]
        Payload = Payload ? Payload : null
        if (Payload?.["action"]) {
            setLoading(false)
            setProgress(true)
            const per = Payload?.["percentage"]
            setPercentage(per)
            const success = Payload?.["percentage"] === 100 && (Payload?.["action"] === 'success' || Payload?.["action"] === 'pushing')
            setVierified(success)
        }
        // console.log(Payload)



        console.log(wsPayload, Payload)
        setPayload(Payload)

    }, [wsPayload?.["create-suite-status"]])


    return (

        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            {({ values, setFieldValue, touched, errors, handleChange, submitCount, handleSubmit, handleReset, isValid }) => (
                <Form  >
                    <Row>
                        <Col md={6} lg={4} xl={3}>
                            <div className='custom_upload_app position-relative mt-3 mb-4'>
                                <Form.Control
                                    type="file"
                                    className='position-absolute bottom-0 end-0 translate-middle'
                                    name='imageFile'
                                    onChange={e => {
                                        const file = e.target.files[0];
                                        setFieldValue('imageFile', file)
                                    }
                                    }
                                // isInvalid={! &&(touched.imageFile && errors.imageFile)}
                                // isValid={! && touched.imageFile}
                                />

                                {
                                    <>
                                        <PreviewImage file={values.imageFile} />
                                        <span><BiCamera /></span>
                                    </>
                                }

                                {/* {selectedImage && previewImage && <img src={previewImage} alt="Preview" width={200} className='rounded-pill' />} */}

                                {/* {console.log(!errors.appName && submitCount >=1 && Exists,115)} */}
                            </div>
                            <p className="text-center text-danger">{(touched.imageFile && errors.imageFile)}</p>
                        </Col>
                        <Col md={6} lg={8} xl={9}>
                            {/* <Form.Label>Enter  Details</Form.Label> */}
                            <div className="d-flex flex-row justify-content-start mb-2">
                                <h5 className=''>Enter App Details</h5>
                            </div>
                            <Form.Group className="mb-3" >
                                <div className='d-flex'>
                                    <Form.Control
                                        type="text"
                                        className='me-2'
                                        name='appName'
                                        placeholder="App Name"
                                        // disabled={ || (!errors.appName && submitCount >=1 && !Exists) }
                                        value={values.appName}
                                        onChange={handleChange}
                                        isInvalid={(touched.appName && errors.appName)}
                                        isValid={touched.appName}
                                    />
                                    <Form.Control.Feedback type='invalid' >
                                        {errors.appName}
                                    </Form.Control.Feedback>

                                    <Form.Control
                                        type="text"
                                        className='me-2'
                                        name='version'
                                        placeholder="Version"
                                        // disabled={(!errors.version && submitCount >= 1)}
                                        value={values.version}
                                        onChange={handleChange}
                                        isInvalid={(touched.version && errors.version)}
                                        isValid={touched.version}
                                    />
                                    <Form.Control.Feedback type='invalid' >
                                        {errors.version}
                                    </Form.Control.Feedback>
                                    <div className="custom-select-container">
                                        <Form.Select
                                            name='architecture'
                                            // disabled={(!errors.architecture && submitCount >= 1)}
                                            // value={values.architecture}
                                            onChange={handleChange}
                                            className="custom-select"
                                            isInvalid={(touched.architecture && errors.architecture)}
                                            isValid={touched.architecture}
                                            defaultValue="a"
                                        >
                                            <option disabled value="a" >--Architecture--</option>
                                            <option value="linux/amd64" >linux/amd64</option>
                                            <option value="linux/arm64/v8" >linux/arm64/v8</option>
                                        </Form.Select>
                                    </div>

                                </div>
                            </Form.Group>


                            <Form.Group className="mb-3" >

                                <Form.Control
                                    as="textarea"
                                    name="description"
                                    placeholder='Description'
                                    // disabled={(!errors.description && submitCount >= 1)}
                                    rows={3}
                                    value={values.description}
                                    onChange={handleChange}
                                    isInvalid={(touched.description && errors.description)}
                                    isValid={touched.description}
                                />
                                <Form.Control.Feedback type='invalid' >
                                    {errors.description}
                                </Form.Control.Feedback>

                            </Form.Group>

                            <Form.Group>

                                {/* <Form.Label>Choose scope</Form.Label> */}
                                {/* <div className="d-flex flex-row justify-content-start mb-2">
                                        <h5 className=''>Choose scope</h5>
                                    </div> */}
                                <div className="mb-3">
                                    {['PRIVATE', 'PUBLIC', 'ORGANIZATION'].map((scope) => (
                                        <Form.Check
                                            inline
                                            label={scope}
                                            name='scope'
                                            value={scope}
                                            type='radio'
                                            key={scope}
                                            disabled={user !== 'theopenfactory' && (scope === 'PUBLIC' || scope === 'ORGANIZATION')}
                                            // checked={scope === 'PRIVATE'}
                                            defaultChecked={scope === 'PRIVATE'}
                                            defaultValue={scope === 'PRIVATE'}
                                            // disabled={(!errors.scope && submitCount >= 1)}
                                            onChange={handleChange}
                                            isInvalid={(touched.scope && errors.scope)}
                                            isValid={touched.scope}
                                        // checked={.scope === scope}
                                        />
                                    ))}

                                </div>
                            </Form.Group>

                            <Form.Group className='mb-2'>

                                <Form.Check
                                    label={'UI'}
                                    name='uiFlag'
                                    value={true}
                                    type='checkbox'
                                    // disabled={(!errors.scope && submitCount >= 1)}
                                    onChange={handleChange}
                                    isInvalid={(touched.uiFlag && errors.uiFlag)}
                                    isValid={touched.uiFlag}
                                // checked={.scope === scope}
                                />
                                <Form.Control.Feedback type='invalid' >
                                    {errors.image}
                                </Form.Control.Feedback>

                            </Form.Group>

                            <div className='d-flex'>
                                <Form.Control
                                    className='me-2'
                                    type='text'
                                    name="image"
                                    placeholder='image_name:version'
                                    // disabled={(!errors.image && submitCount >= 1)}
                                    size='sm'
                                    // required
                                    value={values.image}
                                    onChange={handleChange}
                                    isInvalid={(touched.image && errors.image)}
                                    isValid={touched.image}
                                />
                                <Form.Control.Feedback type='invalid' >
                                    {errors.image}
                                </Form.Control.Feedback>
                                <Button
                                    style={{ width: "6vw" }}
                                    // disabled={values.image}
                                    variant="outline-primary"
                                    onClick={() => onVerify(values?.image, values?.appName)}
                                    disabled={values.image ? isVerified ? true : false : true}
                                >
                                    {
                                        isLoading ? <Spinner animation="border" variant="primary" size="sm" /> :
                                            inProgress ?
                                                isVerified ?
                                                    `verified`
                                                    :
                                                    Payload?.["action"] === 'pushing' ?
                                                        <div className='d-flex align-items-center'>
                                                            <BiCloudUpload />&nbsp;
                                                            {percentage + '%'}
                                                        </div>
                                                        :
                                                        <div className='d-flex align-items-center'>
                                                            <BiCloudDownload />&nbsp;
                                                            {percentage + '%'}
                                                        </div>
                                                :
                                                'verify'

                                    }
                                </Button>
                            </div>
                        </Col>

                        <FieldArray name="ports">
                            {({ insert, remove, push }) => (
                                <div className="mb-4">

                                    <div className="d-flex flex-row justify-content-start mb-2">
                                        <h5 className=''>Ports</h5>
                                    </div>
                                    {
                                        values.ports.map((port, index) => {

                                            return (
                                                <div className="d-flex justify-content-start " key={index}>
                                                    <div className="w-50 me-3 mb-2">

                                                        <Field
                                                            name={`ports.${index}.intPort`}
                                                            className="form-control"
                                                            placeholder="Host Port"
                                                            type="number"

                                                        />
                                                    </div>

                                                    <div className="w-50">
                                                        <Field
                                                            name={`ports.${index}.extPort`}
                                                            className="form-control"
                                                            placeholder="Container Port"
                                                            type="number"
                                                        />
                                                    </div>
                                                    <div className="">
                                                        <button
                                                            type="link"
                                                            className="btn"
                                                            onClick={() => remove(index)}
                                                        >
                                                            <ReactSVG src={trash} />
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        )
                                    }
                                    <div className="d-flex flex-row justify-content-start mt-2">
                                        <Button
                                            variant="outline-primary"
                                            onClick={() => push({ "intPort": '', "extPort": '' })}
                                        >+ Add Ports</Button>
                                    </div>
                                </div>
                            )}
                        </FieldArray>

                        <FieldArray name="environments">
                            {({ insert, remove, push }) => (
                                <div>
                                    <div className="d-flex justify-content-start mb-2">
                                        <h5 className='mb-0 me-2'>Environment Variables</h5>
                                    </div>

                                    {
                                        values.environments.map((env, index) => (
                                            <div className="d-flex justify-content-between " key={index}>
                                                <div className="w-50 me-3 mb-2">
                                                    <Field
                                                        name={`environments.${index}.variable`}
                                                        className="form-control"
                                                        placeholder="VARIABLE"
                                                        type="text"
                                                    />
                                                </div>
                                                <div className="w-50">

                                                    <Field
                                                        name={`environments.${index}.value`}
                                                        className="form-control"
                                                        placeholder="VALUE"
                                                        type="text"
                                                    />
                                                </div>
                                                <div className="">
                                                    <button
                                                        type="button "
                                                        className="btn"
                                                        onClick={() => remove(index)}
                                                    >
                                                        <ReactSVG src={trash} />
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    <div className="d-flex justify-content-start mt-2">
                                        <Button
                                            variant="outline-primary"
                                            onClick={() => push({ "variable": '', "value": '' })}
                                        >+ Add Environments</Button>
                                    </div>
                                </div>
                            )}
                        </FieldArray>

                    </Row>

                    <div className="d-flex flex-row justify-content-end ">
                        <button
                            disabled={isVerified ? false : true}
                            type="button"
                            className="btn btn-primary mt-4 mx-1"
                            onClick={() => {
                                handleSubmit()
                            }}
                        >
                            Save Changes
                        </button>

                        <button
                            type="button"
                            className="btn btn-secondary mt-4 mx-1"
                            onClick={handleReset}
                        >
                            Reset values
                        </button>
                    </div>
                </Form>
            )
            }
        </Formik >
    )

}

