import React, { Component } from 'react';
import {Button, Modal, Form} from 'react-bootstrap';
class RangeSliderWithInputs extends Component {
  state = {
    minValue: 1,
    maxValue: 255,
  };

  handleMinChange = (e) => {
    this.setState({ minValue: parseInt(e.target.value, 10) });
  };

  handleMaxChange = (e) => {
    this.setState({ maxValue: parseInt(e.target.value, 10) });
  };

  render() {
    const { minValue, maxValue } = this.state;

    return (
      <div className='rangeslider'>
        <label className="form-label">IP Range</label>
        <div className='d-flex align-items-center justify-content-between'>      
        
        <div className='me-4'>     
          <Form.Control
            className='text-center'
            type="number"
            value={minValue}
            onChange={this.handleMinChange}
            id="iprangeleft"
            aria-describedby="iprangeleft"
          />
          <Form.Text id="iprangeleft" muted>
          <small>Your IP must be greater than 1</small>
          </Form.Text>
        </div>
        <div>
        <hr/>
        </div>
        <div className='d-'>
        <Form.Control
          type="number"
          className='text-center'
          value={maxValue}
          onChange={this.handleMaxChange}
          id="iprangeright"
          aria-describedby="iprangeright"
        />
        <Form.Text id="iprangeright" muted>
          <small>Your IP must be less than 255.</small>
        </Form.Text>        
        
        </div>
        </div>
        
        
        

      </div>
    );
  }
}

export default RangeSliderWithInputs;