import { useState, useEffect, useContext,useRef,useImperativeHandle,forwardRef} from 'react'
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import CreateSuiteUpload2 from '../../Component/Forms/CreateSuiteUpload2';
import { Form, Card, Row, Col, Tabs, Tab, Button, } from 'react-bootstrap';
import { BiCloudUpload, BiStore } from "react-icons/bi";
import UploadSuiteModal from '../../Component/modals/UploadSuiteModal'
import AppSelectCard from '../../Component/cards/AppSelectCard';
import * as formik from 'formik';
import * as yup from 'yup';
import yaml from 'js-yaml';
import { useAuth } from '../../context/AuthContext';
import { getApps } from '../../services/Api';
// import Mqtt from '../../hooks/Mqtt';
import CreateSuiteUpload3 from '../Forms/createSuiteUpload3';
import { getUpdateAppPayload } from '../../utility/Helper';

 const SuiteCreateTab = (props, ref) => {

    const {pathname} = useLocation();
    // createSuiteUpload3 ref
    const CreateSuiteUploadref = useRef();
    // UploadSuiteModal ref
    const UploadSuiteModalRef = useRef();
    
    // destructuring props
    const { show,setModalShow,CurrentSuite,AddSuite,suiteStatus,suiteUid,handleSubmit,setDisableCreate,UploadArtefacts } = props
    // console.log(CurrentSuite)
   
    // accessing token from AuthContext
    const {token,logOut,user} = useAuth()

    // Accessing methods and state from Mqtt hook
    // const {Publish,Subscribe,Payload} = Mqtt();

    // file reader
    const reader = new FileReader();
    // Apps state
    const [Apps,setApps] = useState()
    // Selected Apps
    const [selected,setSelected] = useState([])
    // dockerCompose state  
    const [dockerCompose,setDockerCompose] = useState()
    // updateFromApps payload
    const [fromAppsPayload,setFromAppsPayload] = useState()
    // active tab state
    const [tab,setTab] = useState("upload")
    // disable on one 
    const [disable,setDisable] = useState(false)



    // querying API for getting apps
    const {isError,isLoading} = useQuery({
        queryKey:['apps',token],
        queryFn:()=>getApps(token,logOut),
        enabled:!!token,
        onSuccess:(data)=>{
            setApps(data?.data)
        }
    })
  

    useImperativeHandle(ref,()=>{
        return{
            composeUpdate:(suite)=>CreateSuiteUploadref.current.update(suite),
            composeSubmit: CreateSuiteUploadref.current.Submit,
            composeDetails:()=>CreateSuiteUploadref.current.Values(),
            handleArtefacts:UploadSuiteModalRef.current.handleSubmit,
            dockerCompose:()=>dockerCompose,
            apps:()=>fromAppsPayload,
            tab,
            getDockerCompose,
            uiFlag:UploadSuiteModalRef?.current?.getUiFlag,
            selected
        }
    })

    async function getDockerCompose(){
        const files = await CreateSuiteUploadref.current.Values();
        console.log(files?.dockerCompose)
        // converting yaml file to readable js object
        files?.dockerCompose && reader.readAsText(files?.dockerCompose);
        reader.onload = async() =>{
        const text = reader.result.toString()
        const jsObject = yaml.load(text);
        setDockerCompose(jsObject)
        }
        files?.dockerCompose && setModalShow(true)
    }

    function handleSelected (App){
   
        if(selected.includes(App)){
          let selectedArray = selected?.filter(app => app !== App)
           setSelected(selectedArray)
        }
        else{
          setSelected([App,...selected])
        }
    }

   async function handleNext(){ 
    //  Call addSuite
    setDisable(true)
    const valid = await CreateSuiteUploadref?.current?.validated()
    
    console.log(valid)
    valid && !CurrentSuite && AddSuite()
        // console.log(pathname)
    valid && CurrentSuite  && getDockerCompose()
    }

  

    useEffect(()=>{
        if(selected?.length!==0){
            const res =  getUpdateAppPayload(selected,'csbx')
            setFromAppsPayload(res)
         }
    },[selected])


    useEffect(()=>{

        if((tab==='upload' && suiteStatus) || (tab==='fromApp' && selected?.length!==0)){
            console.log('not disabled')
            setDisableCreate(false)
        }
        else{
            console.log(' disabled')
            setDisableCreate(true)
        }
        console.log(tab,suiteStatus)

    },[tab,selected,suiteStatus,setDisableCreate])

   

    //search
    const { Formik } = formik;
    const [searched_term, set_searched_term] = useState('')
    const [searched_items, set_searched_items] = useState([])
    const [searchActive, setSearchActive] = useState(false)

    const schema = yup.object({
        search: yup.string(),
        scope: yup.string()
    });

    const submit = val => {
        console.log(val.search)
        setSearchActive(true)
        let results = Apps?.filter(app => app?.appName.includes(val.search))
        set_searched_items(results)
    };

    useEffect(() => {

        if (searched_term === '') { setSearchActive(false) }
        else {
            setSearchActive(true)
            let results = Apps?.filter(app => app?.appName?.includes(searched_term))
            set_searched_items(results)
        }

    }, [searched_term, Apps])


    

    return (
        <Tabs
            activeKey={tab}
            onSelect={(k) => setTab(k)}
            transition={false}
            id="noanim-tab-example"
        >
            <Tab eventKey='upload'
                title={
                    <span>
                        <BiCloudUpload /> {"Upload"}
                    </span>
                }
                className='custom_tabs pb-4 border border-top-0 p-4 pb-5'>
                <div className='upload'>

                    <CreateSuiteUpload3  ref={CreateSuiteUploadref} suiteUid={suiteUid}/>

                    <UploadSuiteModal
                        ref={UploadSuiteModalRef}
                        show={show}
                        onClose={() => setModalShow(false)}
                        data={dockerCompose}
                        user={user}
                        suite={CurrentSuite?.suiteUid}
                        suiteName={CurrentSuite?.suiteName}
                        handleSubmit={handleSubmit}
                        UploadArtefacts={UploadArtefacts}
                        setDisable={setDisable}
                        // Subscribe={Subscribe}
                        // Payload={Payload}
                        CreateSuiteUploadref={CreateSuiteUploadref}
                        
                    />

                    <Button variant='primary theme-btn  px-5 '
                        disabled={(suiteStatus === 'APPS_ASSOCIATED' || suiteStatus === 'CREATED')}
                        onClick={
                            async() =>{
                            const updatedCompose = await CreateSuiteUploadref.current.update(suiteUid)
                            updatedCompose && handleNext()
                        }}>
                        Next
                    </Button>

                </div>

            </Tab>
            <Tab eventKey='fromApp'
                title={
                    <span>
                        <BiStore /> {"From App"}
                    </span>
                }
                disabled={disable}
                className='custom_tabs pb-4 border border-top-0 p-4 pb-5'>
                <Row>
                    <Col md={9}>
                        <div className='options text-end mt-2'>
                            <Form>
                                {['checkbox'].map((type) => (
                                    <div key={`inline-${type}`} >
                                        <Form.Check
                                            inline
                                            label="ORG"
                                            name="org"
                                            type={type}
                                            id={`inline-${type}-org`}
                                        />
                                        <Form.Check
                                            inline
                                            label="Private"
                                            name="private"
                                            type={type}
                                            id={`inline-${type}-private`}
                                        />
                                        <Form.Check
                                            inline
                                            label="Public"
                                            type={type}
                                            id={`inline-${type}-public`}
                                        />
                                    </div>
                                ))}
                            </Form>


                        </div>
                    </Col>
                    <Col md={3}>
                        <div className='search'>

                            <Formik onSubmit={submit} validationSchema={schema} initialValues={{ search: '' }}>
                                {({ handleSubmit, handleChange, setFieldValue, values }) =>
                                    <Form noValidate className="d-flex" onSubmit={handleSubmit} >
                                        <Form.Control
                                            type="search"
                                            placeholder="Search..."
                                            className="me-2 rounded-pill"
                                            aria-label="Search"
                                            required
                                            name='search'
                                            onChange={e => {
                                                setFieldValue('search', e.target.value)
                                                set_searched_term(e.target.value)
                                            }}
                                            value={values.search}
                                        />
                                        {/* <Button variant="outline-primary" type='submit'>Search</Button> */}
                                    </Form>

                                }

                            </Formik>
                        </div>
                    </Col>
                </Row>
                <div className='app_list mt-4 '>

                    {
                        searchActive ?
                            Apps && searched_items.length !== 0 ?
                                searched_items.map(App => {
                                    return (
                                        <Button 
                                        key={App.appUid} 
                                        variant='focus-ring focus-ring-info text-decoration-none p-0 border-0 me-3 btn btn-link active'
                                        
                                        >
                                        
                                            <AppSelectCard app={App} searchActive={searchActive} searched_term={searched_term} />
                                        </Button>
                                    )
                                })
                                :
                                <div className='row text-center '>
                                    <p className="fs-2"> No match found</p>
                                </div>
                            :
                            Apps?.map(App => {
                                return (
                                    <Button 
                                     key={App.appUid} 
                                     variant={
                                     selected?.includes(App)?
                                     'focus-ring focus-ring-info text-decoration-none p-0 border-2 me-3 btn btn-link '
                                     :
                                     'focus-ring focus-ring-info text-decoration-none p-0 border-0 me-3 btn btn-link '
                                     }
                                     className='bg-primary'
                                     onClick={()=>handleSelected(App)}
                                    >

                                        <AppSelectCard app={App} />
                                    </Button>
                                )
                            })
                    }
                    
                </div>
            </Tab>
        </Tabs>
    )
}

export default forwardRef(SuiteCreateTab)
