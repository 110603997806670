import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { ReactSVG } from "react-svg";
import { computer, screen1, screen2 } from "../Images/Images";
import { IconName } from "react-icons/fa";

export default function MultipleItems (){
   
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 2
    };
    return (
      <div className="text-center">
        <h2 className="mb-5"> Preview</h2>        
        <Slider className="px-5" {...settings}>
          <div>
            <img src={screen1} className="img-fluid" alt="" />
          </div>
          <div>
            <img src={screen1} className="img-fluid" alt="" />
          </div>
          <div>
            <img src={screen1} className="img-fluid" alt="" />
          </div>
          <div>
            <img src={screen1} className="img-fluid" alt="" />
          </div>
          <div>
            <img src={screen1} className="img-fluid" alt="" />
          </div>
        </Slider>
      </div>
    );
  }



