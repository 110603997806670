import { useState, useEffect } from 'react'
import { Modal, Button, Form, Col } from 'react-bootstrap';
import SuiteSelectCard from '../cards/SuiteSelectCard';
import SuiteCard from '../cards/SuiteCard';
import Row from 'react-bootstrap/Row';
import { scopes } from '../../utility/Constants';
import { useFormik } from 'formik';
import * as yup from 'yup';

const SelectSuitesModal = ({ show, onHide, onClick, suites, select, mutate, selectedSuite }) => {


  const [searched_term, set_searched_term] = useState('')
  const [searched_items, set_searched_items] = useState([])
  const [searchActive, setSearchActive] = useState(false)


  const initialValues = {
    search: '',
    scope: [],
  }

  const validationSchema = yup.object({
    search: yup.string(),
    scope: yup.array().of(yup.string())
  });

  const onSubmit = val => {
    console.log(val)
    setSearchActive(true)
    let results = suites && suites.filter(suite => suite.suiteName.includes(val.search))
    set_searched_items(results)
  };

  const formik = useFormik({ initialValues, onSubmit, validationSchema });

  useEffect(() => {
    // search off
    if (searched_term === '' && formik?.values?.scope?.length === 0) {
      setSearchActive(false)
    }
    // search on
    else {
      setSearchActive(true)
      // search with name and scope
      if ((formik?.values?.scope?.length !== 0) && (formik?.values?.search?.length !== 0)) {
        let filteredResults = suites?.filter(suite => suite?.suiteName?.includes(searched_term) && formik?.values?.scope?.includes(suite?.scope))
        set_searched_items(filteredResults)
      }
      // search with only scope
      if ((formik?.values?.scope?.length !== 0) && (formik?.values?.search?.length === 0)) {
        let scopeResults = suites?.filter(suite => formik?.values?.scope?.includes(suite?.scope))
        set_searched_items(scopeResults)
      }
      // search with only name
      if ((formik?.values?.scope?.length === 0) && (formik?.values?.search?.length !== 0)) {
        let searchResults = suites?.filter(suite => suite?.suiteName?.includes(searched_term))
        set_searched_items(searchResults)
      }
    }

  }, [formik?.values, searched_term, suites])




  return (
    <>

      <Modal size="lg" className='select_suit_modal' show={show} onHide={onHide}>
        <Modal.Header className='px-4' closeButton closeVariant='white'>
          <Modal.Title>Select Suites</Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-4'>
          <div className='d-flex justify-content-center justify-content-between mb-4'>
            <div className='search_box'>
              <Form noValidate className="d-flex" onSubmit={formik.handleSubmit} >
                <Form.Control
                  type="search"
                  placeholder="Search"
                  className="me-4 mx-3 rounded-pill"
                  aria-label="Search"
                  required
                  name='search'
                  onChange={e => {
                    formik.setFieldValue('search', e.target.value)
                    set_searched_term(e.target.value)
                  }}
                  value={formik.values.search}
                />
                <div className='options d-flex ms-5 mt-2'>
                  {scopes?.map((scope) =>
                    <div key={scope} >
                      <Form.Check
                        inline
                        label={scope.charAt(0) + scope.substring(1).toLowerCase()}
                        name="scope"
                        type='checkbox'
                        onChange={formik.handleChange}
                        value={scope}
                        id={`inline-${scope}-org`}
                      />
                    </div>
                  )}
                </div>

                {/* <Button variant="outline-primary" type='submit'></Button> */}
              </Form>
              {/* <div className='options'>
                  <Form>
                    
                  </Form>
                </div> */}

            </div>

          </div>
          <div className='suit_item pt-2 p-3'>
            <Row xs={1} md={3} className="g-4" >

              {
                !searchActive ?
                  suites?.map(suite => {
                    return (
                      <Col key={suite?.suiteUid}>
                        <button className="focus-ring focus-ring-info text-decoration-none p-0 border-0 me-3 btn btn-link" onClick={() => select(suite)}>
                          <SuiteSelectCard suite={suite} />
                        </button>
                      </Col>

                    )
                  })
                  :
                  searched_items.length !== 0 ?
                    searched_items.map(suite => {
                      return (
                        <Col key={suite.suiteUid}>
                          <button className="focus-ring focus-ring-info text-decoration-none p-0 border-0 me-3 btn btn-link" onClick={() => select(suite)}>
                            <SuiteSelectCard suite={suite} searchActive={searchActive} searched_term={searched_term} />
                          </button>
                        </Col>
                      )
                    })
                    :
                    <Col>
                      <div className="text-center ">
                        <p>No match found</p>
                      </div>
                    </Col>
              }

            </Row>
          </div>
          <div className='float-end mt-4'>
            <Button variant="outline-primary cancel theme-btn me-2" onClick={onClick}>
              Cancel
            </Button>
            <Button variant="primary theme-btn" onClick={() => {

              selectedSuite && mutate(selectedSuite)
              onClick()
            }}>
              Connect
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default SelectSuitesModal