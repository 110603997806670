import React from 'react'
import image_placeholder from '../assets/images/image-placeholder.jpg';
const PreviewImage = ({file}) => {

    const[preview,setPriview] = React.useState(null)
    const reader = new FileReader();
    file && reader.readAsDataURL(file);
    reader.onload =() =>{
      // console.log(file)
      file && setPriview(reader.result);
    };

  return (
    <img src={preview?preview : image_placeholder} alt="" width={200} className='rounded-pill' />
  )
}

export default PreviewImage