import { useState } from 'react'
import { Card, Container, Row, Col, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import VpnModal from '../modals/VpnModal'
import { VpnConnect } from '../../services/Api'
import { useQuery } from 'react-query'

const VpnConnection = () => {

  const [Vpn, setVpn] = useState(false);
  const connectUrl = `http://localhost:8080/wgctlup`
  const disConnectUrl = `http://localhost:8080/wgctldown`


  const connectVpn = async () => {
    VpnConnect(connectUrl)
      .then(res => {
        console.log(res)
        res && setVpn(false)
      }
      )
      .catch(err => console.log(err))
  }

  const disConnectVpn = async () => {
    VpnConnect(disConnectUrl)
      .then(res => {
        setVpn(true)
        console.log(res)
      })
      .catch(err => console.log(err))
  }



  return (
    <div>
      <Card className='p-5 shadow-sm border-light'>
        <Card.Title className='mb-3 d-flex align-items-center justify-content-between'>
          <h5>VPN Connection <span className="badge bg-primary">Active</span></h5>
          <div className='settings fs-3'>
            <VpnModal />
          </div>
        </Card.Title>

        <Card.Body>
          <Container className='mt-3' fluid>
            <Row className='mb-progressbar'>
              <Col md={4} xs={4} className='mb-progressbar-step complete'>
                <div className="position-relative">
                  <div className="progress">
                    <div className="progress-bar" />
                  </div>
                  <Link className="mb-progressbar-dot"></Link>
                  <div className="text-center mb-progressbar-step-number">Step 1</div>
                </div>

              </Col>

              <Col md={4} xs={4} className='mb-progressbar-step complete'>
                <div className="position-relative">
                  <div className="progress">
                    <div className="progress-bar" />
                  </div>
                  <Link className="mb-progressbar-dot"></Link>
                  <div className="text-center mb-progressbar-step-number">Step 2</div>
                </div>
              </Col>

              <Col md={4} xs={4} className='mb-progressbar-step disabled'>
                <div className="position-relative">
                  <div className="progress">
                    <div className="progress-bar" />
                  </div>
                  <Link className="mb-progressbar-dot"></Link>
                  <div className="text-center mb-progressbar-step-number small">Step 3</div>
                </div>

                {
                  Vpn ?
                    <Button
                      variant="danger theme-btn mt-4 ms-2 py-2"
                      onClick={() => disConnectVpn()}
                    >
                      Disconnect
                    </Button>
                    :
                    <Button
                      variant="primary theme-btn mt-4 ms-2 py-2"
                      onClick={() => connectVpn()}
                    >
                      Connect
                    </Button>
                }

              </Col>

            </Row>
          </Container>
        </Card.Body>
      </Card>
    </div>
  )
}

export default VpnConnection