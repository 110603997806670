import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import EditSuiteParamsForm2 from '../Forms/EditSuiteParamsForm2';



function EditModal({show,handleClose,appParameters,updateSuiteParams}) {
  
  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch static backdrop modal
      </Button> */}

      <Modal
        show={show}
        onHide={handleClose}
        animation={true}
        backdrop={true}
        size='lg'
        keyboard={false}
        className='eidit_suite_modal'
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title>Edit App Parameters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* I will not close if you click outside me. Don not even try to press
          escape key. */}
          <EditSuiteParamsForm2 appParameters={appParameters} handleClose={handleClose} updateSuiteParams={updateSuiteParams}/>
          {/* <EditSuiteParamsForm appParameters={appParameters}/> */}
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Save Changes</Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}

export default EditModal;