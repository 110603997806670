import { useContext, useImperativeHandle, forwardRef } from 'react'

import Form from 'react-bootstrap/Form';
import { useFormik } from 'formik';
import * as yup from 'yup';



function CreateSuiteUpload3({ suiteUid}, ref) {

    const file = new File(['file'], "file.zip", { type: "application/zip", });

    const initialValues = {
        dockerCompose:null,
        dependencies: file,
        suiteUid:suiteUid,
    }

    const validationSchema = yup.object({

        dockerCompose: yup.mixed().required('Please add a yml file !')
        .test("File Type","Only yml format supported!", value => {
            if (value) {
                const supportedFormats = ['yml', 'yaml'];
                return supportedFormats.includes(value.name.split('.').pop());
              }
              return true;
        })
        ,
        dependencies: yup.mixed().notRequired()
        //.test("File Type","Only zip format supported!", value => value && ['application/zip'].includes(value.type))
        ,
        suiteUid: yup.string()

    });

    const onSubmit = values => {
        const Artefacts = new FormData();
        Object.keys(values).forEach(key => {
            console.log(key, formik.values[key])
            // formik.values[key]
            Artefacts.append(key, formik.values[key])
        });
        return (Artefacts)
    }

    const updateValues = (suite) =>{
        console.log(suite)
        formik.setFieldValue('suiteUid',suite)
        return true
    }


    const formik = useFormik({ initialValues, onSubmit, validationSchema });
    // console.log(formik.values)

    useImperativeHandle(ref, () => {
        return {
            // resetForm:formik.resetForm(),
            dockerCompose: () => formik.values.dockerCompose,
            validated: () => Object.keys(formik.errors).length === 0,
            Submit:  async() => {
                // formik.setFieldValue('suiteUid', null)

                const artefacts = await formik.submitForm();
                console.log(artefacts)
                console.log(formik.values)
                return artefacts
            },
            update:(suite)=>updateValues(suite)
            ,
            Values: async () => {
                console.log(formik?.values)
                // if(!formik?.values?.dockerCompose || !formik?.values?.suiteUid){
                //     return 
                // }
                // else{
                    return formik?.values
                // }
            }
        }
    });

    // useEffect(()=>{


    // },[formik.values])


    return (
        <>

            <Form onSubmit={formik.handleSubmit} >
                <span className='fs-5'>Upload compose</span>
                <Form.Group className="position-relative mb-3">
                    <Form.Label>You can upload a Compose file from your computer.</Form.Label>
                    <Form.Control
                        type="file"
                        required
                        name="dockerCompose"
                        // disabled={formik.submitCount>=1}
                        onChange={e => formik.setFieldValue('dockerCompose', e.target.files[0])}

                    // isInvalid={formik.touched.dockerCompose || (formik.touched.dockerCompose && formik.errors.dockerCompose)}
                    // isValid={formik.touched.dockerCompose}
                    />
                    <p className='text-danger'>{formik.dirty && formik.errors.dockerCompose}</p>
                    <Form.Control.Feedback type='invalid' >
                        {formik.errors.dockerCompose}
                    </Form.Control.Feedback>
                </Form.Group>

                <span className='fs-5'>Upload Dependencies</span>
                <Form.Group
                    controlId="validationFormik101"
                    className="position-relative mb-3"
                >
                    <Form.Label>These values will be used as substitutions in the stack file</Form.Label>
                    <Form.Control
                        type="file"
                        name="dependencies"
                        className="form-control"
                        // value={formik.values.dependencies}
                        onChange={e => formik.setFieldValue('dependencies', e.target.files[0])}
                    // isInvalid={ (formik.errors.dependencies)}
                    // isValid={formik.touched.dependencies}
                    />
                    <Form.Control.Feedback type='invalid' >
                        {formik.errors.dependencies}
                    </Form.Control.Feedback>
                </Form.Group>

                {/* <Form.Group className='mb-2'>

          <Form.Check
            label={'uiFlag'}
            name='uiFlag'
            value={true}
            type='checkbox'
            // disabled={(!errors.scope && submitCount >= 1)}
            onChange={formik.handleChange}
            isInvalid={(formik.touched.uiFlag && formik.errors.uiFlag)}
            isValid={formik.touched.uiFlag}
          // checked={.scope === scope}
          />
          <Form.Control.Feedback type='invalid' >
            {formik.errors.image}
          </Form.Control.Feedback>

        </Form.Group> */}


                {/* <Button variant='primary theme-btn mt-3 px-5' type="submit">Next</Button> */}

            </Form>





        </>
    )
}

export default forwardRef(CreateSuiteUpload3) 