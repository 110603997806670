import React from 'react'
import Tooltip from 'react-bootstrap/Tooltip';

const renderTooltip = (text) => {
  return (
    <Tooltip id="button-tooltip" >
    {text}
  </Tooltip>
  )
}

export default renderTooltip