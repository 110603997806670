import {useState,useRef,useContext, useEffect} from 'react'
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from "react-router-dom";
import { useMutation } from 'react-query';
import Breadcrumbs from '../../Component/miscellaneous/Breadcrumb';
import {MutateLoader} from '../../Component/loaders/';
import {Form, Card, Row, Col, Button} from 'react-bootstrap'; 
import {addSuite,uploadArtefacts,updateSuite,updateSuiteApps,createSuiteFinal} from '../../services/Api'
import {CreateSuiteStatus} from '../../Component/stepper';
import 'react-toastify/dist/ReactToastify.css';
import {getUpdatePayload} from '../../utility/Helper';
import {SuiteCreateTab}  from '../../Component/tabs';
import {CreateSuiteForm3} from '../../Component/Forms';
// import Mqtt from '../../hooks/Mqtt'
import { notify } from '../../utility/Helper';

const CreateSuite2 = () => {

  const [suiteUid,setSuiteUid]=useState() // current suite Uid
  const [CurrentSuite,setCurrentSuite] = useState();// current suite created
  const [suiteStatus,setSuiteStatus] = useState();// current suite status
 
  const {user,token,logOut} = useAuth()
  // const {Connect,Disconnect,connected} = Mqtt()

  const CreateSuiteFormref = useRef(); //suiteForm ref
  const suiteTabref = useRef(); //suiteCreateTab ref

  const navigate = useNavigate();

 

  // modal state
  const [modalShow, setModalShow] = useState(false)
  const [newLoading,setNewLoading] = useState(false)
  const [disableCreate,setDisableCreate] = useState(true)

  async function handleSubmit(){
    const updatedCompose = await suiteTabref?.current?.composeUpdate(suiteUid)
    // uploadArtefacts
    UploadArtefacts()
    // AddSuite()
  }

  async function handleAddSuiteSuccess(){
      suiteTabref?.current?.tab==='upload' && UploadArtefacts()
      suiteTabref?.current?.tab==='fromApp' && UpdateSuiteApp()
  }

  async function handleAddSuiteError(error){
    if(error?.response?.status === 409){
      notify('error: Suite already exists !')  
      //  setAddSuite()
       CreateSuiteFormref?.current.checkValidity()
      //  setNext(false)
    }
    if(error?.response?.status === 401){
      notify('error: Unauthorized !')
    }
    if(error?.response?.status === 406){
      notify('error: Not acceptable ! Try a different name')
    }
  }

  //add suite api query
  const {mutate:AddSuite} = useMutation({
    mutationFn: async() => {
      const addSuiteForm = await CreateSuiteFormref?.current?.Submit()
      console.log(addSuiteForm,token)
      return(addSuite(addSuiteForm,token))
    }
    ,
    onSuccess:async(data)=>{
      console.log('DRAFT',data?.data)
      setCurrentSuite(data?.data)
      setSuiteStatus('DRAFT')
      setSuiteUid(data?.data?.suiteUid)
      await CreateSuiteFormref?.current?.lockForm()
      const updatedCompose = await suiteTabref?.current?.composeUpdate(data?.data?.suiteUid)
      suiteTabref?.current?.getDockerCompose()
      suiteTabref?.current?.tab==='fromApp' && UpdateSuiteApp(data?.data?.suiteUid)
    },
    onError:(error)=>{
      setNewLoading(false)
      notify(`error : code:${error?.response?.status} | Draft Failed`)
    }
  })

  // useEffect(()=>{

  //   CurrentSuite && handleAddSuiteSuccess(CurrentSuite)

  // },[CurrentSuite])

  // uploadArtefacts api result
  const {mutate:UploadArtefacts} = useMutation({
    mutationFn:async()=>{
          // console.log(suiteTabref.current.tab)
          const artefacts = await suiteTabref?.current?.composeSubmit()
          return(uploadArtefacts(artefacts,token, logOut))
    }
    ,
    onSuccess:(data)=>{
      console.log('FILES_UPLOADED')
      setSuiteStatus('FILES_UPLOADED')
      UpdateSuiteDocker()
    },
    onError:(error)=>{
      setNewLoading(false)
      notify(`error : code:${error?.response?.status}| Files Upload Failed`)
  }
  })

  // updateSuiteFromDocker api query
  const {mutate:UpdateSuiteDocker} = useMutation({
    mutationFn:async()=>{
          const dockerCompose = await suiteTabref.current.dockerCompose();
          const  uiFlag = await suiteTabref.current.uiFlag;
          const  update_payload =  getUpdatePayload(dockerCompose,CurrentSuite?.suiteName,uiFlag)
          console.log(update_payload)
          return(updateSuite(update_payload ,CurrentSuite?.suiteUid,token))
    }
    ,
    onSuccess:(data)=>{
          console.log('APPS_ASSOCIATED')
          setSuiteStatus('APPS_ASSOCIATED')
    },
    onError:(error)=>{
      setNewLoading(false)
      notify(`error : code:${error?.response?.status} | Apps Association Failed`)
  }
  })

  // updateSuiteFromApps api query
  const {mutate:UpdateSuiteApp} = useMutation({
    mutationFn:async(suiteUid)=>{

          const update_payload = await suiteTabref?.current?.apps()
          console.log(update_payload,suiteUid,token,'ko2.2')
    
          return(updateSuiteApps(update_payload ,suiteUid,token, logOut))
    }
    ,
    onSuccess:(data)=>{
      console.log(data)
      console.log('APPS_ASSOCIATED',data?.data)
      setSuiteStatus('APPS_ASSOCIATED')
      CreateSuiteFinal()
    },
    onError:(error)=>{
      setNewLoading(false)
      notify(`error : code:${error?.response?.status} | Apps Association Failed`)
  }
  })
 

  // createFinal api query
  const {mutate:CreateSuiteFinal} = useMutation({
    mutationFn:async()=>{
          console.log('ko2')
          setNewLoading(true)
          return(createSuiteFinal(suiteUid,token, logOut))
        }
    ,
    onSuccess:(data)=>{
          setNewLoading(false)
          console.log('CREATED',data?.data)
          setSuiteStatus('CREATED')
          navigate('/suite')
        },
    onError:(error)=>{
      setNewLoading(false)
      notify(`error : code:${error?.response?.status} | Creation Failed`)
  }
  })

  async function handleCreate(){

    if(suiteTabref?.current?.tab==='upload'){
      CreateSuiteFinal()
    }
    else{
      setNewLoading(true)
      AddSuite()
    }

    
  }

  


  return (
    <>
    
    <div className='create_suite container-fluid navbar-light px-5 position-relative pb-5'>
      <div className="row breadcrumbs mt-3 d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">        
          <div className="col-md-12">              
            <nav aria-label="breadcrumb" className='d-flex'>
              <h3 className="title me-3">Create Suite</h3>
              <Breadcrumbs page={['suite','create']} />
            </nav>
          </div>          
      </div>
      
      <Card className='p-4 shadow-sm border-light suite_details'>
          {/* {suiteStatus && <CreateSuiteStatus suiteStatus={suiteStatus}/>} */}
          <CreateSuiteStatus suiteStatus={suiteStatus}/>
          <Row>
            
            <Col md={6} lg={8} xl={9}>
              <div className='suite_details_form'>

              <CreateSuiteForm3
                ref={CreateSuiteFormref} 
              />
            </div>
            </Col>
          </Row>
        
        <Form.Label className='mt-4'>Build method</Form.Label>

        <SuiteCreateTab
          CurrentSuite={CurrentSuite}
          suiteStatus={suiteStatus}
          ref={suiteTabref}
          AddSuite={AddSuite}
          handleSubmit={handleSubmit}
          show={modalShow}
          setModalShow={setModalShow}
          setDisableCreate={setDisableCreate}
        />

        
        <div className='add-variable mt-4 pb-4 '>
          <Button 
          variant='primary theme-btn px-5'
          disabled={
            disableCreate
           }
          onClick={()=>{
            handleCreate()
          }}
          >
            Create
          </Button>         
               
        </div>
        
      </Card>     

    </div>
    <MutateLoader 
       show={newLoading?true:false}
       entity={'suite'}
       action={'Creating'}
      />
    </>
  )
}

export default CreateSuite2