import { useState, useEffect, useReducer, useContext } from 'react'
import { useAuth } from '../../context/AuthContext';
import DeviceContext from '../../context/DeviceContext';
import { Link } from "react-router-dom";
import { useQuery, useMutation } from 'react-query';
import fileDownload from 'js-file-download'
import Carousel from '../../Component/miscellaneous/Carousel';
import DeviceCard from '../../Component/cards/DeviceCard';
import Breadcrumbs from '../../Component/miscellaneous/Breadcrumb'
import { Dropdown, Button} from 'react-bootstrap';
import EmptyBox from '../../Component/emptyboxes/EmptyBox';
import AddDeviceModal from '../../Component/modals/AddDeviceModal';
import { BiPlus } from "react-icons/bi";
import { grid, list } from '../../Component/Images/Images';
import { ReactSVG } from 'react-svg';
import axios from 'axios'
import { getDeviceDetails } from '../../utility/Helper';
import { getAllDeviceCards, mutateDevices, createCardInstance } from '../../services/Api';
import PageLoaderAnim from '../../Component/loaders/PageLoaderAnim';
import NotFound from '../../Component/emptyboxes/NotFound';
import MutateLoader from '../../Component/loaders/MutateLoader';
import { notify } from '../../utility/Helper';
import { useSearch } from '../../hooks/Search';
import { useDevice } from '../../context/DeviceContext';
const reducer = (CardPayload, dispatchObj) => {
  if (dispatchObj?.type === 'ADD') {
    return (
      [...CardPayload, dispatchObj?.obj]
    )
  }
  if (dispatchObj?.type === 'UPDATE') {
    return (
      CardPayload.map(obj => {
        if (obj?.device_name === dispatchObj?.obj?.device_name) {
          return dispatchObj?.obj
        }
        return obj
      })
    )
  }
}

function AllDevices() {

  const { user, token, logOut, wsPayload } = useAuth()
  // const { Payload } = useDevice()
  const [CardPayload, dispatch] = useReducer(reducer, []);

  const [newUser, setNewUser] = useState(false);
  const [show, setShow] = useState(false);
  const [mutateAction, setMutate] = useState()
  const submitted = (res) => { res && setShow(false) }

  const [deviceCards, setDeviceCards] = useState([]);
  const [searchActive, setSearchActive] = useState(false)

  var PayloadNew = null

  const { formSearch, filteredList, searchTerm } = useSearch({ list: deviceCards, setSearchActive: setSearchActive, objectType: 'device' })
  
  const [initLoading, setIsLoading] = useState(true);
    
  useEffect(() => {
    // Simulate an API call
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const { isLoading, data } = useQuery({
    queryKey: ['devices', token],
    queryFn: () => getAllDeviceCards(token, logOut),
    enabled: !!token,
    onSuccess: (data) => setDeviceCards(data),
  })

  const { isLoading: newLoading, data: newData, mutate } = useMutation({
    mutationFn: (args) => {
      console.log(args)
      const { method, object } = args
      method === 'POST' && setMutate('Creating')
      method === 'DELETE' && setMutate('Deleting')
      return mutateDevices(method, object, token, logOut)
    }
    ,
    onSuccess: async (new_data, args) => {
      setDeviceCards(new_data?.data)
      console.log(new_data)
      const { values } = args
      const userData = { 'UserName': user }
      // creating instance if is DemoDevice
      if (values?.deviceName === 'DemoDevice') {
        const res = await createCardInstance(userData)
        console.log('DemoDevice created', res)
      }
      //notify
      new_data?.data?.length > data?.length ? notify('Device created 🎉') : notify('Device deleted')
    }
    ,
    onError: (err) => {

      if (err?.response?.status === 409) {
        notify(`error: device already exists !`)
      }
      else {
        notify(`error: device cannot be created !`)
      }
    }
  })


  //Download Config
  const download = (deviceName) => {
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    }
    axios.get(`${process.env.REACT_APP_DOWNLOAD_CONFIG}/devices/${deviceName}/download`, config)
      .then(res => fileDownload(res.data, 'setup.sh'))
      .catch(err => console.error(err))
  }


  // useEffect(() => {

  //   let item = CardPayload.find((i) => i?.device_name === Payload?.device_name);//checks if cardPayload array contains Payload
  //   if (!item && Payload?.length !== 0) {
  //     dispatch({ type: 'ADD', obj: Payload })
  //     //  console.log('added',Payload)
  //   }

  //   if (item && Payload?.length !== 0) {
  //     dispatch({ type: 'UPDATE', obj: Payload })
  //   }
  //   // console.log(Payload)
  // }, [Payload])

  useEffect(() => {

    if (!wsPayload["heartbeat"]) {
      return
    }
    PayloadNew = wsPayload["heartbeat"] ? JSON.parse(JSON.stringify(wsPayload["heartbeat"])) : null
    let item = CardPayload.find((i) => i.device_name === PayloadNew.device_name);//checks if cardPayload array contains Payload
    if (!item && PayloadNew.length !== 0) {
      dispatch({ type: 'ADD', obj: PayloadNew })
       console.log('added',PayloadNew)
    }

    if (item && PayloadNew.length !== 0) {
      dispatch({ type: 'UPDATE', obj: PayloadNew })
    }
  }, [wsPayload])

  useEffect(() => {
    console.log("device page", wsPayload)
  }, [wsPayload])




  return (
    <>

      <div className='container-fluid navbar-light px-5 position-relative pb-4'>
        <div className="row breadcrumbs mt-3 d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
          <div className="col-md-9">
            <nav aria-label="breadcrumb" className='d-flex'>
              <h3 className="title me-3">Device</h3>
              <Breadcrumbs page={['Device']} />
            </nav>
          </div>
          {/* <div className="col-md-3 d-flex justify-content-lg-end"> */}
          <div className="col-md-3 pe-0">

            {/* search */}
            {formSearch}
          </div>
        </div>
        <Carousel />
        {
          isLoading || initLoading ?
            <PageLoaderAnim text={'Devices'} />
            // <div class="loader-line"></div>
            :
            deviceCards?.length >= 1 ?
              <div className='d-block'>
                <div className="fulter-section d-flex flex-wrap align-items-center justify-content-between my-4">
                  {/* <div className="short-by d-flex flex-wrap align-items-center justify-content-between">
                  </div>
                  <div className="filter-buttons d-flex justify-content-center align-items-center">
                    <Dropdown>
                      <span>Show</span>
                      <Dropdown.Toggle variant="default" className='bg-white mx-2 border-0' id="dropdown-basic">
                        9
                      </Dropdown.Toggle>
                      <Dropdown.Menu className='shadow border-0'>
                        <Dropdown.Item >9</Dropdown.Item>
                        <Dropdown.Item >12</Dropdown.Item>
                        <Dropdown.Item >16</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Link className="list-view-button me-2"><ReactSVG src={grid} /></Link>
                    <Link className="grid-view-button"><ReactSVG src={list} /></Link>
                  </div> */}
                </div>
                <div className='device_card_section'>
                  <div className="device_card">
                    <div className='row row-cols-auto'>

                      {
                        searchActive ?
                          filteredList?.length !== 0 ?
                            filteredList?.map(card => {
                              let details = getDeviceDetails(card?.deviceName, CardPayload)
                              return (
                                <DeviceCard
                                  key={card?.deviceId}
                                  datetime={details?.datetime}
                                  deviceName={card?.deviceName}
                                  description={card?.description}
                                  lastactive={details?.lastactive}
                                  imageUrl={card.image}
                                  memory={details?.memory}
                                  temperature={details?.temperature}
                                  locationId={card?.locationId}
                                  cpu={details?.cpu}
                                  download={download}
                                  _deleteDevice={mutate}
                                  searchActive={searchActive}
                                  searched_term={searchTerm}
                                />
                              )
                            })
                            :
                            <NotFound 
                            component={"Device"}/>
                          :
                          deviceCards?.map(card => {
                            let details = getDeviceDetails(card?.deviceName, CardPayload)
                            // card?.deviceName==='csb' && console.log(card)
                            console.log("details",CardPayload)

                            return (
                              <DeviceCard
                                key={card?.deviceId}
                                datetime={details?.datetime}
                                deviceName={card?.deviceName}
                                description={card?.description}
                                lastactive={details?.lastactive}
                                imageUrl={card.image}
                                memory={details?.memory}
                                temperature={details?.temperature}
                                locationId={card?.locationId}
                                cpu={details?.cpu}
                                download={download}
                                _deleteDevice={mutate}
                              />
                            )
                          }
                          )
                      }
                    </div>
                  </div>
                </div>
              </div>
              :
              <EmptyBox
                setShow={setShow}
                setNewUser={setNewUser}
                page={'device'}
                heading={'No Device Connected'}
                info={'Create your first device and get started'}
              />
        }

      </div>


      <div className="add-btn">
        <Button
          className='add-device-btn position-fixed bottom-0 end-0 translate-middle border-light shadow btn btn-sm btn-secondary bg-white text-dark rounded-pill'
          variant="primary" onClick={() => {
            setShow(true)
            setNewUser(false)
          }}>
          <BiPlus />
        </Button>
        <span onClick={() => setShow(true)} data-bs-toggle="modal" data-bs-target="#exampleModal" className="position-fixed bottom-0 end-0 translate-middle d-none">Add Device</span>
      </div>

      <AddDeviceModal
        newUser={newUser}
        add={mutate}
        show={show}
        handleClose={() => setShow(false)}
        submitted={submitted}
      />

      <MutateLoader
        show={newLoading ? true : false}
        //  show={true}
        entity={'device'}
        action={mutateAction}
      />



    </>




  )
}

export default AllDevices