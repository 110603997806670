import { useState, useEffect, useContext } from 'react'
import { useNavigate, Link, Outlet, Navigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import Card from 'react-bootstrap/Card';
import { Button, Dropdown, Modal } from 'react-bootstrap';
import { ReactSVG } from 'react-svg';
import { vertical_icon, verified, device1, cloud, edit, trash } from '../Images/Images';
import { LoadingDots } from '../loaders/LoadingDots';
import DeleteModal from '../modals/DeleteModal';
import { getLocation } from '../../services/Api';
import { useAuth } from '../../context/AuthContext';


function DeviceCard({ deviceName, description, imageUrl, datetime, memory, lastactive, temperature, cpu, locationId, _deleteDevice, download, searchActive, searched_term }) {

  const { token } = useAuth()
  //  const {data:Location,isLoading,isError,error} = useQuery(
  //   {
  //     queryKey:['locationId',locationId],
  //     queryFn:() => getLocation(locationId,token),
  //     enabled:!!locationId,
  //     // onSuccess:(data) => console.log(data),
  //     // onSettled:(data) => console.log(data),
  //     // onError:(data) => console.log(data),
  //     refetchOnMount:false,
  //     refetchOnReconnect:false,
  //     refetchOnWindowFocus:false,
  //     refetchIntervalInBackground:false
  //   }
  //  )


  //  const country = Location?.data?.features?.[0]?.properties?.context?.country?.name
  //  const city = Location?.data?.features?.[0]?.properties?.context?.place?.name
  //  const district = Location?.data?.features?.[0]?.properties?.context?.district?.name || Location?.data?.features?.[0]?.properties?.name
  //  console.log(city,country,district)

  //search
  let left_parted_name = searched_term && deviceName.slice(0, deviceName.indexOf(searched_term))
  let right_parted_name = searched_term && deviceName.slice(deviceName.indexOf(searched_term) + searched_term.length)

  //online status
  const [alive, setAlive] = useState(datetime ? true : false)

  const [show, setShow] = useState(false);

  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  const [hover, setHover] = useState(false);
  const handleMouseEnter = () => setHover(true);
  const handleMouseLeave = () => setHover(false);

  // const cardStyle = {
  //   transition: "transform 0.15s ease-in-out"
  // };

  // console.log(isLoading,isError,country,city,district)




  useEffect(() => {

    let interval = setInterval(() => {

      let d = new Date();
      // console.log("datetime test", d.getTime()-lastactive,lastactive)
      if (d.getTime() - lastactive < 30000) {
        // setTime(datetime)
        setAlive(true)
        setLoading(false)
        // console.log('alive',deviceName)
      }
      else {
        setAlive(false)
        setLoading(false)
        // console.log('dead',deviceName)
      }

    }, 1000)

    return () => clearInterval(interval)


  })





  return (
    <div className='device_card mb-4 pe-auto'>
       {/* style={cardStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}> */}
      <Card className='shadow border-0'>
        <Card.Body className='p-4'>
          <div className="d-flex justify-content-between mb-3">
            <div >
              {/* {console.log(error)} */}
              {
                alive ?
                  <span style={{ position: 'absolute', top: '1.8rem', marginLeft: '0.7rem' }}
                    className="status on p-0 bg-secondary border border-light rounded-circle" />
                  :
                  <span style={{ position: 'absolute', top: '1.8rem', marginLeft: '0.7rem' }}
                    className="status p-0 bg-secondary border border-light rounded-circle" />
              }
              {/* <span className="status  position-absolute top-50 start-100 translate-middle p-2 bg-success border border-light rounded-circle">
                  <span className="visually-hidden">New alerts</span>
                </span> */}
            </div>
            <div className="text-center">
              <div className="device_time d-block">
                {
                  loading ? <LoadingDots /> : datetime ? `Device Time :${(datetime).slice(10, 16)}` : `Device Time : --:--`
                }
              </div>
              <div className="location d-block">
                {
                  loading ?
                    <LoadingDots /> :
                    locationId ?
                      `${locationId}`
                      :
                      "Location N/A"
                }
              </div>
            </div>
            <div className="vertical-icon">
              <Dropdown align="end">
                <Dropdown.Toggle variant="default" id="dropdown-basic" className='bg-white border-light border-0 p-0'>
                  <ReactSVG src={vertical_icon} />
                </Dropdown.Toggle>
                <Dropdown.Menu className='py-2 shadow border-light'>
                  <Dropdown.Item className='d-flex'>
                    <ReactSVG
                      src={cloud}
                      onClick={() => download && download(deviceName)}
                    />
                    <button
                      onClick={() => download(deviceName)}
                      className='bg-transparent border-0 text-body'>
                      Download Config
                    </button>
                  </Dropdown.Item>
                  <Dropdown.Item className='d-flex' ><ReactSVG className='me-2' src={edit} /> Edit Device</Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => setShow(true)}
                    className='d-flex' >
                    <ReactSVG className='me-2' src={trash} />
                    Delete Device
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div className="device-img position-relative">
              <Card.Img className='img' variant="top" src={imageUrl} onClick={() => navigate(`/device/${deviceName}`, { state: 'good' })} />
              <ReactSVG src={verified} className="position-absolute verified bottom-0 end-0 translate-middle" />
            </div>

          </div>
          {
            searchActive ?
              <>
                <Link className='card-title' to={`${deviceName}`}>
                  <span className='text-center '>{left_parted_name}</span>
                  <span className='text-center text-primary'>{searched_term}</span>
                  <span className='text-center '>{right_parted_name}</span>
                </Link>
              </>
              :
              <Link className='card-title' to={`/device/${deviceName}`}>{deviceName}</Link>
          }

          {/* <Card.Title className='card-title'>{deviceName}</Card.Title> */}

          <Card.Text className={description.length > 60 ? 'text-truncate' : ''}>
            {description}
          </Card.Text>

          <hr className="devider" />
          <div className="half_circle_progressbar d-flex justify-content-center">
            <div className="progress">
              <div className={cpu > 90 ? "barOverflow danger" : cpu > 50 ? "barOverflow warning" : "barOverflow success"}>
                <div className="bar" style={alive && cpu ? { transform: `rotate(${45 + cpu * 1.8}deg)` } : { transform: `rotate(${45 + 0 * 1.8}deg)` }}></div>
              </div>
              <span>{loading ? <LoadingDots /> : alive ? `${cpu ? cpu : 0}%` : `0%`}</span>
              <h6 className="pro-title">CPU</h6>
            </div>

            <div className="progress">
              <div className={temperature > 90 ? "barOverflow danger" : temperature > 50 ? "barOverflow warning" : "barOverflow success"} >
                <div className="bar" style={alive && temperature ? { transform: `rotate(${45 + temperature * 1.8}deg)` } : { transform: `rotate(${45 + 0 * 1.8}deg)` }}></div>
              </div>
              <span>{loading ? <LoadingDots /> : alive ? `${temperature ? temperature : 0}°C` : `0°C`}</span>
              <h6 className="pro-title">Temp</h6>
            </div>

            <div className="progress">
              <div className={memory > 90 ? "barOverflow danger" : memory > 50 ? "barOverflow warning" : "barOverflow success"}>
                <div className="bar" style={alive && memory ? { transform: `rotate(${45 + memory * 1.8}deg)` } : { transform: `rotate(${45 + 0 * 1.8}deg)` }}></div>
              </div>
              <span>{loading ? <LoadingDots /> : alive ? `${memory ? memory : 0}%` : `0%`}</span>
              <h6 className="pro-title">Memory</h6>
            </div>

          </div>
        </Card.Body>
      </Card>

      <DeleteModal _delete={_deleteDevice} Name={deviceName} show={show} handleClose={() => setShow(false)} />

    </div>
  )

}

export default DeviceCard