import React from 'react'
import { useState, useEffect, useContext } from 'react'
import { useAuth } from '../../context/AuthContext';
import { Link } from "react-router-dom";
import Breadcrumbs from '../../Component/miscellaneous/Breadcrumb'
import { Form, Dropdown } from 'react-bootstrap';
import { useQuery, useMutation } from 'react-query';
import Carousel from '../../Component/miscellaneous/Carousel';
import { ReactSVG } from 'react-svg';
import { grid, list } from '../../Component/Images/Images';
import { Button } from 'react-bootstrap';
import { BiPlus } from "react-icons/bi";
import AppCard from '../../Component/cards/AppCard';
import MutateLoader from '../../Component/loaders/MutateLoader';
import CreateAppModal from '../../Component/modals/CreateAppModal';
import { getApps } from '../../services/Api';
import PageLoader from '../../Component/loaders/PageLoader';
import EmptyBox from '../../Component/emptyboxes/EmptyBox';
import { createApp, mutateApps, updateApp, createFinalApp, deleteApp } from '../../services/Api';
import { useSearch } from '../../hooks/Search';
import { getAppCreatePayload, notify } from '../../utility/Helper';
import NotFound from '../../Component/emptyboxes/NotFound';

function Appstore() {

  const [Apps, setApps] = useState([])

  const { user, token, logOut } = useAuth()
  const [show, setShow] = useState(false);

  const [createdAppUid, setCreatedAppUid] = useState()
  const [createdApp, setCreatedApp] = useState()
  const [action, setAction] = useState()

  const [searchActive, setSearchActive] = useState(false)

  const { formSearch, filteredList, searchTerm } = useSearch({ list: Apps, setSearchActive: setSearchActive, objectType: 'app' })

  // fetching apps 
  const { isLoading: appsLoading, refetch: Appsrefetch } = useQuery(
    {
      queryKey: ['apps'],
      queryFn: () => getApps(token, logOut),
      enabled: !!token,
      onSuccess: (data) => {
        const createdApps = data?.data?.filter(app => app.creationSource === 'APP')
        setApps(createdApps)
      },
      onError: (err) => {
        notify(`error : ${err?.response?.status}`)
      }

    }
  )

  //  creating app
  const { isLoading: addAppLoading, mutate: addApp } = useMutation({
    mutationFn: (args) => {
      const { object, method, values } = args
      setCreatedApp(values)
      method === 'POST' ? setAction('Creating') : setAction('Deleting')
      return createApp(object, token)
    }
    ,
    onSuccess: (data) => {
      _update_app(data?.data?.appUid)
    }
    ,
    onError: (err) => {
      notify(`error : app draft failed code:${err?.response?.status} 1`)
    }
  })
  // deleting app
  const { isLoading: deleteLoading, mutate: _deleteApp } = useMutation({
    mutationFn: (args) => {
      const { object, method } = args
      method === 'POST' ? setAction('Creating') : setAction('Deleting')
      return deleteApp(object, token)
    }
    ,
    onSuccess: (data) => {
      console.log(data)
      Appsrefetch()
    }
    ,
    onError: (err) => {
      notify(`error : ${err?.response?.data?.status}`)
    }

  })

  const [initLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate an API call
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  const { mutate: _update_app } = useMutation({
    mutationFn: (appUid) => {
      const data = getAppCreatePayload(createdApp)
      return updateApp(appUid, data, token)
    },
    onSuccess: (data) => {
      _create_app_final(data?.data?.appUid)
    },
    onError: (err) => {
      notify(`error : app update failed code:${err?.response?.status} `)
    }
  })

  const { mutate: _create_app_final } = useMutation({
    mutationFn: (appUid) => {
      return createFinalApp(appUid, token)
    },
    onSuccess: (data) => {
      Appsrefetch()
      setShow(false)
    },
    onError: (error) => {
      console.error(error)
      notify(`error : app create failed code:${error?.response?.status} 3`)
    }
  })


  // const schema = yup.object({
  //   search: yup.string()
  // });

  // const submit = val => {
  //   console.log(val.search)
  //   setSearchActive(true)
  //   let results = Apps && Apps.filter(app => app?.appName?.includes(val.search))
  //   set_searched_items(results)
  // };

  // useEffect(() => {

  //   if (searched_term === '') { setSearchActive(false) }
  //   else {
  //     setSearchActive(true)
  //     let results = Apps && Apps.filter(app => app?.appName?.includes(searched_term))
  //     set_searched_items(results)
  //   }

  // }, [searched_term, Apps])



  return (
    <>
      <div className='suites apps container-fluid navbar-light px-5 position-relative pb-4'>
        <div className="row breadcrumbs mt-3 d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
          <div className="col-md-9">
            <nav aria-label="breadcrumb" className='d-flex'>
              <h3 className="title me-3">App Store</h3>
              <Breadcrumbs page={['Appstore']} />
            </nav>
          </div>
          <div className="col-md-3 pe-0">
            <div className='search_box'>
              {/* <Formik onSubmit={submit} validationSchema={schema} initialValues={{ search: '' }}>
                {({ handleSubmit, handleChange, setFieldValue, values }) =>
                  <Form noValidate className="d-flex" onSubmit={handleSubmit} >
                    <Form.Control
                      type="search"
                      placeholder="Search"
                      className="me-2 rounded-pill"
                      aria-label="Search"
                      required
                      name='search'
                      onChange={e => {
                        setFieldValue('search', e.target.value)
                        set_searched_term(e.target.value)
                      }}
                      value={values.search}
                    />
                    <Button variant="outline-primary" type='submit'>Search</Button>
                  </Form>
                }
              </Formik> */}
              {formSearch}
            </div>
          </div>
        </div>
        <Carousel />

        {
          appsLoading || initLoading ?
            <PageLoader text={'Apps'} />
            :
            Apps?.length >= 1 ?
              <div className='apps d-block'>

                {/* flutter section */}
                <div className="fulter-section d-flex flex-wrap align-items-center justify-content-between my-4">
                  {/* <div className="short-by d-flex flex-wrap align-items-center justify-content-between">
                    <span className="me-2">Sort By</span>
                    <Dropdown>
                      <Dropdown.Toggle variant="default" id="dropdown-basic" className='bg-white border-light'>
                        Created
                      </Dropdown.Toggle>

                      <Dropdown.Menu className='shadow border-0'>
                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                  </div> */}
                  {/* <div className="filter-buttons d-flex justify-content-center align-items-center">
                    <Dropdown>
                      <span>Show</span>
                      <Dropdown.Toggle variant="default" className='bg-white mx-2 border-0' id="dropdown-basic">
                        9
                      </Dropdown.Toggle>
                      <Dropdown.Menu className='shadow border-0'>
                        <Dropdown.Item href="#/action-1">9</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">12</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">16</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Link className="list-view-button me-2"><ReactSVG src={grid} /></Link>
                    <Link className="grid-view-button"><ReactSVG src={list} /></Link>
                  </div> */}
                </div>
                {/* app listing */}
                <div className='suite_list'>
                  <div className='pt-2 p-3'>
                    <div className='row row-cols-auto'>
                      {
                        searchActive ?
                          filteredList.length !== 0 ?
                            filteredList.map(App =>
                              <AppCard
                                user={user}
                                source={'appstore'}
                                key={App?.appUid}
                                app={App}
                                deleteApp={_deleteApp}
                                searchActive={searchActive}
                                searched_term={searchTerm}
                              />)
                            :
                            // <div className='row text-center '>
                            //   <p className="fs-2"> No match found</p>
                            // </div>
                            <NotFound
                              component={"App"} />
                          :
                          Apps?.map(App => {
                            return (
                              <AppCard
                                user={user}
                                source={'appstore'}
                                key={App?.appUid}
                                app={App}
                                deleteApp={_deleteApp}
                              />
                            )
                          })
                      }
                    </div>
                  </div>
                </div>

              </div>
              :
              <EmptyBox
                page={'appstore'}
                heading={'No Apps Available'}
                info={'Create an app to get started'}
              />
        }


        {/* <div className='no-apps d-block'>
          <EmptyApps />
        </div> */}

        <div className="add-btn">
          <Button
            className='add-device-btn position-fixed bottom-0 end-0 translate-middle border-light shadow btn btn-sm btn-secondary bg-white text-dark rounded-pill'
            variant="primary" onClick={() => {
              setShow(true)
            }}>
            <BiPlus />
          </Button>
          <span onClick={() => setShow(true)} data-bs-toggle="modal" data-bs-target="#exampleModal" className="position-fixed bottom-0 end-0 translate-middle d-none">Create App</span>
        </div>

        <CreateAppModal
          show={show}
          setShow={setShow}
          _createApp={addApp}
        />

        <MutateLoader
          show={addAppLoading || deleteLoading ? true : false}
          entity={'app'}
          action={action}
        />



      </div>
    </>


  )
}

export default Appstore