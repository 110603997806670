import {useState , Suspense ,useContext} from 'react';
import { useAuth } from '../../context/AuthContext';
import {useFormik} from 'formik';
import * as yup from 'yup';
import { useQuery } from 'react-query';
import {Button, Form, Row} from 'react-bootstrap';
import { ReactSVG } from 'react-svg';
import {  } from '../Images/Images';
import logo from '../../assets/images/logo.svg';
import { eye,eye_slash,logo_light } from '../Images/Images';
import {  
  Link,
  useNavigate,
} from "react-router-dom";


function SignInForm() {

  
  const {user,token,login_user,logOut} = useAuth()
  const navigate = useNavigate()
  const [logging,setlog] = useState(false)
  const [failedAuth,setFailedAuth]  = useState()
  const [unAuth,setAuth] = useState()
  const [hide,setHide] = useState(true)
  // const { data,isLoading,isError} = useQuery({
  //   queryKey:['data'],
  //   queryFn:() => login_user(formik.values),
  //   enabled:setlog

  // })
   
    const initialValues = {
        username:'',
        password:'',
    }

    const onSubmit = async(values) => {

       setlog(true)
       setAuth(false)
       const response = await login_user(values)

       console.log('clicked signin',response,0)

       if(response?.status === 200){
        setlog(false)
        console.log('ko')
       }

       if(response?.response?.status === 404){
          setFailedAuth('User not found ')
          setlog(false)
          console.log(404)
       }
       if(response?.response?.status === 401){
        //  if(response?.response?.data?.status === 'UNAUTHORIZED'){User is disabled
         if(response?.response?.data?.message === 'User is disabled'){
          setFailedAuth('You are not authorised')
          setAuth(true)
          setlog(false)
         }
         else{
          setFailedAuth('Incorrect username or password ')
          setlog(false)
         }
        console.log(401)
       }
       if(response?.code === 'ERR_NETWORK'){
        setlog(false)
        setFailedAuth('Request timed out')
       }

    }

    const validationSchema = yup.object({
        username: yup.string().required('Provide a valid username!'),
        password: yup.string().required('Password Required!'),
    });

    const formik = useFormik({initialValues,onSubmit,validationSchema});

  return (
    
    <div className="LandingPage position-absolute top-0 start-0 vh-100 vw-100 bg-light z-3">    
    <div className="wrapper account">
    <div className='sign_in '>
    <Link className='logo mb-4 d-block text-center' to='/'><ReactSVG src={logo_light} alt="" /></Link>
      <div className='sign_in_form bg-white mx-auto px-5  shadow p-5'>        
        <span className='fs-2'>
          Welcome Back
        </span>
        <p>Sign in to your The OpenFactory account.</p>
        <Form  onSubmit={formik.handleSubmit}>
          <Row className="mb-3">
            <Form.Group className='mb-3' controlId="validationusername">                
              <Form.Control
                name='username'
                className='border-0 border-bottom'
                required
                type="text"                  
                placeholder="username or email"   
                value={formik.values.username}
                onChange={formik.handleChange} 
              />
              <Form.Control.Feedback type="invalid">Please provide a valid username.</Form.Control.Feedback>                
            </Form.Group>
                      
            <Form.Group controlId="validationPassword">
              <div className='d-flex'>
                 <Form.Control
                required
                name='password'
                className='border-0 border-bottom'
                type={hide ? "password" : "text"}
                value={formik.values.password}
                onChange={formik.handleChange}
                placeholder="Password"
                 /> 
                 {
                   hide?
                   <ReactSVG src={eye_slash} onClick={()=>setHide(false)}></ReactSVG> 
                   :
                   <ReactSVG src={eye} onClick={()=>setHide(true)}></ReactSVG>
                 } 
                    
              </div>               
                       
            </Form.Group>
           
            {
              failedAuth ?
              unAuth?
              <div className='mb-2 text-center'>
                <p className="text-danger fs-5">{failedAuth} !</p>
                <p className="text-center fw-light fs-6 mb-1">We are processing your account verification.</p>
                <p className="text-center text-success fs-6">we will notify you as soon as you get verified.</p>
              </div> 
            :
              <div className='mb-2 text-center'>
                <p className="text-danger">{failedAuth} !</p>
                <Button variant="link p-0" href='#'>Forgot password?</Button>
              </div> 
            :
            <></>
            }

          </Row>            
          <div className="d-grid gap-2">
            
              {
                !logging?
                unAuth?
                <>
                {/* <Button variant="success">Notify Me</Button> */}
                <Button type='submit' variant="primary theme-btn" >
                  Sign in
                </Button>
                </>
                :
                <>
                <Button type='submit' variant="primary theme-btn" >
                  Sign in
                </Button>
                </>
                :
                    <>
                      <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                        <span role="status"> Logging In ...</span>
                      </button>
                    </>
              }
               
          </div>            
          <div className='mt-4'>
            <span>Don't have an account? </span> <Button variant="link p-0" onClick={()=> navigate('/signup')}>Click here</Button>
          </div>
        </Form>
      </div>
    </div>
    </div>

  </div>
  )
}

export default SignInForm