import { useState, useEffect, useReducer, forwardRef, useImperativeHandle } from 'react';
import { Button, Modal, Table, Spinner } from 'react-bootstrap';
import { useAuth } from '../../context/AuthContext';
import Form from 'react-bootstrap/Form';
import { BsFillXCircleFill, BsFillCheckCircleFill } from "react-icons/bs";
import { useFormik } from 'formik';
// import Mqtt from '../../hooks/Mqtt';
import { LoadingDots } from '../loaders/LoadingDots';


const reducer = (appStatus, dispatchObj) => {
  if (dispatchObj.type === 'ADD') {
    return (
      [...appStatus, dispatchObj.obj]
    )
  }
  if (dispatchObj.type === 'UPDATE') {
    return (
      appStatus.map(obj => {
        if (obj.app === dispatchObj.obj.app) {
          return dispatchObj.obj
        }
        return obj
      })
    )
  }
}


const UploadSuiteModal = ({ show, onClose, data, user, suite, suiteName, handleSubmit, setDisable, UploadArtefacts, CreateSuiteUploadref }, ref) => {

  const [appStatus, dispatch] = useReducer(reducer, [])
  const [build, setBuild] = useState(false)
  const [services, setServices] = useState()
  const [pairs, setPairs] = useState()
  const [title, setTitle] = useState('Verify Suites');

  const [loading, setLoading] = useState([])

  const { wsPayload, ws } = useAuth()
  // const { Publish, Subscribe, Payload, connected, Connect, Disconnect } = Mqtt();

  const formik = useFormik({
    initialValues:
    {
      uiFlag: false
    }
  })


  useEffect(() => {

    const build_arr = data && data.services && Object.keys(data.services).map(service => {
      const service_keys = Object.keys(data.services[service])
      return service_keys
    })

    let service_arr = [];

    build_arr && build_arr.forEach(service => {
      service.forEach(key => {
        service_arr.unshift(key)
      })
    })

    if (service_arr && service_arr.includes('build')) {
      setTitle('Caution')
      setBuild(true)
      setServices()
      setPairs()
    }
    else {
      setTitle('Verify Suites')
      setBuild(false)
      data && setServices(data.services)
      services && setPairs(Object.entries(services))
    }
  }, [data, services])

  const verify = (image) => {
    console.log(image, suiteName)
    setLoading([...loading, image])
    //  setLoading([...loading,image])
    //  dispatchLoad({app:image , transition : 'load'})
    //  console.log(connected)
    const publish_payload = `{"user":"${user}", "image":"${image}", "suite":"${suiteName}"}`

    // Publish(`create_suite_cmd`, publish_payload)
    ws.send(JSON.stringify({ topic: `create_suite_cmd`, payload: publish_payload }))

    // suite && Subscribe(`${user}/create_suite_status/${suiteName}`)

  }

  async function handleSemiBuiltSuite() {
    await CreateSuiteUploadref?.current?.update(suite)
    UploadArtefacts()
  }

  useEffect(() => {

    let Payload = wsPayload ? wsPayload["create-suite-status"] ? wsPayload["create-suite-status"] : null : null
    if (Payload) {
      let item = appStatus.find(item => item["app"] === Payload["app"]);
      // console.log(item, wsPayload?wsPayload["create-suite-status"]?wsPayload["create-suite-status"]["app"]:null:null)

      let _payload = Payload && Payload["app"] && Payload["action"] && Payload["percentage"];
      console.log("333333333333333333", _payload, Payload, item, item ? item["app"] : null, Payload ? Payload["app"] : null)

      // console.log(_payload)

      if (!item && _payload) {
        console.log(appStatus, 'add')
        dispatch({ obj: Payload, type: 'ADD' })
        setLoading(loading.map(item => item !== Payload["app"] ? item : null))
        // setLoading(false)
      }
      if (item && _payload) {
        console.log(appStatus, 'update')
        dispatch({ obj: Payload, type: 'UPDATE' })
      }
    }
    console.log("appStatus",appStatus)
  }, [wsPayload])

  // useEffect(() => {

  //   Connect()

  //   return () => {
  //     Disconnect()
  //   }

  // }, [])




  useImperativeHandle(ref, () => {
    return {
      getUiFlag: () => formik?.values?.uiFlag
    }
  })




  return (
    <Modal size={'lg'} show={show} onHide={onClose}>
      <Modal.Header closeButton closeVariant='white'>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='p-4'>
        {
          build ?
            "Build currently not supported. Please build the image locally and upload in a repository and try again."
            :
            <Table bordered>
              <thead>
                <tr>
                  <th align='center'>Status</th>
                  <th>Images</th>
                  <th>Instruction</th>
                  <th>uiFlag</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {
                  pairs && pairs.map(pair => {

                    let item = appStatus.find(item => item && item.app === pair[1].image);
                    return (
                      <tr key={pair[1].image}>
                        <td align='center'>
                          {
                            !item ? <BsFillXCircleFill className='text-danger fs-4' />
                              :
                              item?.action?.includes('success') ?
                                <BsFillCheckCircleFill className='text-success fs-4' />
                                :
                                item?.action?.includes('error') ?
                                  <BsFillXCircleFill className='text-danger fs-4' />
                                  :
                                  <Spinner animation="grow" variant="primary" size="sm" />
                          }
                        </td>
                        <td>{pair[1].image}</td>
                        <td>-</td>
                        <td>
                          <Form onSubmit={formik.handleSubmit}>
                            <Form.Check
                              name='uiFlag'
                              type={'checkbox'}
                              id={`disabled-default-'checkbox'`}
                              value={true}
                              onChange={formik.handleChange}
                            />
                          </Form>

                        </td>
                        <td>
                          <button
                            className={
                              item && (item.action.includes('pulling') || item.action.includes('pushing')) ?
                                'bg-transparent border-0 text-body' :
                                item && (item.action.includes('success')) ?
                                  "bg-transparent border-0  text-success" :
                                  "bg-transparent border-0  text-primary"
                            }
                            onClick={() => {
                              // item && setLoading([...loading,item.app])
                              verify(pair[1].image)
                            }}
                            disabled={item || loading.includes(pair[1].image)}
                          >
                            {/* {item && console.log(loading,item.app,loading.includes(item.app))} */}
                            {/* {console.log(loading,item)} */}
                            <div>
                              {
                                loading.includes(pair[1].image) ? <LoadingDots /> :
                                  item && (item.action.includes('pulling') || item.action.includes('pushing')) ?
                                    `${item.action === 'pulling' ? 'downloading' : 'uploading'} ${item.percentage}%` :
                                    item && (item.action.includes('success')) ? `Verified` :
                                      `Verify`
                              }
                            </div>



                          </button>
                        </td>
                      </tr>
                    )
                  })
                }

              </tbody>
            </Table>

        }

        <div className='float-end'>
          {
            build ?
              <>
                <Button variant="primary theme-btn me-2 mt-4" onClick={onClose}>
                  Understood
                </Button>
              </>
              :
              <>
                <Button variant="outline-primary theme-btn me-2" onClick={onClose}>
                  Cancel
                </Button>

                <Button variant="primary theme-btn" onClick={() => {
                  console.log(handleSubmit)
                  setDisable(true)
                  !handleSubmit ? handleSemiBuiltSuite() : handleSubmit()
                  onClose()
                }}
                  disabled={appStatus.length < pairs?.length && appStatus.every(item => item && item.action.includes('success'))}
                >
                  Submit
                </Button>
              </>
          }

        </div>
      </Modal.Body>

    </Modal>
  );
};
export default forwardRef(UploadSuiteModal);