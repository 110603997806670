import React, { useEffect, useState, useContext } from 'react'
import { useAuth } from '../context/AuthContext';
import { useQuery } from 'react-query';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { ReactSVG } from 'react-svg';
import { landing_page_image, logo_light, land_device, land_app_bg, land_app, land_suite, women_men, vpn_connection, youtube, linkdin, twitter, desktop, computer, computer_new, arrow_right_up, app_private, app_public, app_org, right_arrow_white, demo } from '../Component/Images/Images';
import { Button, Row, Col } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
import SuiteSlider from '../Component/sliders/SuiteSlider';
import { useNavigate } from 'react-router-dom'
import { BiRightArrowAlt, BiX } from "react-icons/bi";
import DeviceCardHome from '../Component/cards/DeviceCardHome';
import AppCardHome from '../Component/cards/AppCardHome';
// import { getApps } from '../services/Api';
import AppCard from '../Component/cards/AppCard';




function Home() {

  const navigate = useNavigate();
  const { user, token, login_user, logOut } = useAuth()
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [animate, setAnimate] = useState(false);

  const [Apps, setApps] = useState([])
  const [cpu, setCpu] = useState(Math.trunc(23 + Math.floor(Math.random() * 3)))
  const [temp, setTemp] = useState(Math.trunc(57 + Math.floor(Math.random() * 3)))
  const [mem, setMem] = useState(Math.trunc(36 + Math.floor(Math.random() * 3)))

  const handleScroll = () => {
    if (window.scrollY > 1200) {
      setAnimate(true)
      AOS.init()
    }
  }

  // const { isError, isLoading } = useQuery({
  //   queryKey: ["apps"],
  //   queryFn: () => getApps(),
  //   onSuccess: (data) => {
  //     const createdApps = data?.data?.filter(app => app.creationSource === 'APP').filter(app => app?.user?.username === 'admin')
  //     setApps(createdApps)
  //     // console.log("1234567890-",Apps)
  //   }
  // })


  function setOff(c) {
    if (c >= 95) {
      return c - 65
    }
    if (c <= 20) {
      return c + 3
    }
    else {
      let rand = Math.random()
      return c + (rand > 0.5 ? Math.floor(Math.random() * 2) : -1 * Math.floor(Math.random() * 2))
    }
  }

  useEffect(() => {
    logOut()

    let interval = setInterval(() => {
      setCpu(setOff(cpu))
      setMem(setOff(mem))
      setTemp(setOff(temp))
    }, 30000)
    return () => clearInterval(interval)

  }, [cpu, temp, mem])


  useEffect(() => {
    AOS.init();
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
    // window.scrollBy(0,10)
  }, [])


  // const poster = "https://embed-ssl.wistia.com/deliveries/3ff326c35254c41e3433b7c0d0beef1c.jpg?image_crop_resize=774&fm=avif";
  return (

    <div className='LandingPage text-light w-100 h-auto pt-5 z-3 position-absolute start-0 top-0 '>
      <Navbar fixed="top" collapseOnSelect expand="lg" bg="dark" data-bs-theme="dark" className="border-bottom">
        <Container>
          <Navbar.Brand href="/">
            <ReactSVG
              src={logo_light}
              className='logo-landing'
              alt=""
              onClick={() => logOut()}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="justify-content-center flex-grow-1 pe-3 navbar-nav">
              <Nav.Link href="#">About Us</Nav.Link>
              <Nav.Link href="#">Resources</Nav.Link>
              <Nav.Link href="#">Pricing</Nav.Link>
              <Nav.Link href="#">Contact Us</Nav.Link>
            </Nav>
            <Nav>
              {token && <Button variant="link px-4" onClick={() => logOut()} >Log out</Button>}
              {!token && <Button variant="link px-4 fs-5 me-4" onClick={() => navigate('/signin')} >Log in</Button>}
              <Button variant="primary px-4 py-3 fs-5" onClick={() => navigate('signup')}>Get Started - It's free</Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* <Modal 
        show={show} 
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered        
      >    
      
         
            <Modal.Body closeButton className='p-0 position-relative'>
            
                     
            <SignInForm login_user={login_user}/>
          </Modal.Body>        
        </Modal>
       */}

      <Container
        data-aos="fade-up"
        data-aos-duration="3000"
        className='section_one'
      >
        <div className='py-5 text-center'>
          <span className='display-2 fw-bold'>Where the legacy of OT <br />meets the open standards of IT</span>
          <div className='mt-3 description'><br />Create, Deploy and Manage your IT solutions for your OT applications.<br />
            The OpenFactory's Low Code platform helps elevate your industries, one connection at a time.</div><br />
          <Button variant="primary mt-4 px-5 py-3 fs-5" onClick={() => navigate('/signin')}>Start Exploring</Button>
        </div>
      </Container>
      {/* <Button variant="primary" onClick={() => setModalShow(true)}>
        Launch vertically centered modal
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      /> */}
      <Container>
        <div className='text-center video-section'
          data-aos="fade-up"
          data-aos-duration="3000"
        >

          {/* <iframe
            className='object-fit-contain rounded-4'
            title='csb'
            src="https://www.youtube.com/embed/ckfhvisBVAE?rel=0&autoplay=1&mute=0&controls=0"
            width='1220'
            height='640'
            allowFullScreen=""
            data-gtm-yt-inspected-2340190_699="true"
          /> */}

          <img
            src={landing_page_image}
            className="video-section"
            alt="" />

          {/* <ReactSVG src={landing_page_image} alt="" /> */}
        </div>
      </Container>
      <Container
        data-aos="fade-up"
        data-aos-duration="3000"
        className='py-5'>
        <Row>
          <Col sm={3}>
            <div className='item me-4'>
              <h4>Security</h4>
              <hr />
              <p>The apps and suites goes through strict security checks to make sure your machines are never vurnable</p>
            </div>
          </Col>
          <Col sm={3}>
            <div className='item me-4'>
              <h4>Remote Access</h4>
              <hr />
              <p>Secure and reliable remote access to your machines in the shop floor via VPN connection</p>
            </div>
          </Col>
          <Col sm={3}>
            <div className='item me-4'>
              <h4>Scale-up</h4>
              <hr />
              <p>Scale your applications on demand and manage all your assets them from one platform</p>
            </div>
          </Col>
          <Col sm={3}>
            <div className='item'>
              <h4>The Community</h4>
              <hr />
              <p>Alone, we can do little; <br />Together we can do so much</p>
            </div>
          </Col>
        </Row>
      </Container>

      <Container
        data-aos="fade-up"
        data-aos-duration="3000"
        className='section_two device_card_section py-5'>
        <Row>
          <Col
            data-aos={"fade-right"}
            data-aos-offset={300}
            data-aos-easing="ease-in-sine"
            xs={12} md={6}>
            {/* <ReactSVG src={land_device} alt="" /> */}
            <DeviceCardHome
              // token={token}
              animate={animate}
              deviceId={0}
              deviceName={'Pick & Sort'}
              description={'Picking & Sorting of apples based on size using computer vision'}
              // imageUrl={`https://tofbucket.s3.ap-south-1.amazonaws.com/theopenfactory/helper/eSIM-768x432-c.jpeg`}
              imageUrl={demo}
              memory={mem}
              temperature={temp}
              cpu={cpu}
            />
          </Col>
          <Col
            data-aos="fade-left"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            xs={12} md={6} className='d-flex align-items-center justify-content-center'>
            <div className='device_right'>
              <h1 className='display-3 fw-bolder'>Devices</h1>
              <p>This is a digital repersentation of your physical machine .<br /> It helps deploy, manage and monitor your applications seamlessly!
              </p>
              <Button variant='primary p-3 d-flex' onClick={() => navigate('/signin')}>Create Device <ReactSVG className='ms-2' src={right_arrow_white} alt="" /></Button>

            </div>
          </Col>
        </Row>
      </Container>

      <Container
        data-aos="fade-up"
        data-aos-duration="3000"
        className='section_three py-5 suites'>
        <Row className='suite_list'>
          <Col xs={12} md={4} className='d-flex align-items-center justify-content-center'>
            <div className='device_right'>
              <h1 className='display-3 fw-bolder'>Suites</h1>
              <p>A collection of Apps configured to carry out a specific application. This gets associated to devices to perform dedicated operations.</p>
              <Button variant='primary p-3 d-flex' onClick={() => navigate('/signin')}>Explore Suites <ReactSVG className='ms-2' src={right_arrow_white} alt="" /></Button>
            </div>
          </Col>
          <Col className='text-center d-flex' xs={12} md={8}>
            <SuiteSlider />
          </Col>
        </Row>
      </Container>

      <div
        data-aos="fade-up"
        data-aos-duration="3000"
        className='land_app_section text-white py-5 text-center suites'>
        <Container>
          <Row>
            <Col>
              <div className='device_right'>

                <span className='land_title display-3 fw-bolder mb-3 d-block'>
                  Apps
                </span>
                <p>Apps are the building block of all Suites. The App Store hosts various<br /> apps that can be configured and used directly for your applications.</p>
                <Button variant='primary p-3 fs-5 px-4 text-white' onClick={() => navigate('/signin')}><span>Browse Apps</span> <ReactSVG className='float-end ms-2' src={arrow_right_up} alt="" /></Button>
              </div>
            </Col>
          </Row>
          <Row className='mt-5 suite_list'>
            <Col
              data-aos="fade-right"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
              style={{ display: "flex", justifyContent: "center" }}>
              {
                // Apps?.length >0 ?
                //   <AppCard source={"home"} app={Apps[0]} />
                //   :
                <AppCardHome index={0} />
              }

              {/* <ReactSVG src={app_private} alt="" /> */}
            </Col>
            <Col className='mt-5'
              data-aos="fade-up"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
              style={{ display: "flex", justifyContent: "center" }}>
              {
                // Apps?.length > 1 ?
                //   <AppCard source={"home"} app={Apps[1]} />
                //   :
                <AppCardHome index={1} />
              }

            </Col>
            <Col
              data-aos="fade-left"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
              style={{ display: "flex", justifyContent: "center" }}>
              {
                // Apps?.length > 2 ?
                //   <AppCard source={"home"} app={Apps[2]} />
                //   :
                <AppCardHome index={2} />
              }
            </Col>
          </Row>
        </Container>
      </div>

      <Container
        data-aos="fade-up"
        data-aos-duration="3000"
        className='platform_section mt-5'>
        {/* // className='land_app_section text-white py-5 text-center suites'> */}
        <Row>
          <span className='text-center mb-5 display-3 fw-bolder'>A platform<br />Designed To Scale</span>
          <Col md={7}>
            <div className='left card bg-dark text-white p-4'>
              <h4 className='ps-4 mt-4'>Collaborate with the community</h4>
              <div className='d-md-inline-flex'>
                <div className='des ps-4 mt-5'>
                  <p>Cannot find apps that fits your needs?</p>
                  <p className='mt-4 d-block'>Collaborate and create apps curated to your needs while we take care of it's security.</p>
                </div>
                <ReactSVG className='img-fluid' src={women_men} alt="" />
              </div>

            </div>
          </Col>
          <Col md={5}>
            <div className='right card bg-dark text-white p-4 h-100'>
              <h4 className='mb-5 ps-4 mt-4 d-block'>Integrated remote access to your machines over VPN</h4>
              <div className='text-center img-fluid'>
                <ReactSVG src={vpn_connection} alt="" />
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Container
        data-aos="fade-up"
        data-aos-duration="3000"
        className='section_two py-5'>
        <Row>
          <Col
            data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            className='text-center' xs={12} md={6}>
            <ReactSVG src={computer_new} alt="" />
          </Col>
          <Col
            data-aos="fade-left"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            xs={12} md={4} className='d-flex align-items-center justify-content-center'>
            <div className='device_right'>
              <h1 className='display-3 fw-bolder'>Get started for free</h1>
              <p>Start using The OpenFactory with our free starter plan. After that, pay as you go.</p>
              <Button variant='primary p-3 d-flex' onClick={() => navigate('/signin')}>Get Started — It's free <ReactSVG className='ms-2' src={right_arrow_white} alt="" /></Button>
            </div>
          </Col>
        </Row>
      </Container>


      <div
        className="bg-dark"
      >
        <div
          className="container"
        >
          <footer className="d-flex flex-wrap justify-content-between align-items-center py-4 mt-4">
            <div className="col-md-4 d-flex align-items-center">
              <a href="/" className="mb-3 me-2 mb-md-0 text-decoration-none text-white lh-1">
                The OpenFactory
              </a>
              <span className="mb-3 mb-md-0">© 2024 Company, Inc</span>
            </div>

            <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
              <li className="ms-3"><a className="text-muted" href="#"><ReactSVG src={youtube} alt="" /></a></li>
              <li className="ms-3"><a className="text-muted" href="#"><ReactSVG src={linkdin} alt="" /></a></li>
              <li className="ms-3"><a className="text-muted" href="#"><ReactSVG src={twitter} alt="" /></a></li>
            </ul>
          </footer>
        </div>
      </div>
    </div>


  )
}

export default Home