
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const notify = (text) => {
    if (text.includes('error')) {
        return (toast.error(text, { position: "top-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", }))
    }
    else {
        return (toast.success(text, { position: "top-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", }))
    }
}

export const getDeviceDetails = (deviceName, CardPayload) => {
    let item = CardPayload.find((item) => item?.device_name === deviceName)

    if (item) {

        let details = {
            memory: item.memory,
            cpu: item.cpu,
            temperature: item.temperature,
            datetime: item.datetime,
            lastactive: item.lastactive
        }

        return details;
    }
}

export const getApp = (apps, app) => {
    // console.log("=========================",app,apps)
    const item = apps.find(_app => {
        // const [suite,App] = app.appName.trim().split('/')
        // console.log( _app.name.replace(/ /g, "_").toLowerCase(), app.app.appName.toLowerCase())
        return (
            //  _app.name === App
            _app.name.replace(/ /g, "_").toLowerCase() === app.app.appName.replace(/ /g, "_").toLowerCase()

        )
    })
    const modified_item = item && JSON.parse(JSON.stringify(item))
    // console.log("$$$$$$$$$$$$$$",item, modified_item)
    if (modified_item && modified_item.ports.length !== 0) {

        const _ports = modified_item.ports.map(port => {
            // const port_ ={
            //   ip:(port.ip ? port.ip : ' '),
            //   privateport:(port.privateport?port.privateport:' '),
            //   publicport:(port.publicport ? port.publicport:' '),
            //   type:(port.type?port.type:''),
            // }

            if (port.type.includes('udp')) {
                const udp_port = port.privateport + '/' + port.type
                return udp_port
            }
            else {
                const tcp_port = (port.ip ? port.ip : '') + ':' + (port.privateport ? port.privateport : '') + (port.publicport ? '->' + port.publicport : '') + '/' + (port.type ? port.type : '')
                return tcp_port
            }
            // return port_
        }
        )

        const _status = modified_item.status

        const payload = {
            ports: _ports,
            status: _status,
        }
        // console.log("%%%%%%%%%%%%%%%%%%%%%%%%",payload)
        return payload
    }
    else {
        const _ports = [
            // {
            // ip: '',
            // privateport: '',
            // publicport: '',
            // type: ''
            // }
        ]
        const _status = modified_item && modified_item.status
        const payload = {
            ports: _ports,
            status: _status,
        }
        // console.log("----------------------------",payload)
        return payload
    }
}

export const getAllApp = (apps, app, cardApps) => {
    const inCard = cardApps?.find(_app => {
        return (
            _app.app.appName.replace(/ /g, "_").toLowerCase() === app.name.replace(/ /g, "_").toLowerCase()
        )
    })
    console.log("=========================", app, apps, cardApps, inCard)
    if (inCard?.app) {
        return
    }

    const item = apps.find(_app => {
        // const [suite,App] = app.appName.trim().split('/')
        // console.log( _app.name.replace(/ /g, "_").toLowerCase(), app.app.appName.toLowerCase())
        return (
            // true
            //  _app.name === App
            _app.name.replace(/ /g, "_").toLowerCase() === app.name.replace(/ /g, "_").toLowerCase()

        )
    })
    const modified_item = item && JSON.parse(JSON.stringify(item))
    console.log("$$$$$$$$$$$$$$", item, modified_item)
    if (modified_item && modified_item.ports.length !== 0) {

        const _ports = modified_item.ports.map(port => {
            // const port_ ={
            //   ip:(port.ip ? port.ip : ' '),
            //   privateport:(port.privateport?port.privateport:' '),
            //   publicport:(port.publicport ? port.publicport:' '),
            //   type:(port.type?port.type:''),
            // }

            if (port.type.includes('udp')) {
                const udp_port = port?.privateport + '/' + port?.type
                return udp_port
            }
            else {
                const tcp_port = (port?.ip ? port?.ip : '') + ':' + (port?.privateport ? port?.privateport : '') + (port?.publicport ? '->' + port?.publicport : '') + '/' + (port?.type ? port?.type : '')
                return tcp_port
            }
            // return port_
        }
        )

        const _status = modified_item.status

        const payload = {
            ports: _ports,
            status: _status,
        }
        console.log("%%%%%%%%%%%%%%%%%%%%%%%%", payload)
        return payload
    }
    else {
        const _ports = [
            // {
            // ip: '',
            // privateport: '',
            // publicport: '',
            // type: ''
            // }
        ]
        const _status = modified_item && modified_item.status
        const payload = {
            ports: _ports,
            status: _status,
        }
        // console.log("----------------------------",payload)
        return payload
    }
}

export const getUpdatePayload = (dockerComposes, suite, uiFlag) => {

    const uiFlagValue = uiFlag()

    const update_payload3 = {

        suiteName: suite,
        creationSource: "DOCKER_COMPOSE",
        appList: dockerComposes && Object.entries(dockerComposes.services).map(service => {

            const image_array = service[1].image.split(":")
            const ports_ = service?.[1]?.ports
            const environments_ = {};
            service?.[1]?.environment?.forEach(env => {
                const [variable, value] = env.split("=");
                environments_[variable] = value
            })

            // service?.[1]?.ports?.forEach(port => {
            //     const [variable, value] = port.split(":");
            //     ports_[variable] = value
            // })

            return (
                {
                    appParameters: {
                        ports: ports_,
                        environments: environments_
                    },
                    app: {
                        appName: `${suite.toLowerCase()}.${service[0].toLowerCase()}`,
                        version: image_array && image_array[1],
                        image: {
                            imageName: image_array && image_array[0],
                        },
                        uiFlag: uiFlagValue
                    }

                })
        })
    }

    return update_payload3
}

export const getUpdateAppPayload = (apps, suite) => {
    console.log(apps, suite)
    const updatePayload = {
        suiteName: suite,
        creationSource: "APP",
        appList: apps?.map(app => {

            const appObject = {
                app: {
                    appName: app?.appName,
                    version: app?.image?.version,
                    image: {
                        imageName: app?.image?.imageName
                    },
                    uiFlag: app?.uiFlag,
                    parentAppUid: app?.appUid,
                    appParameters: {
                        ports: app?.appParameters?.ports,
                        environments: app?.appParameters?.environments
                    }
                }
            }

            return (appObject)
        })
    }
    console.log(apps, suite)
    return updatePayload
}

export const getAppCreatePayload = (app) => {

    console.log(app)

    const _ports = app?.ports?.map(port => {
        const str = `${port?.intPort}:${port?.extPort}`
        return str
    })

    const _environments = {}

    // app?.ports?.forEach(port => _ports[port?.intPort] = port?.extPort)
    app?.environments?.forEach(env => _environments[env?.variable] = env?.value)

    console.log(_ports, _environments)

    const appPayload = {
        "appName": app?.appName,
        "image": {
            "imageName": app?.image,
            "version": app?.imageVersion
        },
        "appParameters": {
            "ports": _ports,
            "environments": _environments
        }
    }

    return appPayload
}



