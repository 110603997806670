import {useState} from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { CreateAppForm } from '../Forms/CreateAppForm';

const CreateAppModal = ({show,setShow,_createApp}) => {
    const [submit,setSubmit] = useState(false)
  return (
    <>
     <Modal 
     show={show} 
     onHide={()=>setShow(false)} 
     backdrop="static"
     size="lg"
    
     >
        <Modal.Header className='px-4' closeVariant='white' closeButton>
          <Modal.Title> Create App </Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-4 py-4'>
           <CreateAppForm setShow={setShow} _createApp={_createApp}/>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={()=>setShow(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={()=>{
            setShow(false)
            }}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>

   
    </>
   
  )
}

export default CreateAppModal