import React from 'react'
import {Button, Dropdown,Modal} from 'react-bootstrap';

const DeleteModal = ({_delete,Name,show,handleClose,token}) => {
  
  return (
    <Modal 
        onHide={handleClose}
        show={show}
        backdrop="static"
        keyboard={false}
        size='sm'
        >
          <Modal.Header closeButton closeVariant='white'>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete  ?</Modal.Body>
          <Modal.Footer className='border-0 pb-4'>
            <Button className='theme-btn' variant="outline-secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button className='theme-btn' variant="danger" onClick={()=>{
              console.log(Name)
              const args = {
                method:'DELETE',
                object:Name
              }
              _delete(args)
              handleClose()
              }}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
  )
}

export default DeleteModal