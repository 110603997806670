import { useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import { ReactSVG } from 'react-svg';
import { pencil } from '../../Component/Images/Images';
import EditModal from '../modals/EditModal';

const TableApps = ({ app, updateSuiteParams }) => {

  //set current selected suite app
  const [currSuiteApp, setCurrSuiteApp] = useState();

  // edit suite modal state and functions
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false)

  return (
    <Table striped>
      <thead>
        <tr>
          <th style={{width: '5%'}}></th>
          <th style={{width: '35%'}}>App</th>
          <th style={{width: '30%'}}>Version</th>
          <th>Ports</th>
        </tr>
      </thead>
      <tbody>
        {

          app?.appList?.map(appListItem => {
            console.log(appListItem, 137, appListItem.appParameters.ports)

            const ports = appListItem.appParameters.ports
            return (
              <tr key={appListItem?.app?.appUid} className='align-middle'>
                <td>
                  <Button variant='link primary'>
                    <ReactSVG className='me-2' src={pencil}
                      onClick={() => {
                        setShow(true)
                        setCurrSuiteApp(appListItem)
                      }}
                    />
                  </Button>
                </td>
                <td>{appListItem?.app?.appName}</td>
                <td>{appListItem?.app?.version}</td>
                <td>
                  {
                    ports ?
                      ports?.map((port, index) => {
                        // console.log(ports,Object.entries(appListItem.appParameters))
                        const [intPort, extPort] = port.split(':')

                        return (
                          <table  key={index}>
                            <tbody>
                              <tr >
                              <td >{`${intPort}:${extPort}/tcp`}</td>
                            </tr>
                            </tbody>
                            
                          </table>

                        )
                      })
                      :
                      <table >
                        <tbody>
                           <tr>
                          <td></td>
                        </tr>
                        </tbody>
                       
                      </table>
                     
                  }
                </td>
              </tr>
            )
          })
        }
        {/* Edit app parameters modal */}
        <EditModal
          show={show}
          handleClose={handleClose}
          appParameters={currSuiteApp?.appParameters}
          updateSuiteParams={updateSuiteParams}
        />

      </tbody>
    </Table>
  )
}

export default TableApps