import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { useState } from 'react';
import {Button, Form, Row, InputGroup} from 'react-bootstrap';
import { ReactSVG } from 'react-svg';
import logo from '../../assets/images/logo.svg';
import { Link } from "react-router-dom";

const ForgotPassword = () => {
  const [validated, setValidated] = useState(false);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  return (  
    
    <div className="position-absolute top-0 start-0 vh-100 vw-100 bg-light z-3">
      <div className='logo_for_login'>
        <Link className='logo mb-4' to='/'><ReactSVG src={logo} alt="" /></Link>
      </div>
      <div className="wrapper account bg-light">
      <div className='sign_in'>
        <div className='sign_in_form bg-white mx-auto shadow p-5'>
          <span className='fs-2'>
          Forgot password ?
          </span>
          <p>Enter the email address and we'll send a code this email to reset your password.</p>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group className='mb-3' controlId="validationEmailid">                
                <Form.Control
                  required
                  type="email"
                  placeholder="Email Address"                  
                />
                <Form.Control.Feedback type="invalid">Please provide a valid email.</Form.Control.Feedback>                 
              </Form.Group>             
                      
            </Row>            
            <div className="d-grid gap-2">
              <Button type='submit' variant="primary theme-btn">
                Submit
              </Button>              
            </div>            
            <div className='mt-4'>
              <span>Have an account?</span> <Button variant="link p-0" href='signin'>Click here</Button>
            </div>
          </Form>
        </div>
      </div>
      </div>

    </div>
  
  )
}

export default ForgotPassword