import { useContext, useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext';
import { Outlet } from "react-router-dom";
import Navbars from '../../Component/navigation/Navbars'
import Sidebar from '../../Component/navigation/Sidebar'
import { DeviceProvider } from '../../context/DeviceContext';
import { ToastContainer } from 'react-toastify';



function Device() {

  const { user, token, logOut, login_user, userID } = useAuth()

  return (

    <>
      <Sidebar user={user} logOut={logOut} userID={userID} />
      <ToastContainer />
      <div className="my-container bg-light active-cont">
        <Navbars user={user} logOut={logOut} login_user={login_user} />
        {/* <DeviceProvider > */}
        <Outlet />
        {/* </DeviceProvider> */}

      </div>


    </>




  )
}

export default Device