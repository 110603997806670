import React from 'react'

const NotFound = ({component}) => {
    return (
        <div className='row text-center  w-100 '>
            <p className="fs-2"> Oops!</p>
            <p>Searched {component} not found </p>
        </div>
    )
}

export default NotFound