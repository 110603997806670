import { useState } from 'react';
import {Button, Modal, Form} from 'react-bootstrap';
import { AiFillSetting } from "react-icons/ai";
import RangeSliderWithInputs from '../sliders/RangeSlider';

function VpnModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      
      
      <Button variant='link fs-4'><AiFillSetting onClick={handleShow}/></Button>
      <Modal show={show} onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      >
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title>VPN Configuration</Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-5 py-4'>
          <div className='d-flex align-items-center justify-content-between mb-3'>
            <button type="button" className="btn btn-outline-primary theme-btn">Status</button>
              <button type="button" className="btn btn-primary theme-btn">
                Activate                
              </button> 
          </div>
          <h5 className="card-title">Server Information</h5>
             
          <img src="https://placehold.co/400x200" className="card-img-top mb-3 mt-3" alt="..."></img>                          
            <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <h5 className="card-title mb-3">Edge Device</h5>
                    <Form.Control className='border-bottom border-0'  type="text" placeholder="IP address of Device" />
                </Form.Group>
                <RangeSliderWithInputs/> 
                <div className='d-flex float-end mt-4'>
                    <Button variant="secondary me-3 theme-btn">
                    Cancel
                    </Button>
                    <Button variant="primary theme-btn" type='submit'>
                    Submit
                    </Button>
                </div>            
            </Form>
        </Modal.Body>       
      </Modal>
    </>
  );
}

export default VpnModal;