import { Component, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import {
  Dropdown,
  Badge,
  Container,
  Nav,
  Navbar,
} from 'react-bootstrap';
import { ReactSVG } from 'react-svg';
import { bell, messege, user_profile, logout, user_setting, arrowright } from '../Images/Images';
import { IoIosNotificationsOutline } from "react-icons/io";
import { BiMessageSquare } from "react-icons/bi";

const handleClick = () => {
  document.body.classList.toggle('active-nav');
};



function Navbars({ user, logOut }) {

  const navigate = useNavigate()

  return (
    <div className='sticky-top z-2'>
      <Navbar className='pe-5 bg-white border-bottom' sticky="top" collapseOnSelect expand="lg" bg="" variant="">
        <Container fluid>
          <Nav.Link className='toggle' onClick={handleClick}>
            <ReactSVG src={arrowright} />
          </Nav.Link>
          <Navbar>
            <Nav>
              <Nav.Link href="#"><IoIosNotificationsOutline size={30}/>
              {/* <ReactSVG src={bell} /> */}
                {/* <Badge pill bg="danger" className='round position-absolute'>3 </Badge> */}
              </Nav.Link>
              {/* <Nav.Link eventKey={2} href="#messege"> */}
              {/* <BiMessageSquare size={30} /> */}
                {/* <ReactSVG src={messege} /> */}
                {/* <Badge pill bg="danger" className='round position-absolute'>2</Badge> */}
              {/* </Nav.Link> */}
              <Dropdown className='pe-3 ms-3' style={{alignContent:"center"}}>
                <Dropdown.Toggle className='d-flex' variant="default" id="dropdown-basic">
                  {/* <div className='rounded-circle d-flex profile' style={{alignSelf:"center"}}>{user?.charAt(0).toUpperCase()}</div> */}
                  <ReactSVG className='rounded-circle d-flex profile' width={32} src={user_profile} />
                </Dropdown.Toggle>

                <Dropdown.Menu className='p-3 shadow border-0 dropdown-menu-lg-end'>
                  <Dropdown.Item className='d-flex py-2' href="#"><ReactSVG className='me-3' src={user_setting} />{user}</Dropdown.Item>
                  <Dropdown.Item className='d-flex py-2' onClick={() => user ? logOut() : navigate('/signin')}>

                    <ReactSVG className='me-3' src={logout} />
                    {!user ?
                      'Login'
                      :
                      'Sign Out'
                    }


                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar>
        </Container>
      </Navbar>
    </div>
  )

}

export default Navbars