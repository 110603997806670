import React from 'react'
import {icon404} from '../Component/Images/Images'
function PageNotFound() {
  return (
    <div className='position-absolute top-0 start-0 h-100 w-100 bg-light z-3'>
       <img src={icon404} alt='' className='position-relative top-50 start-50 translate-middle '></img> 
       <div className='fs-1  w-25' style={{position:'relative',top:'13%', left:'42%'}}>Page Not Found !</div>
    </div>
  )
}

export default PageNotFound