import React from 'react'
import { Tabs, Tab, Table, Button } from 'react-bootstrap'
import { BiUserPlus, BiRocket, BiRevision, BiBrush } from "react-icons/bi";
const SuiteDevicesTab = ({ suite }) => {
    return (
        <>
            <Tabs
                variant="underline"
                id="justify-tab-example"
                className="mb-3"
            >
                <Tab eventKey="connected" title="Connected">
                    {
                        suite?.pairedDevices?.length === 0 ?
                            "No Device Connected"
                            :
                            <Table striped>
                                <thead>
                                    <tr>
                                        <th>Device Name</th>
                                        <th>Location</th>
                                        <th>CPU</th>
                                        <th>TEMP</th>
                                        <th>MEMORY</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        suite?.pairedDevices?.map(device => {
                                            return (
                                                <tr key={device?.deviceUid}>
                                                    <td>{device?.deviceName}</td>
                                                    <td>v_1.0.0</td>
                                                    <td>35%</td>
                                                    <td>45%</td>
                                                    <td>65%</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                    }


                </Tab>
                <Tab eventKey="running" title="Running">
                    <div className='action-btn me-3 d-flex float-end mb-3'>
                        <Button size='sm' className='me-2 px-3' variant="outline-primary"><BiUserPlus /> Identify</Button>
                        <Button size='sm' className='me-2 px-3' variant="outline-primary"><BiRocket /> Deploy</Button>
                        <Button size='sm' className='me-2 px-3' variant="outline-primary"><BiRevision /> Reboot</Button>
                        <Button size='sm' className='me-2 px-3' variant="outline-danger"><BiBrush /> Purge</Button>
                    </div>
                    {
                        suite?.hostDevices?.length === 0 ?
                            "No Device Runnning"
                            :
                            <Table striped>
                                <thead>
                                    <tr>
                                        <th>Device Name</th>
                                        <th>Location</th>
                                        <th>CPU</th>
                                        <th>TEMP</th>
                                        <th>MEMORY</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        suite?.hostDevices?.map((device, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{device?.deviceName}</td>
                                                    <td>v_1.0.0</td>
                                                    <td>35%</td>
                                                    <td>45%</td>
                                                    <td>65%</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                    }
                </Tab>
            </Tabs>
        </>
    )
}

export default SuiteDevicesTab