import { useState, useImperativeHandle, forwardRef } from 'react';
import { Form, Card, Button, Container, Row, Col, Tab, Tabs, Table } from 'react-bootstrap';
import { BiRevision, BiPlay, BiStop, BiRefresh } from "react-icons/bi";
import { ReactSVG } from 'react-svg';
import Spinner from 'react-bootstrap/Spinner';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import renderTooltip from '../../Component/miscellaneous/Overlay';
import { pencil } from '../../Component/Images/Images';
import 'react-toastify/dist/ReactToastify.css';
import { getApp, getAllApp } from '../../utility/Helper';
import EditModal from '../../Component/modals/EditModal';
import PageLoader from '../loaders/PageLoader';
import { useEffect } from 'react';

function DeviceDetailsTab({ suiteref, card, apps, loading, refresh, publishCombined, publishIndividual, mqtt_busy, isSuiteLoading, isDeviceAlive }, ref) {

  const [key, setKey] = useState('connected');
  const [showeditmodal, setShowEdit] = useState(false);
  const handleClose = () => setShowEdit(false);
  const [currSuiteApp, setCurrSuiteApp] = useState();

  const [checkedApps, setcheckedApps] = useState([])

  useImperativeHandle(ref, () => {
    return {
      checkedApps: () => checkedApps

    }
  });

  // console.log(props)


  //all select
  const allSelect = () => {
    // (card && card?.runningSuite) && console?.log(checkedApps?.length , card?.runningSuite?.appList?.length , checkedApps?.length < card?.runningSuite?.appList?.length)
    if ((card?.runningSuite) && (checkedApps?.length === 0 || checkedApps?.length < card?.runningSuite?.appList?.length)) {
      let allApps = card?.runningSuite?.appList?.map(app => app?.appName?.trim())
      setcheckedApps(allApps)
      console?.log('added')
    }
    else {
      setcheckedApps([])
      console?.log('removed')
    }
  }

  const select = (appName) => {
    if (!checkedApps?.includes(appName)) {
      setcheckedApps([...checkedApps, appName])
    }
    else {
      setcheckedApps(checkedApps => checkedApps?.filter(item_app => item_app !== appName))
    }
  }

  useEffect(() => {
    // console.log("4444444444444444444444444",card, loading)
  }, [card])


  return (
    <>
      <Container className='mt-4 connected_tab' fluid>
        <Row>
          <Col>
            <Card className='p-5 shadow-sm border-light'>
              <Tabs
                activeKey={key}
                variant="underline"
                onSelect={(k) => setKey(k)}
                id="justify-tab-example"
                className="mb-3"
              >
                <Tab eventKey="connected" title="Connected">
                  {
                    isSuiteLoading ?
                      <PageLoader />
                      :
                      card?.pairedSuite ?

                        <Table striped bordered>
                          <thead>
                            <tr>
                              <th style={{ width: '5%' }}></th>
                              <th>App</th>
                              <th>Version</th>
                              <th>Image</th>
                              <th>Ports</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              card?.pairedSuite?.appList?.map(app => {
                                const payload = apps && getApp(apps, app);
                                // console.log(payload,apps,app)
                                const ports = (app?.appParameters?.ports)
                                // console.log(ports,app)

                                return (
                                  <tr key={app?.app?.appUid}>
                                    {/* <td align='center'>                                  
                                  <Link><ReactSVG className='me-2' src={pencil} /></Link>
                                </td> */}
                                    <td>
                                      <Button variant='link primary'>
                                        <ReactSVG className='me-2' src={pencil}
                                          onClick={() => {
                                            setShowEdit(true)
                                            setCurrSuiteApp(app)
                                          }}
                                        />
                                      </Button>
                                    </td>
                                    <td>{app?.app?.appName}</td>
                                    <td>{app?.app?.version}</td>
                                    <td>{app?.app?.image?.imageName}</td>
                                    <td>
                                      <table>
                                        <tbody>

                                          {
                                            ports?.length === 0 ?
                                              <tr>
                                                <td></td>
                                              </tr>

                                              :
                                              (ports)?.map((port, index) => {
                                                const [extPort, intPort] = port.split(":")
                                                return (
                                                  <tr key={index}>
                                                    <td >{extPort}:{intPort}</td>
                                                  </tr>
                                                )
                                              }
                                              )
                                          }
                                        </tbody>
                                      </table>

                                    </td>


                                  </tr>
                                )
                              })
                            }
                          </tbody>
                          <EditModal show={showeditmodal} handleClose={handleClose} appParameters={currSuiteApp?.appParameters} />

                        </Table>
                        :
                        "No Suite Connected"
                  }

                </Tab>
                <Tab eventKey="running" title="Running">
                  <div className='action-btn me-3 d-flex float-end mb-3'>
                    <Button size='sm' className='me-2 ' variant="outline-primary"
                      onClick={() => refresh()}>
                      <BiRefresh />
                    </Button>

                    <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip('no apps selected')} trigger={(checkedApps?.length === 0) && ['hover', 'focus']}>
                      <Button size='sm' className='me-2 px-3' variant="outline-primary"
                        onClick={() => checkedApps?.length !== 0 && publishCombined('start')}
                        disabled={(loading?.load === 'running' && loading?.state) || mqtt_busy || !isDeviceAlive}>
                        {
                          loading?.load === 'running' && loading?.state ? <Spinner animation="border" variant="primary" size="sm" /> : <><BiPlay /> Start</>
                        }
                      </Button>
                    </OverlayTrigger>

                    <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip('no apps selected')} trigger={(checkedApps?.length === 0) && ['hover', 'focus']}>
                      <Button size='sm' className='me-2 px-3' variant="outline-primary"
                        onClick={() => checkedApps?.length !== 0 && publishCombined('stop')}
                        disabled={(loading?.load === 'exited' && loading?.state) || mqtt_busy || !isDeviceAlive}>
                        {
                          // loading?.load === `${app?.app?.appName?.trim().replace(/ /g, "_").toLowerCase()} exited` && loading?.state ? 
                          // <Spinner animation="grow" variant="dark" size="sm" /> : <BiStop />
                          loading?.load === 'exited' && loading?.state ? <Spinner animation="border" variant="primary" size="sm" /> : <><BiStop /> Stop</>
                        }
                      </Button>
                    </OverlayTrigger>

                    <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip('no apps selected')} trigger={(checkedApps?.length === 0) && ['hover', 'focus']}>
                      <Button size='sm' variant="outline-primary px-3"
                        onClick={() => checkedApps?.length !== 0 && publishCombined('restart')}
                        disabled={(loading?.load === 'running' && loading?.state) || mqtt_busy || !isDeviceAlive}>
                        {
                          loading?.load === 'restart' && loading?.state ? <Spinner animation="border" variant="primary" size="sm" /> : <><BiRevision /> Restart</>
                        }
                      </Button>
                    </OverlayTrigger>

                  </div>
                  {
                    card?.runningSuite ?
                      <Table striped bordered>
                        <thead>
                          <tr>
                            <th style={{ width: '50px' }}>
                              {
                                (card?.runningSuite) &&
                                <Form.Check aria-label="option 1" onChange={() => allSelect()}
                                  className='text-center'
                                  checked={(card?.runningSuite) && checkedApps.length === card?.runningSuite?.appList?.length}
                                />
                              }
                            </th>
                            <th>App</th>
                            <th>Version</th>
                            <th>Status</th>
                            <th>Ports</th>
                            <th>Operations</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            card?.runningSuite?.appList?.map((app) => {
                              const payload = apps && getApp(apps, app);
                              const ports = app?.appParameters?.ports;
                              // console.log(";;;;;;;;;;;;;;;",app, app?.app?.appName, payload, apps)
                              return (
                                <tr key={app?.app?.appUid}>
                                  <td align='center'>
                                    <Form.Check aria-label="option 1"
                                      onChange={() => select(app?.app?.appName?.trim())}
                                      checked={checkedApps?.includes(app?.app?.appName?.trim())}
                                    />
                                  </td>
                                  <td>{app?.app?.appName}</td>
                                  <td>{app?.app?.version}</td>
                                  <td>{payload?.status}</td>

                                  <td>
                                    <table>
                                      <tbody>
                                        {
                                          payload?.ports?.map((port, index) => {
                                            const port_ = port //port?.ip + ':' + port?.privateport + ':' + port?.publicport + port?.type

                                            return (
                                              // <td key={index}>{port}</td>
                                              <tr key={index}>
                                                <td >{port_}</td>
                                              </tr>
                                            )
                                          }
                                          )
                                        }
                                        {
                                          // (ports)?.length === 0 ?
                                          //   <tr>
                                          //     <td></td>
                                          //   </tr>
                                          //   :
                                          //   (ports)?.map((port, index) => {
                                          //     const [extPort, intPort] = port.split(":")
                                          //     return (
                                          //       <tr key={index}>
                                          //         <td >{extPort}:{intPort}</td>
                                          //       </tr>
                                          //     )
                                          //   }
                                          //   )
                                        }
                                      </tbody>
                                    </table>
                                  </td>



                                  <td>
                                    <div className='operation-btn'>
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip('start')}>
                                        <Button
                                          size='sm'
                                          className='me-2'
                                          variant="Link fs-5 p-0"
                                          onClick={() => publishIndividual(app?.app?.appName?.trim().replace(/ /g, "_").toLowerCase(), 'start')}
                                          disabled={(loading?.load === `${app?.app?.appName?.trim().replace(/ /g, "_").toLowerCase()} running` && loading?.state) || mqtt_busy || !isDeviceAlive}>
                                          {
                                            loading?.load === `${app?.app?.appName?.trim().replace(/ /g, "_").toLowerCase()} running` && loading?.state ?
                                              <Spinner animation="grow" variant="dark" size="sm" />
                                              :
                                              <BiPlay />
                                          }
                                        </Button>
                                      </OverlayTrigger>
                                      <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip('stop')}>
                                        <Button size='sm' className='me-2' variant="Link fs-5 p-0"
                                          onClick={() => { publishIndividual(app?.app?.appName?.trim().replace(/ /g, "_").toLowerCase(), 'stop') }}
                                          disabled={(loading?.load === `${app?.app?.appName?.trim().replace(/ /g, "_").toLowerCase()} exited` && loading?.state) || mqtt_busy || !isDeviceAlive}>
                                          {
                                            loading?.load === `${app?.app?.appName?.trim().replace(/ /g, "_").toLowerCase()} exited` && loading?.state ? 
                                            <Spinner animation="grow" variant="dark" size="sm" /> : <BiStop />
                                          }
                                        </Button>
                                      </OverlayTrigger>
                                      <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip('restart')}>
                                        <Button size='sm' variant="Link fs-5 p-0"
                                          onClick={() => publishIndividual(app?.app?.appName?.trim().replace(/ /g, "_").toLowerCase(), 'restart')}
                                          disabled={mqtt_busy || !isDeviceAlive}>
                                          {
                                            loading?.load === `${app?.app?.appName?.trim().replace(/ /g, "_").toLowerCase()} restart` && loading?.state ? 
                                            <Spinner animation="grow" variant="dark" size="sm" /> : <BiRevision />
                                          }
                                        </Button>
                                      </OverlayTrigger>
                                    </div>
                                  </td>
                                </tr>

                              )

                            })
                          }
                        </tbody>
                      </Table>

                      :
                      <>
                        No Suite Running
                        <br /></>
                  }
                  <>

                    <br></br>
                    <hr></hr>
                    <Table striped bordered>
                      <thead>
                        <tr>
                          <th style={{ width: '50px' }}>
                            {/* {
                                  (card?.runningSuite) &&
                                  <Form.Check aria-label="option 1" onChange={() => allSelect()}
                                    className='text-center'
                                    checked={(card?.runningSuite) && checkedApps.length === card?.runningSuite?.appList?.length}
                                  />
                                } */}
                          </th>
                          <th>Container</th>
                          <th>Image</th>
                          <th>Status</th>
                          <th>Ports</th>
                          {/* <th>Operations</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {
                          apps?.map((app) => {
                            const payload = apps && getAllApp(apps, app, card?.runningSuite?.appList);
                            const ports = app?.appParameters?.ports;
                            payload ? console.log(";;;;;;;;;;;;;;;", payload, app, app?.app?.appName, apps) : console.log()
                            if (!payload?.ports) {
                              return (<></>)
                            }
                            return (
                              <tr key={app?.app?.appUid}>
                                <td align='center'>
                                  {/* <Form.Check aria-label="option 1"
                                      onChange={() => select(app?.app?.appName?.trim())}
                                      checked={checkedApps?.includes(app?.app?.appName?.trim())}
                                    /> */}
                                </td>
                                <td>{app?.name}</td>
                                <td>{app?.image}</td>
                                <td>{payload?.status}</td>

                                <td>
                                  <table>
                                    <tbody>
                                      {
                                        payload?.ports?.map((port, index) => {
                                          const port_ = port //port?.ip + ':' + port?.privateport + ':' + port?.publicport + port?.type

                                          return (
                                            // <td key={index}>{port}</td>
                                            <tr key={index}>
                                              <td >{port_}</td>
                                            </tr>
                                          )
                                        }
                                        )
                                      }
                                      {
                                        // (ports)?.length === 0 ?
                                        //   <tr>
                                        //     <td></td>
                                        //   </tr>
                                        //   :
                                        //   (ports)?.map((port, index) => {
                                        //     const [extPort, intPort] = port.split(":")
                                        //     return (
                                        //       <tr key={index}>
                                        //         <td >{extPort}:{intPort}</td>
                                        //       </tr>
                                        //     )
                                        //   }
                                        //   )
                                      }
                                    </tbody>
                                  </table>
                                </td>



                                {/* <td>
                                      <div className='operation-btn'>
                                        <OverlayTrigger
                                          placement="top"
                                          delay={{ show: 250, hide: 400 }}
                                          overlay={renderTooltip('start')}>
                                          <Button
                                            size='sm'
                                            className='me-2'
                                            variant="Link fs-5 p-0"
                                            onClick={() => publishIndividual(app?.app?.appName?.trim(), 'start')}
                                            disabled={(loading?.load === `${app?.app?.appName?.trim()} running` && loading?.state) || mqtt_busy || !isDeviceAlive}>
                                            {
                                              loading?.load === `${app?.app?.appName?.trim()} running` && loading?.state ?
                                                <Spinner animation="grow" variant="dark" size="sm" />
                                                :
                                                <BiPlay />
                                            }
                                          </Button>
                                        </OverlayTrigger>
                                        <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip('stop')}>
                                          <Button size='sm' className='me-2' variant="Link fs-5 p-0"
                                            onClick={() => { publishIndividual(app?.app?.appName?.trim(), 'stop') }}
                                            disabled={(loading?.load === `${app?.app?.appName?.trim()} exited` && loading?.state) || mqtt_busy || !isDeviceAlive}>
                                            {
                                              loading?.load === `${app?.app?.appName?.trim()} exited` && loading?.state ? <Spinner animation="grow" variant="dark" size="sm" /> : <BiStop />
                                            }
                                          </Button>
                                        </OverlayTrigger>
                                        <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip('restart')}>
                                          <Button size='sm' variant="Link fs-5 p-0"
                                            onClick={() => publishIndividual(app?.app?.appName?.trim(), 'restart')}
                                            disabled={mqtt_busy || !isDeviceAlive}>
                                            {
                                              loading?.load === `${app?.app?.appName?.trim()} restart` && loading?.state ? <Spinner animation="grow" variant="dark" size="sm" /> : <BiRevision />
                                            }
                                          </Button>
                                        </OverlayTrigger>
                                      </div>
                                    </td> */}
                              </tr>

                            )

                          })
                        }


                      </tbody>
                    </Table>
                  </>
                  {/* } */}

                </Tab>
              </Tabs>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default forwardRef(DeviceDetailsTab)