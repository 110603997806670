
import React from 'react';
import * as yup from 'yup';
import { Formik, Field, Form, FieldArray } from 'formik';
import { ReactSVG } from 'react-svg';
import { trash } from '../Images/Images';
import { Button, Alert } from 'react-bootstrap';

function EditSuiteParamsForm2({appParameters,handleClose,updateSuiteParams}) {

    const ports_ = appParameters?.ports?.map(port => {

      const[intPort,extPort] = port.split(':')

        return (
            {
                'intPort': intPort,
                'extPort': extPort
            }
        )
    })

    const env_ =  appParameters?.environments && Object?.entries(appParameters?.environments)?.map(env => {

        const variable = env[0]
        const value = env[1]

        return (
            {
                'variable': variable,
                'value': value
            }
        )
    })

    console.log(ports_,env_)

    const initialValues = {
        ports: ports_ && [...ports_],
        environments: env_ && [...env_],
    }
    const onSubmit =(values) => {
        
        const ports_payload = values?.ports.map(port =>{
          return `${port?.intPort}:${port?.extPort}`
        })

        const envs_payload ={}
        // values.ports.forEach(port => {
        //   console.log(Object.entries(port).length ,port)
        //   if(Object.entries(port).length !== 0){
        //     ports_payload[port.intPort] = port.extPort
        //   }
          
        // });
        values.environments.forEach(env => {
          if(Object.entries(env).length !== 0){
            envs_payload[env.variable] = env.value
          }
          
        });

        let params_payload =  {
            ports : ports_payload,
            environments : envs_payload
        }

        updateSuiteParams(params_payload)
        console.log('clicked submit',params_payload,values)
        
    }

    const validationSchema = yup.object({
        ports : yup.array().of(yup.object()),
        environments : yup.array().of(yup.object())
        
    });

      
  return (


    <div className="p-4">
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({values}) => (
        <Form >
          <FieldArray name="ports">
            {({ insert, remove, push}) => (
              <div className="mb-4">

              <div className="d-flex flex-row justify-content-start mb-2">
                <h5 className=''>Ports</h5>
              </div>
                {
                  values?.ports?.length < 1  ?
                  <div className="alert alert-danger" role="alert">
                    No ports provided
                  </div>                  
                  :
                  values?.ports?.map((port, index) => {

                    return(
                    <div className="d-flex justify-content-start " key={index}>
                      <div className="w-50 me-3 mb-2">

                        <Field
                          name={`ports.${index}.intPort`}
                          className="form-control"
                          placeholder="0000"
                          type="number"
                        
                        />
                      </div>
                      
                      <div className="w-50">
                        <Field
                          name={`ports.${index}.extPort`}
                          className="form-control"
                          placeholder="0000"
                          type="number"                        
                        />
                      </div>
                      <div className="">
                        <button
                          type="link"
                          className="btn"
                          onClick={() => remove(index)}
                        >
                          <ReactSVG src={trash}/>
                        </button>
                      </div>
                    </div>
                  )}
                  )
                }
                <div className="d-flex flex-row justify-content-start mt-2">                
                  <Button 
                      variant="outline-primary"                                         
                      onClick={() => push({ "intPort": '', "extPort": '' })}
                      >+ Add Ports</Button>                  
                </div>
              </div>
            )}
          </FieldArray>

          <hr/>
          
          <FieldArray name="environments">
            {({ insert, remove, push}) => (
              <div>
                <div className="d-flex justify-content-start mb-2">
                    <h5 className='mb-0 me-2'>Environments</h5>
                </div>
                
                {
                values?.environments?.length < 1 ?               
                <div className="alert alert-danger" role="alert">
                  No environments provided
                </div>  
                  :
                  values?.environments?.map((env, index) => (
                    <div className="d-flex justify-content-between " key={index}>
                      <div className="w-50 me-3 mb-2">
                        <Field
                          name={`environments.${index}.variable`}
                          className="form-control"
                          placeholder="VARIABLE"
                          type="text"                        
                        />
                      </div>                      
                      <div className="w-50">

                        <Field
                          name={`environments.${index}.value`}
                          className="form-control"
                          placeholder="VALUE"
                          type="text"
                        />
                      </div>
                      <div className="">
                        <button
                          type="button "
                          className="btn"
                          onClick={() => remove(index)}
                        >
                          <ReactSVG src={trash}/>
                        </button>
                      </div>
                    </div>
                  ))}
                <div className="d-flex justify-content-start mt-2">                                                         
                    <Button 
                    variant="outline-primary"                    
                    onClick={() => push({ "variable": '', "value": '' })}
                    >+ Add Environments</Button>
                </div>
              </div>
            )}
          </FieldArray>
          
          <div className="d-flex flex-row justify-content-end ">

          <button 
          type="submit"
          className="btn btn-primary mt-4 mx-1"
          onClick={handleClose}
          >
            Save 
          </button>

          <button 
          type="reset"
          className="btn btn-secondary mt-4 mx-1"
          >
            Clear
          </button>
          </div>
          
        </Form>
      )}
    </Formik>
  </div>

    
  )
}

export default EditSuiteParamsForm2