import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { grid, list, device1, vertical_icon, verified, cloud, edit, share, trash, clone, product_image } from '../Images/Images';
import { Form, Dropdown, Row, Col, Card, ListGroup, ListGroupItem, Button } from 'react-bootstrap';
import { Rating } from 'react-simple-star-rating'
import { BiHeart } from "react-icons/bi";
import DeleteModal from '../modals/DeleteModal';
import Heart from "react-animated-heart";


const AppCard = ({ user, source, app, deleteApp, searchActive, searched_term }) => {

  const navigate = useNavigate();

  let left_parted_name = app?.appName?.slice(0, app?.appName?.indexOf(searched_term))
  let right_parted_name = app?.appName?.slice(app?.appName?.indexOf(searched_term) + searched_term?.length)

  // console.log(app)
  const [rating, setRating] = useState(0);
  const [show, setShow] = useState(false);
  const [isClick, setClick] = useState(false);

  return (
    <>
      <div className='suit_item appcard mb-5 ' style={{ display: 'flex', justifyContent: 'center' }}>
        <Card className='shadow border-0' style={{ height: '220px' }}>
          <Card.Body className='py-4 px-4'>
            <div className="d-flex justify-content-between mb-3">
              <div className="ribbon">
                {/* <div className="wrap" > */}
                {/* onClick={source === 'appstore' ? () => navigate(`${app?.appUid}`) : null}> */}
                <span className={app?.scope === "PUBLIC" ? "tag public" : app?.scope === "PRIVATE" ? "tag private" : "tag org"}>
                  {app && app.scope === "ORGANIZATION" ? 'ORG' : app.scope}
                </span>
                {/* </div> */}
              </div>
              <div className="vertical-icon">
                <Dropdown align="end">
                  <Button variant='link fs-3 heart'>
                    {/* <BiHeart /></Button> */}
                    <Heart styles={{ margin: '-27px -30px -40px -40px' }} isClick={isClick} onClick={() => setClick(!isClick)} /></Button>
                  <Dropdown.Toggle variant="default" id="dropdown-basic" className='bg-white border-light border-0 p-0'>
                    <ReactSVG src={vertical_icon} />
                  </Dropdown.Toggle>
                  {user === app?.user?.username &&
                    <Dropdown.Menu className='py-2 shadow border-light'>
                      {/* <Dropdown.Item className='d-flex' ><ReactSVG className='me-2' src={clone} />
                      <button disabled className='bg-transparent border-0 text-body text-muted'>Clone App
                      </button>
                    </Dropdown.Item>
                    <Dropdown.Item className='d-flex' ><ReactSVG className='me-2' src={edit} />
                      <button disabled className='bg-transparent border-0 text-body text-muted'>Edit App
                      </button>
                    </Dropdown.Item> */}
                      {user === app?.user?.username &&
                        <Dropdown.Item className='d-flex' ><ReactSVG className='me-2' src={share} />
                          <button disabled className='bg-transparent border-0 text-body text-muted' data-toggle="tooltip" title="Coming Soon ...">Publish App
                          </button>
                        </Dropdown.Item>
                      }
                      {user === app?.user?.username &&
                        <Dropdown.Item className='d-flex' ><ReactSVG className='me-2' src={trash} />
                          <button
                            className='bg-transparent border-0 text-body'
                            onClick={source === 'appstore' ? () => setShow(true) : null}>Delete App
                          </button>
                        </Dropdown.Item>
                      }
                    </Dropdown.Menu>
                  }
                </Dropdown>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <div className="device-img position-relative">
                <Card.Img
                  className='img-2 rounded-pill'
                  variant="top"
                  src={app?.imageData ? `${app?.imageData}` : product_image}
                // onClick={source==='appstore'?()=>navigate(`${app?.appUid}`):null}
                />
                {app?.user?.username === "theopenfactory" && <ReactSVG src={verified} className="position-absolute verified bottom-0 end-0" />}
              </div>

            </div>
            {
              searchActive && searched_term !== '' ?
                <>
                  <Card.Title className='card-title h3 my-2' >
                    {/* onClick={source==='appstore'?()=>navigate(`${app?.appUid}`):null}> */}
                    <span className='text-center '>{left_parted_name}</span>
                    <span className='text-center text-primary'>{searched_term}</span>
                    <span className='text-center '>{right_parted_name}</span>
                  </Card.Title>
                </>
                :
                <Card.Title className='card-title h3 my-2' >
                  {/* onClick={source==='appstore'?()=>navigate(`${app?.appUid}`):null}> */}
                  {app?.appName}</Card.Title>
            }



            {['sm'].map((breakpoint) => (
              <ListGroup horizontal={breakpoint} key={breakpoint} className="my-0">
                <ListGroup.Item className='p-0 border-0 border-right me-2 border-end pe-2'>{app?.user?.username}</ListGroup.Item>
                <ListGroup.Item className='p-0 border-0 border-right me-2 border-end pe-2'>2,425 </ListGroup.Item>
                <ListGroup.Item className='p-0 border-0 border-right'>{app?.version}</ListGroup.Item>
              </ListGroup>
            ))}

            <div className="d-flex justify-content-start">
              <Rating
                initialValue={'5'}
                // onClick={(rate) => setRating(rate)}
                // onPointerEnter={() => console.log('Enter')}
                // onPointerLeave={() => console.log('Leave')}
                // onPointerMove={(value, index) => console.log(value, index)}
                fillColor={'#0C90E1'}
                size={'17'}
              /* Available Props */
              />
              <Card.Text className='ms-2 mt-1 border-start ps-2'>
                {app?.architecture}
              </Card.Text>

            </div>

            <Card.Text className='mt-2' style={{ height: '60px' }} data-toggle="tooltip" title={app?.description}>
              {app?.description?.length > 50 ? app?.description?.slice(0, 50) + '...' : app?.description}
            </Card.Text>

          </Card.Body>
        </Card>
      </div>

      <DeleteModal _delete={deleteApp} Name={app?.appUid} show={show} handleClose={() => setShow(false)} />
    </>


  )
}

export default AppCard