import { useState, useEffect, useContext } from 'react'
import { useAuth } from '../../context/AuthContext';
import { Link } from "react-router-dom";
import { SuiteCard } from '../../Component/cards';
import { ReactSVG } from 'react-svg';
import { grid, list } from '../../Component/Images/Images';
import { BiPlus } from "react-icons/bi";
import { Dropdown, Button, Container, Row, } from 'react-bootstrap';
import { getSuites, deleteSuite } from '../../services/Api'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useQuery, useMutation } from 'react-query';
import { PageLoader, MutateLoader } from '../../Component/loaders'
import EmptyBox from '../../Component/emptyboxes/EmptyBox';
import { ControlledCarousel, CustomBreadcrumb } from '../../Component/miscellaneous';
import { useSearch } from '../../hooks/Search'
import { notify } from '../../utility/Helper';
import NotFound from '../../Component/emptyboxes/NotFound';


function AllSuites() {

  const { user, token, login_user, logOut } = useAuth()
  const [show, setShow] = useState(false);
  const [suites, setSuites] = useState([]);
  const [searchActive, setSearchActive] = useState(false)

  const { formSearch, filteredList, searchTerm } = useSearch({ list: suites, setSearchActive: setSearchActive, objectType: 'suite' })

  //mutate 
  const [mutateAction, setMutate] = useState()

  const { isLoading: dataLoading, refetch: dataRefetch } = useQuery({
    queryKey: ['devices', token],
    queryFn: () => getSuites(token, logOut),
    enabled: !!token,
    onSuccess: (response) => {
      setSuites(response?.data)
    }
  })

  const [initLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate an API call
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  const { isLoading: newLoading, mutate: _deleteSuite } = useMutation({
    mutationFn: (args) => {
      setMutate('Deleting')
      const { object } = args
      return deleteSuite(object, token, logOut)
    }
    ,
    onSuccess: () => {
      setMutate()
      dataRefetch()
    },
    onError: () => {
      setMutate()
      notify("error: trouble ")
    }
  })



  return (
    <>

      <div className='container-fluid navbar-light px-5 position-relative pb-5 suites'>
        <div className="row breadcrumbs mt-3 d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
          <div className="col-md-9">
            <nav aria-label="breadcrumb" className='d-flex'>
              <h3 className="title me-3">Suite</h3>
              <CustomBreadcrumb page={['Suite']} />
            </nav>
          </div>
          <div className="col-md-3 pe-0">

            <div className='search_box bg-white rounded-pill pe-3'>
              {/* <Formik onSubmit={submit} validationSchema={schema} initialValues={{ search: '' }}>
                {({ handleSubmit, handleChange, setFieldValue, values }) =>
                  <Form noValidate className="d-flex" onSubmit={handleSubmit} >
                    <Form.Control
                      type="search"
                      placeholder="Search"
                      className=" rounded-pill border-0"
                      aria-label="Search"
                      required
                      name='search'
                      onChange={e => {
                        setFieldValue('search', e.target.value)
                        set_searched_term(e.target.value)
                      }}
                      value={values.search}
                    />
                    <Button variant="white rounded-pill  border-0">
                      <BsSearch />
                    </Button>
                    <Button variant="white rounded-pill border-0">
                      <img src={filter} alt='' />
                    </Button>
                  </Form>
                }
              </Formik> */}
            </div>

            {/* search and filter */}
            {formSearch}

          </div>
        </div>
        <ControlledCarousel />
        {
          dataLoading || initLoading ?
            <PageLoader text={'Suites'} />
            :
            suites?.length >= 1 ?
              <div className='d-block'>
                <div className="fulter-section d-flex flex-wrap align-items-center justify-content-between my-4">
                  {/* <div className="short-by d-flex flex-wrap align-items-center justify-content-between"> */}
                    {/* <span className="me-2 mx-2">
                      Filter By
                      <FilterModal
                      />
                    </span> */}

                  {/* </div> */}
                  {/* <div className="filter-buttons d-flex justify-content-center align-items-center">
                    <Dropdown>
                      <span>Show</span>
                      <Dropdown.Toggle variant="default" className='bg-white mx-2 border-0' id="dropdown-basic">
                        9
                      </Dropdown.Toggle>
                      <Dropdown.Menu className='shadow border-0'>
                        <Dropdown.Item >9</Dropdown.Item>
                        <Dropdown.Item >12</Dropdown.Item>
                        <Dropdown.Item >16</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Link className="list-view-button me-2"><ReactSVG src={grid} /></Link>
                    <Link className="grid-view-button"><ReactSVG src={list} /></Link>
                  </div> */}
                </div>




                <div className='suite_list mt-4'>
                  <Container fluid>
                    <Row xs="auto">
                      {
                        searchActive ?
                          filteredList.length !== 0 ?
                            filteredList?.map(suite => {
                              return (
                                <SuiteCard
                                  user={user}
                                  suite={suite}
                                  key={suite?.suiteUid}
                                  searchActive={searchActive}
                                  searched_term={searchTerm}
                                />
                              )
                            }
                            )
                            :
                            // <div className='row text-center '>
                            //   <p className="fs-2"> No match found</p>
                            // </div>
                            <NotFound 
                            component={"Suite"}/>
                          :
                          suites?.map(suite =>
                            <SuiteCard
                              user={user}
                              suite={suite}
                              key={suite?.suiteUid}
                              _deleteSuite={_deleteSuite}
                              token={token} />
                          )
                      }
                    </Row>
                  </Container>
                </div>
              </div>
              :
              <EmptyBox
                setShow={setShow}
                page={'suite'}
                heading={'No suite present'}
                info={'Create a suite to get started'}
              />
        }

        <div className="add-btn">
          <Button
            className='add-device-btn position-fixed bottom-0 end-0 translate-middle border-light shadow btn btn-sm btn-secondary bg-white text-dark rounded-pill'
            variant="primary" onClick={() => setShow(true)}>
            <Link to='/suite/create'>
              <BiPlus />
            </Link>
          </Button>
          <span onClick={() => setShow(false)} data-bs-toggle="modal" data-bs-target="#exampleModal" className="position-fixed bottom-0 end-0 translate-middle d-none">Create Suite</span>
        </div>
      </div>

      <MutateLoader
        show={newLoading ? true : false}
        //  show={true}
        entity={'suite'}
        action={mutateAction}
      />

    </>

  )
}

export default AllSuites

