import {useState,useEffect} from 'react';
import {useParams} from 'react-router-dom'
import Breadcrumbs from '../../Component/miscellaneous/Breadcrumb';
import { Card, ListGroup, Button, Container, Row, Col, Tab, Tabs, Table, Badge, Stack} from 'react-bootstrap';
import { ReactSVG } from 'react-svg';
import { pencil, product_image, user_profile, donut_chart } from '../../Component/Images/Images';
import {  BiCalendarAlt, BiUser, BiCloudDownload, BiUserPlus, BiRocket, BiRevision, BiBrush} from "react-icons/bi";
import {getSuite} from '../../services/Api'
import SlickSlider from '../../Component/sliders/SlickSlider';
import PieChart from '../../Component/miscellaneous/PieChart';
import TableApps from '../../Component/tables/TableApps';
import {SuiteDevicesTab} from '../../Component/tabs';


function DemoSuiteDetails() {  

  const {suiteUid} = useParams();
  //current page suite
  const [suite,setSuite] = useState();
  //set current selected suite app
  const [currSuiteApp,setCurrSuiteApp]= useState();
  //checks if any suite has been updated
  const [updatedSuite,setUpdatedSuite] = useState(0);

 

  useEffect(()=>{

    suiteUid && getSuite(suiteUid)
    .then(res => setSuite(res.data))
    .catch(err => console.error(err))

  },[suiteUid,updatedSuite])


  return (
    <>
     <div className='container-fluid navbar-light px-5 position-relative pb-5 suite_details'>
        <div className="row breadcrumbs mt-3 d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">        
            <div className="col-md-12">              
              <nav aria-label="breadcrumb"  className='d-flex'>
                <h3 className="title me-3">Suite</h3>
                <Breadcrumbs page={['Suite',`${suite?.suiteName}`]}/>
              </nav>
            </div>              
        </div>
        <Container className='mt-3' fluid>
          <div className='p-4 shadow-sm border-light card'>
            <Row xs={1} md={2} className="g-4 m-2">
                <Col md={3} xs={12}>
                  <img 
                  className='me-2 w-100 ' 
                  src={suite && `${suite.image}`}
                  alt=" "
                   />
                </Col>
                <Col md={9} xs={12}>
                  <div className='product-info'>
                    <div className='title d-flex'>
                      <h3 className='me-3'>{suite?.suiteName} : {suite?.version}</h3>
                      <div className='virifyied-icon'>
                        <ReactSVG src={pencil} /> 
                      </div>                                          
                    </div>
                    {['sm'].map((breakpoint) => (
                      <ListGroup key={breakpoint} horizontal={breakpoint} className="my-0">
                        {/* <BiUser /> */}
                        <ListGroup.Item className='py-0 ps-0 border-0 small'>Created by : {suite?.userInfoWrapper?.firstName}</ListGroup.Item>
                      </ListGroup>
                    ))}
                    {['sm'].map((breakpoint) => (
                      <ListGroup key={breakpoint} horizontal={breakpoint} className="mt-2">
                        <ListGroup.Item className='py-0 ps-0 border-0 border-end small '>
                          <span>OS/ARCH</span>
                          <p className='mb-0'>{suite?.architecture}</p>                          
                        </ListGroup.Item>                                             
                        <ListGroup.Item className='py-0 border-0 small border-end'>                         
                          <span>LAST UPDATE</span>
                          <p className='mb-0'><BiCalendarAlt/> 20 May, 2023</p>
                        </ListGroup.Item>
                        <ListGroup.Item className='py-0 border-0 small border-end'>
                          <span>DOWNLOADS</span>
                          <p className='mb-0'><BiCloudDownload /> {suite?.downloads}</p>
                        </ListGroup.Item>
                        <ListGroup.Item className='py-0 border-0 small'>                          
                          <span>VULNERABILTIES</span>
                          <Stack className='mt-1' direction="horizontal" gap={2}>                            
                            <Badge bg="danger">1C</Badge>
                            <Badge bg="warning" text="dark">
                              4H
                            </Badge>
                            <Badge bg="info">15M</Badge>                            
                            <Badge bg="dark">6L</Badge>
                          </Stack>
                        </ListGroup.Item>                        
                      </ListGroup>
                    ))}
                    <div className='description mt-3'>
                      <h4>Description :</h4>
                      <p>{suite?.description}</p>
                    </div>
                  </div>  
                </Col>
               {/*  <Col md={3} xs={12}>
                  <img src={donut_chart} className='img-fluid float-end' alt=''/>
                    <PieChart/> 
                </Col>
                */}
            </Row>
              <SlickSlider/>            
              <div className='app_table mt-5 px-3 '>
              <h3>Apps :</h3>
              <TableApps
              app={suite} 
              appParameters={currSuiteApp?.appParameters}
              />
            </div>
            
            
            <Container fluid>
            <div className='mt-5 device connected_tab' fluid>
            <h3>Device :</h3>
            <Row>
              <Col>
                <Card className='p-5 shadow-sm border-light'>
                
                <SuiteDevicesTab
                  suite={suite}
                />
                </Card>              
              </Col>
            </Row>
            </div> 
            </Container>
            
              
          </div>
          
        </Container>
        
     </div>
    </>
   
  )
}

export default DemoSuiteDetails