import  {useState , useEffect ,useReducer  } from 'react'
import {useNavigate,Link, Outlet, Navigate} from 'react-router-dom'
import Card from 'react-bootstrap/Card';
import {Button, Dropdown,Modal} from 'react-bootstrap';
import { ReactSVG } from 'react-svg';
import {vertical_icon, verified, device1, cloud, edit, trash } from '../Images/Images';
import { LoadingDots } from '../loaders/LoadingDots';




function DeviceCardHome({animate,deviceName,description,imageUrl,memory,temperature,cpu,token}) {

  const [alive,setAlive] = useState(true)
 
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  setTimeout(()=>setLoading(false),1000)

  const [_cpu, setCpu] = useState(0)
  const [temp, setTemp] = useState(0)
  const [mem, setMem] = useState(0)


  const d = new Date();
  const[time,setTime] = useState(d.toLocaleTimeString('en-US', {hour12: false,}));

  useEffect(()=>{

    let interval = setInterval(()=>{

     const d = new Date();
     setTime(d.toLocaleTimeString('en-US', {hour12: false,}))
      
    },1000)

    return ()=> clearInterval(interval)

  })

  useEffect(()=>{

    if(animate){
      

      let out = setTimeout(()=>{
      _cpu >= cpu ? setCpu(cpu) : setCpu(m=>m+1)
      temp >= temperature ? setTemp(temperature) : setTemp(m=>m+1)
      mem  >= memory ? setMem(memory) : setMem(m=>m+1)
      },30)

      return ()=> clearTimeout(out)
    }

  },[animate,_cpu,cpu,mem,memory,temp,temperature])


    
    return (
      <div className='device_card mb-5 justify-content-center d-flex' >                
        <Card className='shadow border-0'>          
          <Card.Body className='p-4'>
            <div className="d-flex justify-content-between mb-3">
              <div className="position-relative">
                {
                  alive?<span className="status on position-absolute top-50 start-100 translate-middle p-2 bg-secondary border border-light rounded-circle"/>
                  :<span className="status  position-absolute top-50 start-100 translate-middle p-2 bg-secondary border border-light rounded-circle"/>
                }
              </div>
              <div className="text-center">
                <span className="device_time d-block">
                  {
                   loading?<LoadingDots/> :`Device Time : ${time.slice(0,5)}` 
                  }
                  </span>
                <span className="location d-block">Kolkata, India</span>
              </div>
              
              <div className="vertical-icon">
                <Dropdown align="end">
                <Dropdown.Toggle variant="default" id="dropdown-basic" className='bg-white border-light border-0 p-0'>
                  <ReactSVG src={vertical_icon} />
                </Dropdown.Toggle>
                <Dropdown.Menu className='py-2 shadow border-light'>
                  <Dropdown.Item className='d-flex' ><img alt="" className='me-2' src={cloud} />
                    <button  className='bg-transparent border-0 text-body' disabled>
                      Download Config
                    </button>
                   </Dropdown.Item>
                  <Dropdown.Item className='d-flex' ><ReactSVG className='me-2' src={edit} />
                    <button className='bg-transparent border-0 text-body' disabled>
                      Edit Device
                    </button>
                    
                  </Dropdown.Item>
                  <Dropdown.Item  className='d-flex' ><ReactSVG className='me-2' src={trash} />                  
                   <button className='bg-transparent border-0 text-body' disabled>
                    Delete Device 
                   </button>
                                      
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>         
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <div className="device-img position-relative">
                <Card.Img className='img' variant="top" src={imageUrl}/> {/* onClick={()=>navigate(`/device/_demoDevice`)}/>*/}
                <ReactSVG src={verified} className="position-absolute verified bottom-0 end-0 translate-middle" />                
              </div>                  
              
            </div>
            <div className='card-title'>{deviceName}</div>
                {/* <Link className='card-title' to={`/device/_demoDevice`}>{deviceName}</Link> */}
            {/* <Card.Title className='card-title'>{deviceName}</Card.Title> */}
            
            <Card.Text>
            {description && description.length>80?description.slice(0,60)+'...':description}
            </Card.Text>
            <hr className="devider" />
            <div className="half_circle_progressbar d-flex justify-content-center">
              <div className="progress">
                <div className={alive && _cpu>90?"barOverflow danger":_cpu>50?"barOverflow warning":"barOverflow success"}>
                  <div className="bar" style={alive?{ transform: `rotate(${45+_cpu*1.8}deg)` }:{ transform: `rotate(${45+0*1.8}deg)` }}></div>
                </div>
                <span>{loading?<LoadingDots/>:alive && _cpu?`${_cpu}%`:`0%`}</span>
                <h6 className="pro-title">CPU</h6>
              </div>                 
              
              <div className="progress">
                <div className={alive && temp>90?"barOverflow danger":temp>50?"barOverflow warning":"barOverflow success"} >
                  <div className="bar" style={alive?{ transform: `rotate(${45+temp*1.8}deg)` }:{ transform: `rotate(${45+0*1.8}deg)` }}></div>
                </div>
                <span>{loading?<LoadingDots/>:alive && temp?`${temp}°C` :`0°C`}</span>
                <h6 className="pro-title">Temp</h6>
              </div>
              
              <div className="progress">
                <div className={alive && mem>90?"barOverflow danger":mem>50?"barOverflow warning":"barOverflow success"}>
                  <div className="bar" style={alive?{ transform: `rotate(${45+mem*1.8}deg)` }:{ transform: `rotate(${45+0*1.8}deg)` }}></div>
                </div>
                <span>{loading?<LoadingDots/>:alive && mem?`${mem}%`:`0%`}</span>
                <h6 className="pro-title">Memory</h6>
              </div>                
              
            </div>
          </Card.Body>
        </Card>



      </div>
    
  )
}

export default DeviceCardHome